export default (theme) => ({
  forgottenPasswordWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 12,
    marginBottom: 12,
  },
  loginButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 24,
  },
  logoWrapper: {
    margin: '0 auto',
    marginTop: 30,
    marginBottom: 34,
  },
  separator: {
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.background.main,
  },
  login: {
    marginTop: 28,
    marginBottom: 16,
    textAlign: 'center',
  },
  passwordWrapper: {
    marginTop: 24,
  },
  version: {
    fontSize: 12,
    lineHeight: 1.4,
    position: 'absolute',
    bottom: 15,
    left: 15,
  },
});
