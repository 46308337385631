export default (theme) => ({
  title: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  smallFieldWrapper: {
    width: 220,
  },
  fieldSpacer: {
    marginLeft: 24,
    marginRight: 24,
  },
  bigFieldWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  topSpacer: {
    marginTop: 12,
  },
  titleWithExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  buttonsWrapper: {
    marginLeft: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 56,
  },
  resetWrapper: {
    marginLeft: 12,
  },
  numberCell: {
    width: 15,
    padding: '0 12px',
  },
  answerSetCell: {
    width: 250,
  },
  questionTypeCell: {
    width: 200,
  },
  questionTypeContent: {
    color: theme.palette.primary.main,
  },
  questionContentCell: {
    width: 600,
    wordBreak: 'break-word',
  },
  groupCell: {
    width: 200,
  },
  actionsCell: {
    width: 100,
  },
  buttonCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectWrapper: {
    paddingRight: 24,
  },
  root: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
  },
  paginationRoot: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: 1,
  },
  editTableCell: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.main,
    paddingTop: 24,
    paddingBottom: 24,
  },
  answerSetWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
