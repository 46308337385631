export default {
  usersAddEdit: {
    first_name: 'name',
    last_name: 'surname',
    email: 'email',
    role_id: 'role',
    status: 'status',
  },
  questionAddEdit: {
    name: 'content',
    question_type_id: 'type',
    question_group_id: 'group',
    answer_set_id: 'set',
  },
  organizationAddEdit: {
    name: 'name',
    city: 'city',
    number: 'number',
    postal_code: 'postalCode',
    consent: 'consent',
    organization_type_id: 'type',
    organization_status_id: 'status',
  },
  answersAddEdit: {
    label: 'label',
    answer_set_id: 'answerSet',
  },
  participantEdit: {
    first_name: 'name',
    last_name: 'surname',
    user_city: 'city',
    organization_id: 'organization',
    user_sex: 'userSex',
    user_birth_month: 'birthMonth',
    user_birth_year: 'birthYear',
    user_birth_day: 'birthDay',
  },
  surveyCreator: {
    question_type_id: 'type',
    question_group_id: 'group',
    name: 'content',
    answer_set_id: 'answerSet',
  },
};
