import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _isEqual from 'lodash/isEqual';
import _forEach from 'lodash/forEach';

import PromiseAll from 'utils/PromiseAll';
import { historyShape, locationShape } from 'utils/shapes/router.shapes';

import {
  loadUserData, getRowsPerPageOptions, clearPagingData,
} from 'containers/store';

const unauthorizedRoutes = ['/login', '/forgotPassword', '/resetPassword/.+'];

class Global extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    clearPagingData: PropTypes.func.isRequired,
    getRowsPerPageOptions: PropTypes.func.isRequired,
    history: historyShape.isRequired,
    loadUserData: PropTypes.func.isRequired,
    location: locationShape.isRequired,
  };

  constructor(props) {
    super(props);

    this.onHistoryChange(props.history.location, 'PUSH');

    this.state = {
      userDataLoaded: false,
    };
  }

  componentDidMount() {
    let isUnauthorized = false;

    _forEach(unauthorizedRoutes, (el) => {
      const regexp = new RegExp(el);
      if (this.props.location.pathname.match(regexp)) {
        isUnauthorized = true;
      }
    });

    if (isUnauthorized) {
      this.props.getRowsPerPageOptions().then(() => {
        this.setDataLoaded();
      }).catch(() => {
        this.setDataLoaded();
      });
    } else {
      PromiseAll({
        userData: this.props.loadUserData(),
        rowsPerPageOptions: this.props.getRowsPerPageOptions(),
      }).then(() => {
        this.setDataLoaded();
      }).catch(() => {
        this.setDataLoaded();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname
      || !_isEqual(this.props.location.state, nextProps.location.state)) {
      this.onHistoryChange(nextProps.location, nextProps.history.action);
    }
  }

  setDataLoaded = () => {
    this.setState({
      userDataLoaded: true,
    });
  }

  onHistoryChange = () => {
    this.props.clearPagingData();
  };

  redirectToLogin = () => {
    window.location.pathname = '/';
  };

  componentDidCatch(err) {
    Sentry.captureException(err);
  }

  render() {
    return this.state.userDataLoaded ? this.props.children : null;
  }
}

const mapDispatchToProps = {
  loadUserData,
  getRowsPerPageOptions,
  clearPagingData,
};

const mapStateToProps = (state) => ({
  ...state.ResetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Global));
