export default () => ({
  questionsMappingWrapper: {
    marginTop: 12,
  },
  questionSelectWrapper: {
    marginBottom: 12,
  },
  questionsWrapper: {
    marginTop: 12,
  },
});
