import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Formik } from 'formik';
import _forEach from 'lodash/forEach';
import _findIndex from 'lodash/findIndex';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormField from 'components/FormField';
import { itemShape } from 'utils/shapes/select.shapes';
import { classesShape } from 'utils/shapes/classesShape';

import { Typography } from '@material-ui/core';
import messages from './QuestionParamsFormDialog.messages';
import questionParamsStyles from './QuestionParamsFormDialog.styles';

const submitHelper = (values, onSubmit, answers) => {
  const answersMapping = [];

  _forEach(values, (el, key) => {
    if (key.match(/answer-/) && el) {
      const parts = key.split('-');
      const answerIndex = parts[1];

      answersMapping.push({
        answerId: answers[answerIndex].id,
        questionId: el,
      });
    }
  });

  const response = {
    answersMapping,
    isLast: values.isLast,
  };

  onSubmit(response);
};

const FormDialog = ({
  intl,
  open,
  onClose,
  onDialogClose,
  isLast,
  id,
  onSubmit,
  answers,
  questions,
  classes,
  questionMappings,
}) => {
  const initialValues = {};

  _forEach(questionMappings, (el) => {
    const answerIndex = _findIndex(answers, (el1) => el1.id === el.answerId);

    initialValues[`answer-${answerIndex}`] = el.questionId;
  });

  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle>{intl.formatMessage(messages.title)}</DialogTitle>
      <Formik
        initialValues={{
          isLast,
          ...initialValues,
        }}
        onSubmit={(values) => { submitHelper(values, onSubmit, answers); }}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <React.Fragment>
            <DialogContent>
              <FormControlLabel
                control={(
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={(fieldName) => { setFieldValue(fieldName, !values[fieldName]); }}
                    errors={errors}
                    touched={touched}
                    name="isLast"
                  >
                    <Checkbox
                      id={id}
                      checked={Boolean(values.isLast)}
                      value
                      type="checkbox"
                    />
                  </FormField>
                  )}
                label={intl.formatMessage(messages.isLast)}
              />
              <div className={classes.questionsMappingWrapper}>
                <Typography variant="body1">
                  {intl.formatMessage(messages.questionsMapping)}
                </Typography>
                <div className={classes.questionsWrapper}>
                  {
                      answers.map((el, key) => (
                        <React.Fragment key={el.id}>
                          <Typography variant="h6">
                            {el.name}
                          </Typography>
                          <div className={classes.questionSelectWrapper}>
                            <FormField
                              onBlur={setFieldTouched}
                              onChange={setFieldValue}
                              errors={errors}
                              touched={touched}
                              name={`answer-${key}`}
                              label={intl.formatMessage(messages.chooseQuestion)}
                              fullWidth
                            >
                              <Select
                                value={values[`answer-${key}`] || ''}
                              >
                                <MenuItem value="">
                                  <FormattedMessage {...messages.none} />
                                </MenuItem>
                                {
                                  questions.map((el1) => (
                                    <MenuItem value={el1.id} key={el1.id}>
                                      { /* eslint-disable-next-line react/no-danger */ }
                                      <div dangerouslySetInnerHTML={{ __html: el1.name }} />
                                    </MenuItem>
                                  ))
                                }
                              </Select>
                            </FormField>
                          </div>
                        </React.Fragment>
                      ))
                    }
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { onClose(); resetForm(); }} color="primary">
                {intl.formatMessage(messages.cancel)}
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {intl.formatMessage(messages.save)}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

FormDialog.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(itemShape),
  classes: classesShape,
  id: PropTypes.string,
  isLast: PropTypes.bool,
  open: PropTypes.bool,
  questionMappings: PropTypes.arrayOf(PropTypes.shape({
    answerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  questions: PropTypes.arrayOf(itemShape),
  onClose: PropTypes.func,
  onDialogClose: PropTypes.func,
};

FormDialog.defaultProps = {
  answers: [],
  classes: {},
  open: false,
  onClose: () => {},
  onDialogClose: () => {},
  id: '',
  isLast: '',
  questions: [],
  questionMappings: [],
};

export default withStyles(questionParamsStyles)(injectIntl(FormDialog));
