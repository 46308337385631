export default (theme) => ({
  title: {
    marginBottom: 12,
  },
  filtersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  answerSetWrapper: {
    width: 240,
    marginLeft: 24,
  },
  contentWrapper: {
    marginTop: 12,
  },
  root: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
  },
  paginationRoot: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: 1,
  },
  addWrapper: {
    position: 'fixed',
    right: 24,
    bottom: 24,
  },
  answerSetCellWrapper: {
    color: theme.palette.primary.main,
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  numberCell: {
    width: 40,
    padding: 12,
  },
  nameCell: {
    width: '70%',
  },
  answerSetCell: {
    width: '15%',
  },
  answerCountCell: {
    width: '20%',
    textAlign: 'center',
  },
  actionsCell: {},
  selectWrapper: {
    paddingRight: 24,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    height: 56,
  },
  resetWrapper: {
    marginLeft: 24,
  },
  searchWrapper: {
    flexGrow: 1,
  },
});
