import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: 'Anuluj',
  content: 'Treść pytania',
  contentFemale: 'Treść pytania (kobiety)',
  questionGroup: 'Grupa',
  questions: 'Pytanie',
  picture: 'Obrazek',
  save: 'Zapisz',
  set: 'Zestaw odpowiedzi',
  systemParams: 'Parametry systemowe',
  type: 'Typ pytania',
  title: 'Ostrzeżenie!',
  contentText: 'Pytanie o identycznej treści istnieje już w repozytorium, czy mimo wszystko chcesz je dodać?',
  cancelButtonText: 'Nie',
  saveButtonText: 'Tak',
});
