import { defineMessages } from 'react-intl';
import _isNumber from 'lodash/isNumber';

const messages = defineMessages({
  fieldValueTooLarge: 'Wartość pola zbyt niska',
});

export default (value, minValue, parseValueToNumber) => {
  const result = {};
  result.isValid = true;

  let newValue = value;

  if (parseValueToNumber) {
    newValue = parseInt(newValue);
  }

  if (_isNumber(newValue) && _isNumber(minValue)) {
    result.isValid = newValue >= minValue;
    result.message = messages.fieldValueTooLarge;
  }

  return result;
};
