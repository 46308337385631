export default (theme) => ({
  title: {
    marginBottom: 12,
  },
  filtersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  organizationWrapper: {
    width: 240,
    marginLeft: 24,
  },
  typeWrapper: {
    width: 240,
    marginLeft: 24,
    marginRight: 24,
  },
  statusWrapper: {
    width: 240,
  },
  contentWrapper: {
    marginTop: 12,
  },
  root: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
  },
  paginationRoot: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: 1,
  },
  addWrapper: {
    position: 'fixed',
    right: 24,
    bottom: 24,
  },
  roleWrapper: {
    color: theme.palette.primary.main,
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  numberCell: {
    width: 10,
    padding: 12,
  },
  nameCell: {
    width: '10%',
  },
  surnameCell: {
    width: '15%',
  },
  emailCell: {
    width: '30%',
  },
  roleCell: {
    width: '15%',
  },
  statusCell: {
    width: '15%',
  },
  actionsCell: {},
  selectWrapper: {
    paddingRight: 24,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    height: 56,
  },
  resetWrapper: {
    marginLeft: 24,
  },
  searchWrapper: {
    flexGrow: 1,
  },
  elipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
