import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import OutlinedTextarea from 'components/OutlinedTextarea';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';

import FormField from 'components/FormField';
import UploadButtonController from 'components/UploadButtonController';
import Autocomplete from 'components/Autocomplete';
import disabledSaveButton from 'utils/disableSaveButton';
import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';
import addQuestionStyles from './AddQuestion.styles';

import messages from './AddQuestion.messages';

const validators = [{
  fieldName: 'content',
  validators: [
    {
      validatorName: 'notEmpty',
    },
    {
      validatorName: 'maxLength',
      parameters: [65535],
    },
  ],
}, {
  fieldName: 'contentFemale',
  validators: [{
    validatorName: 'maxLength',
    parameters: [65535],
  }],
}, {
  fieldName: 'type',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'group',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'answerSet',
  validators: [
    {
      validatorName: 'notEmptyAutocomplete',
    },
  ],
}];

const AddQuestion = ({
  classes, intl, initialValues, onSubmit, groups, types, isEdit, onCancel, onAddNewSet,
  onClear, onFetch, suggestions, disabled, uploadButtonId,
}) => (
  <div>
    {
        isEdit ? null : (
          <div className={classes.title}>
            <Typography variant="h6">
              {intl.formatMessage(messages.addQuestion)}
            </Typography>
          </div>
        )
      }
    <Formik
      initialValues={
          { ...initialValues }
        }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.row}>
              <div className={classes.bigFieldWrapper}>
                <OutlinedTextarea
                  errors={errors}
                  touched={touched}
                  name="content"
                  label={intl.formatMessage(messages.content)}
                  placeholder={intl.formatMessage(messages.content)}
                  labelWidth={95}
                  value={values.content}
                  isRTE
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  idSuffix={isEdit ? 'edit' : 'add'}
                  disabled={disabled}
                />
                {
                  isEdit ? (
                    <span className={classes.warningWrapper}>
                      {intl.formatMessage(messages.warning)}
                    </span>
                  ) : null
                }
              </div>
              <div className={classNames(
                classes.bigFieldWrapper,
                classes.femaleContentWrapper,
              )}
              >
                <OutlinedTextarea
                  errors={errors}
                  touched={touched}
                  name="contentFemale"
                  label={intl.formatMessage(messages.contentFemale)}
                  placeholder={intl.formatMessage(messages.contentFemale)}
                  labelWidth={165}
                  value={values.contentFemale}
                  isRTE
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  idSuffix={isEdit ? 'edit' : 'add'}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={classNames(
              classes.row,
              classes.alignLeft,
            )}
            >
              <div className={classNames(
                classes.smallFieldWrapper,
                classes.disableRightMargin,
              )}
              >
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="answerSet"
                  label={intl.formatMessage(messages.answerSet)}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    onFetch={onFetch}
                    onClear={onClear}
                    icon={<SearchIcon />}
                    labelWidth={140}
                    suggestions={suggestions}
                    disabled={isEdit || disabled}
                    value={values.answerSet}
                  />
                </FormField>
              </div>
              <div className={classNames(classes.smallFieldWrapper, classes.fieldSpacer)}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="type"
                  label={intl.formatMessage(messages.questionType)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.type}
                    input={(
                      <OutlinedInput
                        labelWidth={80}
                        name="type"
                        id="type-picker"
                        disabled={disabled}
                        classes={{
                          root: classes.outlinedInputRoot,
                        }}
                      />
                      )}
                  >
                    {
                        types.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                  </Select>
                </FormField>
              </div>
              <div className={classes.smallFieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="group"
                  label={intl.formatMessage(messages.group)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.group}
                    input={(
                      <OutlinedInput
                        labelWidth={40}
                        name="group"
                        id="group-picker"
                        disabled={disabled}
                        classes={{
                          root: classes.outlinedInputRoot,
                        }}
                      />
                      )}
                  >
                    {
                        groups.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                  </Select>
                </FormField>
              </div>
            </div>
            <div className={classNames(
              classes.uploadButtonWrapper,
              classes.fieldSpacer,
              classes.disableRightMargin,
            )}
            >
              <UploadButtonController
                uploadButtonId={uploadButtonId}
                withoutPreview
                imageUrl={values.questionPicture}
                onChange={(event, imageLocalUrl) => {
                  setFieldValue('questionPicture', imageLocalUrl);
                  setFieldValue('questionImageObject', event);
                }}
              />
            </div>
            <div className={classNames(classes.buttonsWrapper, {
              [classes.editButtonsWrapper]: isEdit,
            })}
            >
              {
                  isEdit ? (
                    <div className={classes.cancelWrapper}>
                      <Button
                        color="inherit"
                        onClick={onCancel}
                        disabled={disabled}
                      >
                        {intl.formatMessage(messages.cancel)}
                      </Button>
                    </div>
                  ) : (
                    <div className={classes.newSetWrapper}>
                      <Button
                        color="secondary"
                        onClick={onAddNewSet}
                        disabled={disabled}
                      >
                        {intl.formatMessage(messages.newSet)}
                      </Button>
                    </div>
                  )
                }
              <div className={classes.saveWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disabled || disabledSaveButton(errors, touched)}
                  fullWidth
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              </div>
            </div>
          </form>
          <Prompt
            when={!_isEqual(values, initialValues) && !isSubmitting}
            message={intl.formatMessage(snackbarMessages.unsaveData)}
          />
        </React.Fragment>
      )}
    />
  </div>
);

AddQuestion.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  disabled: PropTypes.bool,
  groups: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    content: PropTypes.string,
    contentFemale: PropTypes.string,
    answerSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    group: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isEdit: PropTypes.bool,
  suggestions: PropTypes.arrayOf(itemShape),
  types: PropTypes.arrayOf(itemShape),
  uploadButtonId: PropTypes.string,
  onAddNewSet: PropTypes.func,
  onCancel: PropTypes.func,
  onClear: PropTypes.func,
  onFetch: PropTypes.func,
};

AddQuestion.defaultProps = {
  classes: {},
  disabled: false,
  initialValues: {},
  uploadButtonId: '',
  groups: [],
  isEdit: false,
  suggestions: [],
  types: [],
  onAddNewSet: () => {},
  onCancel: () => {},
  onClear: () => {},
  onFetch: () => {},
};

export default withStyles(addQuestionStyles)(injectIntl(AddQuestion));
