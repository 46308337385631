import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import usersStyles from './Organizations.styles';

import messages from './Organizations.messages';

const validators = [{
  fieldName: 'search',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const getFromMeta = (value, meta) => {
  const item = _find(meta, (el) => el.id === value);

  return item.name || value;
};

const Organizations = ({
  onAddClick,
  classes,
  intl,
  initialValues,
  onSearchSubmit,
  items,
  totalItemsCount,
  page,
  consents,
  organizationTypes,
  organizationStatuses,
  rowsPerPageOptions,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onDelete,
  onEdit,
  sortingDirection,
  sortingField,
  onChangeSort,
}) => {
  const head = [
    {
      id: 'number',
      label: '#',
      sortable: false,
    },
    {
      id: 'name',
      label: intl.formatMessage(messages.name),
      sortable: true,
      class: classes.nameCell,
    },
    {
      id: 'city',
      label: intl.formatMessage(messages.city),
      sortable: true,
    },
    {
      id: 'street',
      label: intl.formatMessage(messages.street),
      sortable: true,
      class: classes.streetCell,
    },
    {
      id: 'buildingNumber',
      label: intl.formatMessage(messages.number),
      sortable: true,
    },
    {
      id: 'postalCode',
      label: intl.formatMessage(messages.postalCode),
      sortable: true,
      class: classes.postalCodeCell,
    },
    {
      id: 'consent',
      label: intl.formatMessage(messages.consent),
      sortable: true,
      class: classes.consentCell,
    },
    {
      id: 'type',
      label: intl.formatMessage(messages.type),
      sortable: true,
      class: classes.typeCell,
    },
    {
      id: 'status',
      label: intl.formatMessage(messages.status),
      sortable: true,
    },
    {
      id: 'actions',
      label: intl.formatMessage(messages.actions),
      class: classes.actionsCell,
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">{intl.formatMessage(messages.usersList)}</Typography>
      </div>
      <Formik
        initialValues={{ ...initialValues }}
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSearchSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          dirty,
          handleReset,
          submitCount,
          isSubmitting,
          setSubmitting,
        }) => (
          <form onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}>
            <div className={classes.filtersWrapper}>
              <div className={classes.searchWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="search"
                  label={intl.formatMessage(messages.findOrganization)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.search}
                    labelWidth={120}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.organizationWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="consent"
                  label={intl.formatMessage(messages.consent)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.consent}
                    input={(
                      <OutlinedInput
                        labelWidth={45}
                        name="consent"
                        id="consent-picker"
                      />
                    )}
                  >
                    {
                      consents.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.organizationWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="status"
                  label={intl.formatMessage(messages.status)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.status}
                    input={(
                      <OutlinedInput
                        labelWidth={115}
                        name="status"
                        id="status-picker"
                      />
                    )}
                  >
                    {
                      organizationStatuses.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.organizationWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="type"
                  label={intl.formatMessage(messages.type)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.type}
                    input={(
                      <OutlinedInput
                        labelWidth={95}
                        name="type"
                        id="type-picker"
                      />
                    )}
                  >
                    {
                      organizationTypes.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                >
                  {intl.formatMessage(messages.filter)}
                </Button>
                <div className={classes.resetWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      handleReset(event);
                      onSearchSubmit({});
                    }}
                    disabled={!(submitCount || dirty)}
                  >
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className={classes.contentWrapper}>
        {items.length > 0 ? (
          <React.Fragment>
            <Table
              stickyHeader
              className={classes.root}
            >
              <TableHead
                rows={head}
                sortingDirection={sortingDirection}
                sortingField={sortingField}
                onChangeSort={onChangeSort}
              />
              <TableBody>
                {items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                      {row.id}
                    </TableCell>
                    <TableCell className={classes.nameCell}>{row.name}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell className={classes.streetCell}>{row.street}</TableCell>
                    <TableCell>{row.number}</TableCell>
                    <TableCell className={classes.postalCodeCell}>{row.postalCode}</TableCell>
                    <TableCell className={classes.consentCell}>
                      {getFromMeta(row.consent, consents)}
                    </TableCell>
                    <TableCell className={classes.typeCell}>
                      {getFromMeta(row.organizationType, organizationTypes)}
                    </TableCell>
                    <TableCell>
                      {getFromMeta(row.organizationStatus, organizationStatuses)}
                    </TableCell>
                    <TableCell className={classNames(classes.buttonCell, classes.actionsCell)}>
                      <div className={classes.actionsWrapper}>
                        <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                          <IconButton onClick={() => onDelete(row.apiId)}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                          <IconButton onClick={() => onEdit(row.apiId)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalItemsCount}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              className={classNames({
                [classes.caption]: true,
                [classes.paginationRoot]: true,
              })}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
              labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
              classes={{
                select: classes.selectWrapper,
              }}
            />
          </React.Fragment>
        ) : null}
      </div>
      <div className={classes.addWrapper}>
        <Fab color="primary" onClick={onAddClick}>
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

Organizations.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  consents: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    search: PropTypes.string,
    consent: PropTypes.string,
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      city: PropTypes.string,
      number: PropTypes.string,
      postalCode: PropTypes.string,
      consent: PropTypes.bool,
    }),
  ),
  organizationStatuses: PropTypes.arrayOf(itemShape),
  organizationTypes: PropTypes.arrayOf(itemShape),
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  onChangeSort: PropTypes.func,
};

Organizations.defaultProps = {
  classes: {},
  items: [],
  initialValues: {},
  consents: [],
  organizationTypes: [],
  organizationStatuses: [],
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
};

export default withStyles(usersStyles)(injectIntl(Organizations));
