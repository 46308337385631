import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ForgotPasswordPage from 'components/pages/ForgotPassword';

import { onSendClick } from './store';

const ForgotPassword = (props) => (
  <ForgotPasswordPage
    {...props}
    onSendClick={props.onSendClick}
  />
);

ForgotPassword.propTypes = {
  onSendClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSendClick,
};

const mapStateToProps = (state) => ({
  ...state.ForgotPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ForgotPassword));
