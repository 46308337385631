const sortMapping = {
  asc: 'desc',
  desc: 'asc',
};

export default (fieldName, sortDirection, currentSortField, mappedFieldName) => {
  const newDirection = mappedFieldName === currentSortField ? sortMapping[sortDirection] : 'asc';
  const newFieldName = fieldName;

  return {
    fieldName: newFieldName,
    sortDirection: newDirection,
  };
};
