import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';

import classNames from 'classnames';

import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import PreviewPopup from 'components/PreviewPopup';
import AnswersPopup from 'components/AnswersPopup';
import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import FormField from 'components/FormField';

import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import questionsStyles from './Questions.styles';

import messages from './Questions.messages';

const validators = [{
  fieldName: 'search',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const getFromMeta = (value, meta) => {
  const item = _find(meta, (el) => el.id === value);

  return item.name || value;
};

const Questions = ({
  onAddClick,
  classes,
  intl,
  initialValues,
  onSearchSubmit,
  types,
  groups,
  items,
  totalItemsCount,
  page,
  rowsPerPageOptions,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  onDelete,
  onEdit,
  sets,
  sortingDirection,
  sortingField,
  onChangeSort,
}) => {
  const head = [
    {
      id: 'number',
      label: '#',
      sortable: false,
    },
    {
      id: 'picture',
      label: intl.formatMessage(messages.picture),
      sortable: false,
    },
    {
      id: 'contents',
      label: intl.formatMessage(messages.contents),
      sortable: true,
    },
    {
      id: 'set',
      label: intl.formatMessage(messages.set),
      sortable: true,
    },
    {
      id: 'type',
      label: intl.formatMessage(messages.type),
      sortable: true,
    },
    {
      id: 'group',
      label: intl.formatMessage(messages.questionGroup),
      sortable: true,
    },
    {
      id: 'surveys',
      label: intl.formatMessage(messages.surveys),
    },
    {
      id: 'actions',
      label: intl.formatMessage(messages.actions),
      sortable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">{intl.formatMessage(messages.questionsList)}</Typography>
      </div>
      <Formik
        initialValues={{ ...initialValues }}
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSearchSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          dirty,
          handleReset,
          isSubmitting,
          submitCount,
          setSubmitting,
        }) => (
          <form onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}>
            <div className={classes.filtersWrapper}>
              <div className={classes.searchWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="search"
                  label={intl.formatMessage(messages.findQuestion)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.search}
                    labelWidth={105}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.setWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="set"
                  label={intl.formatMessage(messages.set)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.set}
                    input={
                      <OutlinedInput labelWidth={135} name="set" id="set-picker" />
                    }
                  >
                    {
                      sets.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.typeWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="type"
                  label={intl.formatMessage(messages.type)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.type}
                    input={<OutlinedInput labelWidth={90} name="type" id="type-picker" />}
                  >
                    {
                      types.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.groupWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="group"
                  label={intl.formatMessage(messages.questionGroup)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.group}
                    input={<OutlinedInput labelWidth={50} name="group" id="group-picker" />}
                  >
                    {
                      groups.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                >
                  {intl.formatMessage(messages.filter)}
                </Button>
                <div className={classes.resetWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      handleReset(event);
                      onSearchSubmit({});
                    }}
                    disabled={!(submitCount || dirty)}
                  >
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className={classes.contentWrapper}>
        {items.length > 0 ? (
          <React.Fragment>
            <Table
              stickyHeader
              className={classes.root}
            >
              <TableHead
                rows={head}
                sortingDirection={sortingDirection}
                sortingField={sortingField}
                onChangeSort={onChangeSort}
              />
              <TableBody>
                {items.map((row) => {
                  const areSurveysAssigned = !_isEmpty(row.surveys);
                  let surveys = null;

                  if (areSurveysAssigned && row.surveys.items.length > 1) {
                    surveys = (
                      <div className={classes.surveyCell}>
                        <span className={classes.firstSurveyName}>
                          {row.surveys.firstSurvey.name}
                        </span>
                        <AnswersPopup
                          noPopupMessage
                          answers={row.surveys.items}
                        />
                      </div>
                    );
                  } else if (areSurveysAssigned && row.surveys.items.length === 1) {
                    surveys = row.surveys.firstSurvey.name;
                  }

                  return (
                    <TableRow key={row.id}>
                      <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        { row.imageUrl ? (
                          <PreviewPopup imageUrl={row.imageUrl} />
                        ) : null }
                      </TableCell>
                      <TableCell className={classes.questionContent}>
                        { /* eslint-disable-next-line react/no-danger */ }
                        <div dangerouslySetInnerHTML={{ __html: row.content }} />
                      </TableCell>
                      <TableCell>{getFromMeta(row.set, sets)}</TableCell>
                      <TableCell className={classes.questionTypeWrapper}>
                        {getFromMeta(row.type, types)}
                      </TableCell>
                      <TableCell>{getFromMeta(row.group, groups)}</TableCell>
                      <TableCell>{surveys}</TableCell>
                      <TableCell className={classNames(classes.buttonCell, classes.actionsCell)}>
                        <div className={classes.actionsWrapper}>
                          <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                            <IconButton onClick={() => onDelete(row.apiId)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                            <IconButton onClick={() => onEdit(row.apiId)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={totalItemsCount}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              className={classNames({
                [classes.caption]: true,
                [classes.paginationRoot]: true,
              })}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
              labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
              classes={{
                select: classes.selectWrapper,
              }}
            />
          </React.Fragment>
        ) : null}
      </div>
      <div className={classes.addWrapper}>
        <Fab color="primary" onClick={onAddClick}>
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

Questions.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  groups: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    organization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    search: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      surname: PropTypes.string,
      email: PropTypes.string,
      role: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
  sets: PropTypes.arrayOf(itemShape),
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  types: PropTypes.arrayOf(itemShape),
  onChangeSort: PropTypes.func,
};

Questions.defaultProps = {
  classes: {},
  items: [],
  initialValues: {},
  sets: [],
  groups: [],
  types: [],
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
};

export default withStyles(questionsStyles)(injectIntl(Questions));
