import { replace } from 'connected-react-router';
import { showTransparentLoader, hideLoader, showSnackbar } from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  initialValues: {
    login: '',
    password: '',
    confirmPassword: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const onSubmit = (values, token) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      token,
      password: values.password,
      password_confirmation: values.confirmPassword,
      email: values.login,
    },
  };

  ApiManager.request('post', dispatch, 'reset_password', requestBody).then(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.passwordChanged));
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
