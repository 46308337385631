import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import { push } from 'connected-react-router';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader,
  setPage, setRowsPerPage, setSortingData, downloadSurveyFile,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  items: [],
  resultsId: null,
};

const sortFieldsMapping = {
  gender: 'participants|user_sex',
  birthDate: 'participants|user_birth_year',
  uniqueId: 'uniqueid',
};

const mapResults = (surveys, startingPosition) => surveys.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  gender: el.participant.user_sex,
  birthDate: el.participant.user_birth_year,
  uniqueId: el.uniqueid,
  results: el.participant.results.map((el1) => ({
    id: el1.id,
    question: el1.question.name,
    answer: el1.answer.label,
  })),
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEY_RESULTS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_SURVEY_RESULTS: 'SURVEY_RESULTS/SET_FILTERED_SURVEY_RESULTS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapResults(action.responses.surveyResults.data.items, 0),
        totalItemsCount: action.responses.surveyResults.data.total,
        resultsId: action.routeState.id,
      };
    }

    case actionTypes.SET_FILTERED_SURVEY_RESULTS: {
      return {
        ...state,
        items: mapResults(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses, routeState) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
  routeState,
});

const setFilteredSurveysResults = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_SURVEY_RESULTS,
  response,
  startCountFrom,
});

const loadSurveyResults = (id, params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
    findBySession: id,
  };

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('session_participants', data);

  return ApiManager.request('get', dispatch, url);
};

const getSurveyResults = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);
  const store = getStore().SurveyResults;

  dispatch(loadSurveyResults(store.resultsId, data)).then((response) => {
    dispatch(setFilteredSurveysResults(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getSurveyResults());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getSurveyResults());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getSurveyResults());
};

export const onEditData = (id) => (dispatch) => {
  dispatch(push('/participantEdit', { id }));
};

export const onDownload = () => (dispatch, getStore) => {
  const store = getStore().SurveyResults;
  dispatch(downloadSurveyFile(store.resultsId));
};

export const loadPageData = (routeState) => (dispatch, getStore) => {
  dispatch(showLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    surveyResults: dispatch(loadSurveyResults(routeState.id, data)),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses, routeState));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
