import React from 'react';
import PropTypes from 'prop-types';
import { Formik, FieldArray } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import FormField from 'components/FormField';
import { classesShape } from 'utils/shapes/classesShape';
import disabledSaveButton from 'utils/disableSaveButton';
import { itemShape } from 'utils/shapes/select.shapes';
import validator from 'utils/validator/core';
import snackbarMessages from 'utils/snackbarMessages';

import newSetStyles from './NewSet.styles';
import messages from './NewSet.messages';

const validators = [{
  fieldName: 'name',
  validators: [{
    validatorName: 'notEmpty',
  }],
}, {
  fieldName: 'type',
  validators: [{
    validatorName: 'notEmpty',
  }],
}, {
  fieldName: 'range',
  validators: [{
    validatorName: 'notEmpty',
  }, {
    validatorName: 'greaterThan',
    parameters: [2, true],
    customErrorMessage: messages.greaterThan,
  }, {
    validatorName: 'maxDecimalPlaces',
    parameters: [0],
    customErrorMessage: messages.mustBeInteger,
  }, {
    validatorName: 'lowerOrEqualTo',
    parameters: [10],
  }],
}, {
  fieldName: 'answers',
  isArrayValidation: true,
  validators: [{
    validatorName: 'answersValidator',
  }],
}, {
  fieldName: 'weights',
  isArrayValidation: true,
  validators: [{
    validatorName: 'weightsValidator',
  }],
}];

const onRangeChange = (value, values, setFieldValue, setFieldTouched) => {
  const maxRange = 10;
  const prevValue = values.range || 0;
  const answers = [...values.answers];
  const weights = [...values.weights];

  if (prevValue > value) {
    const elementsToRemove = Math.floor(prevValue) - Math.floor(value);

    answers.splice(-elementsToRemove, elementsToRemove);
    weights.splice(-elementsToRemove, elementsToRemove);
  } else if (value > 0 && value <= maxRange) {
    const elementsToAdd = Math.floor(value) - Math.floor(prevValue);

    for (let i = 0; i < elementsToAdd; i += 1) {
      answers.push('');
      weights.push('');
    }
  }

  setFieldValue('range', value);
  setFieldTouched('range', true);
  setFieldValue('answers', answers);
  setFieldValue('weights', weights);
};

const onAnswerChange = (value, key, values, setFieldValue) => {
  const answers = [...values.answers];

  answers[key] = String(value);

  setFieldValue('answers', answers);
};

const onAnswerWeightChange = (value, key, values, setFieldValue) => {
  const weights = [...values.weights];

  weights[key] = value;

  setFieldValue('weights', weights);
};

const NewSet = ({
  intl, classes, onCancel, onSubmit, initialValues, types,
}) => (
  <React.Fragment>
    <Formik
      initialValues={
        { ...initialValues }
      }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
        submitCount,
      }) => (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.dataWrapper}>
              <div className={classes.basicDataWrapper}>
                <Typography variant="h6">
                  {intl.formatMessage(messages.basicData)}
                </Typography>
                <div className={classes.dataContentWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="name"
                    label={intl.formatMessage(messages.name)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.name}
                      labelWidth={115}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
              </div>
              <div className={classes.paramsWrapper}>
                <Typography variant="h6">
                  {intl.formatMessage(messages.params)}
                </Typography>
                <div className={classes.dataContentWrapper}>
                  <div className={classes.rangeWrapper}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={(name, value) => {
                        onRangeChange(value, values, setFieldValue, setFieldTouched);
                      }}
                      errors={errors}
                      touched={touched}
                      name="range"
                      label={intl.formatMessage(messages.range)}
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        type="number"
                        value={values.range}
                        labelWidth={50}
                        margin="none"
                        variant="outlined"
                      />
                    </FormField>
                  </div>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="type"
                    label={intl.formatMessage(messages.type)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.type}
                      input={(
                        <OutlinedInput
                          labelWidth={30}
                          name="type"
                          id="type-picker"
                        />
                      )}
                    >
                      {
                        types.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
              </div>
            </div>
            <div className={classes.syntaxWrapper}>
              <Typography variant="h6">
                {intl.formatMessage(messages.syntax)}
              </Typography>
              <FieldArray
                name="answers"
                render={() => (
                  <React.Fragment>
                    {/* eslint-disable react/no-array-index-key */}
                    {
                      values.answers.map((el, key) => (
                        <React.Fragment key={key}>
                          <div className={classes.answerWrapper}>
                            <FormField
                              onBlur={setFieldTouched}
                              onChange={(name, value) => {
                                onAnswerChange(value, key, values, setFieldValue);
                              }}
                              errors={errors}
                              touched={touched}
                              name={`answer-${key}`}
                              label={intl.formatMessage(messages.answer, { number: key + 1 })}
                              variant="outlined"
                              fullWidth
                              runBlurWithChange
                              submitCount={submitCount}
                            >
                              <OutlinedInput
                                value={el}
                                labelWidth={100}
                                margin="none"
                                variant="outlined"
                              />
                            </FormField>
                            <div className={classes.weightWrapper}>
                              <FormField
                                onBlur={setFieldTouched}
                                onChange={(name, value) => {
                                  onAnswerWeightChange(value, key, values, setFieldValue);
                                }}
                                errors={errors}
                                touched={touched}
                                name={`weight-${key}`}
                                variant="outlined"
                                fullWidth
                                runBlurWithChange
                                submitCount={submitCount}
                              >
                                <TextField
                                  label={intl.formatMessage(messages.weight)}
                                  value={values.weights[key]}
                                  type="number"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  margin="normal"
                                  variant="outlined"
                                />
                              </FormField>
                            </div>
                          </div>
                        </React.Fragment>
                      ))
                    }
                  </React.Fragment>
                )}
              />
            </div>
            <div className={classes.buttonsWrapper}>
              <div className={classes.cancelWrapper}>
                <Button
                  color="inherit"
                  onClick={onCancel}
                >
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={disabledSaveButton(errors, touched)}
                onClick={handleSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </div>
          </form>
          <Prompt
            when={!_isEqual(values, initialValues) && !isSubmitting}
            message={intl.formatMessage(snackbarMessages.unsaveData)}
          />
        </React.Fragment>
      )}
    />
  </React.Fragment>
);

NewSet.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    answers: PropTypes.array,
  }),
  types: PropTypes.arrayOf(itemShape),
};

NewSet.defaultProps = {
  initialValues: {},
  classes: {},
  types: [],
};

export default withStyles(newSetStyles)(injectIntl(NewSet));
