export default (getStore, sortFieldsMapping) => {
  const pagingData = getStore().Global.pagingData;

  const values = {
    perPage: pagingData.rowsPerPage,
    page: pagingData.page,
    lastFilterValues: pagingData.lastFilterValues,
  };

  const sorting = {
    fieldName: pagingData.sortingField,
    sortDirection: pagingData.sortingDirection,
  };

  if (sorting.fieldName && sorting.sortDirection) {
    values.sortedBy = sorting.sortDirection;
    values.orderBy = sortFieldsMapping[sorting.fieldName];
  }

  return values;
};
