import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { classesShape } from 'utils/shapes/classesShape';
import suggestionsStyles from './Suggestions.styles';

/* eslint-disable react/no-array-index-key */

const Suggestions = ({
  parts,
  classes,
  isHighlighted,
}) => (
  <MenuItem selected={isHighlighted} component="div">
    <div className={classes.elipsis}>
      {parts.map((part, index) => (part.highlight ? (
        <span key={index} className={classes.bold} title={part.text}>
          {part.text}
        </span>
      ) : (
        <strong key={index} className={classes.regular} title={part.text}>
          {part.text}
        </strong>
      )))}
    </div>
  </MenuItem>
);

Suggestions.propTypes = {
  parts: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  classes: classesShape,
  isHighlighted: PropTypes.bool,
};

Suggestions.defaultProps = {
  classes: {},
  isHighlighted: true,
};

export default withStyles(suggestionsStyles)(Suggestions);
