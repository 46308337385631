import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router';
import { intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import PeopleIcon from '@material-ui/icons/People';
import ChatIcon from '@material-ui/icons/Chat';
import SchoolIcon from '@material-ui/icons/School';
import BarChartIcon from '@material-ui/icons/BarChart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CommentIcon from '@material-ui/icons/Comment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import CardContent from '@material-ui/core/CardContent';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { classesShape } from 'utils/shapes/classesShape';
import { locationShape } from 'utils/shapes/router.shapes';
import logoSmall from 'assets/img/logo_small.png';
import avatar from 'assets/img/avatar.png';
import {
  logout, changePassword, hideSnackbar as hideSnackbarCallback, closeDialog,
} from 'containers/store';
import config from 'config';
import pageLayoutStyles from './PageLayout.styles';

import messages from './PageLayout.messages';

class PageLayout extends React.Component {
  static propTypes = {
    changePassword: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    classes: classesShape.isRequired,
    closeDialog: PropTypes.func.isRequired,
    hideSnackbar: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    loaderVisible: PropTypes.bool.isRequired,
    location: locationShape.isRequired,
    logout: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    snackbarVisible: PropTypes.bool.isRequired,
    transparentLoaderVisible: PropTypes.bool.isRequired,
    activeMenuItem: PropTypes.string,
    apiVersion: PropTypes.string,
    dialogData: PropTypes.shape({
      title: PropTypes.object,
      description: PropTypes.string,
      onAccept: PropTypes.func,
    }),
    dialogOpened: PropTypes.bool,
    snackbarMessage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    title: PropTypes.string,
    userData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  static defaultProps = {
    activeMenuItem: null,
    dialogData: {},
    dialogOpened: false,
    snackbarMessage: null,
    title: null,
    userData: {},
    apiVersion: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      userMenuOpen: false,
    };

    this.items = [{
      id: 'users',
      icon: <PeopleIcon />,
      text: props.intl.formatMessage(messages.users),
      onClick: () => {
        this.handleRedirect('/users');
      },
    }, {
      id: 'surveys',
      icon: <BarChartIcon />,
      text: props.intl.formatMessage(messages.surveys),
      onClick: () => {
        this.handleRedirect('/surveys');
      },
    }, {
      id: 'questions',
      icon: <ChatIcon />,
      text: props.intl.formatMessage(messages.questions),
      onClick: () => {
        this.handleRedirect('/questions');
      },
    }, {
      id: 'answers',
      icon: <CommentIcon />,
      text: props.intl.formatMessage(messages.answers),
      onClick: () => {
        this.handleRedirect('/answers');
      },
    }, {
      id: 'organizations',
      icon: <SchoolIcon />,
      text: props.intl.formatMessage(messages.organizations),
      onClick: () => {
        this.handleRedirect('/organizations');
      },
    }, {
      id: 'surveysResults',
      icon: <AssignmentTurnedInIcon />,
      text: props.intl.formatMessage(messages.surveysResults),
      onClick: () => {
        this.handleRedirect('/surveysResults');
      },
    }];
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleToggle = () => {
    this.setState((state) => ({ userMenuOpen: !state.userMenuOpen }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ userMenuOpen: false });
  };

  handleRedirect = (path) => {
    if (this.props.location.pathname !== path) {
      this.props.push(path);
    }
  };

  handleDialogClose = () => {
    this.props.closeDialog();
  }

  handleDialogAccept = () => {
    this.props.dialogData.onAccept();
    this.handleDialogClose();
  }

  changePassword = () => {
    this.handleClose({});
    this.props.changePassword(this.props.userData.email);
  };

  logout = () => {
    this.handleClose({});
    this.props.logout();
  };

  onAvatarClick = () => {
    this.props.push('/userAddEdit', { id: this.props.userData.id, isViewProfile: true });
  }

  render() {
    const {
      classes, children, activeMenuItem, userData,
      snackbarVisible, hideSnackbar, snackbarMessage,
      loaderVisible, transparentLoaderVisible, dialogData,
    } = this.props;
    const { open, userMenuOpen } = this.state;

    const avatarImage = userData.avatar ? userData.avatar : avatar;

    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              {
                !open ? (
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerOpen}
                    className={classNames(classes.menuButton, {
                      [classes.hide]: this.state.open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null
              }
              <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
                {this.props.title}
              </Typography>
              <div className={classNames(classes.userDataWrapper, {
                [classes.disablePadding]: open,
              })}
              >
                <Typography variant="subtitle1">
                  {`${userData.first_name} ${userData.last_name}`}
                </Typography>
                <div className={classes.userMenuWrapper}>
                  <IconButton
                    buttonRef={(node) => {
                      this.anchorEl = node;
                    }}
                    onClick={this.handleToggle}
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                  <Popper open={userMenuOpen} anchorEl={this.anchorEl} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list-grow"
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                              <MenuItem onClick={this.changePassword}>
                                {this.props.intl.formatMessage(messages.resetPassword)}
                              </MenuItem>
                              <MenuItem onClick={this.logout}>
                                {this.props.intl.formatMessage(messages.logout)}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
                <div className={classes.avatarWrapper} onClick={this.onAvatarClick}>
                  <img src={avatarImage} alt="" />
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <Drawer
            variant="permanent"
            className={classNames(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: classNames({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
          >
            <div className={classes.toolbar}>
              {
                open ? (
                  <React.Fragment>
                    <img src={logoSmall} alt="" />
                    <IconButton onClick={this.handleDrawerClose}>
                      <ChevronLeftIcon color="primary" />
                    </IconButton>
                  </React.Fragment>
                ) : (
                  <img src={logoSmall} alt="" />
                )
              }
            </div>
            <List classes={{
              root: classes.listRoot,
            }}
            >
              {
                this.items.map((el) => (
                  <ListItem
                    button
                    key={el.id}
                    alignItems="center"
                    selected={el.id === activeMenuItem}
                    classes={{
                      selected: classes.selectedListItem,
                    }}
                    onClick={el.onClick}
                  >
                    <ListItemIcon
                      classes={{ root: classes.listItemIconRoot }}
                      className={classNames({
                        [classes.activeIcon]: el.id === activeMenuItem,
                      })}
                    >
                      {
                        open ? (
                          el.icon
                        ) : (
                          <Tooltip title={el.text}>
                            {el.icon}
                          </Tooltip>
                        )
                      }
                    </ListItemIcon>
                    <ListItemText
                      primary={el.text}
                      classes={{
                        primary: el.id === activeMenuItem ? classes.listItemTextRootSelected : null,
                      }}
                    />
                  </ListItem>
                ))
              }
            </List>
          </Drawer>
          <main
            className={classNames({
              [classes.content]: true,
              [classes.loadingPageContent]: loaderVisible,
              [classes.menuOpened]: open,
            })}
          >
            {
              loaderVisible || transparentLoaderVisible ? (
                <div className={classNames({
                  [classes.loaderWrapper]: true,
                  [classes.transparentLoaderWrapper]: transparentLoaderVisible,
                })}
                >
                  {
                    transparentLoaderVisible ? (
                      <div className={classes.transparentLoaderBg} />
                    ) : null
                  }
                  <div className={classes.progressWrapper}>
                    <CircularProgress color="primary" />
                  </div>
                </div>
              ) : null
            }
            <div className={classes.drawerHeader} />
            <Card className={classes.card}>
              <CardContent>
                {children}
              </CardContent>
            </Card>
            <Typography className={classes.version}>
              {`${this.props.intl.formatMessage(messages.appVersion)} ${config.appVersion}`}
              <br />
              {`${this.props.intl.formatMessage(messages.apiVersion)} ${this.props.apiVersion}`}
            </Typography>
          </main>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={snackbarVisible}
          onClose={hideSnackbar}
          message={snackbarMessage ? this.props.intl.formatMessage(snackbarMessage) : null}
        />
        <Dialog
          open={this.props.dialogOpened}
          classes={{
            paper: classes.dialogRoot,
          }}
          onClose={this.handleDialogClose}
        >
          {
            dialogData.title ? (
              <DialogTitle>{this.props.intl.formatMessage(dialogData.title)}</DialogTitle>
            ) : null
          }
          {
            dialogData.description ? (
              <DialogContent>
                <DialogContentText>
                  {dialogData.description}
                </DialogContentText>
              </DialogContent>
            ) : null
          }
          <DialogActions>
            <Button color="primary" onClick={this.handleDialogClose}>
              {this.props.intl.formatMessage(messages.dialogNo)}
            </Button>
            <Button color="primary" autoFocus onClick={this.handleDialogAccept}>
              {this.props.intl.formatMessage(messages.dialogYes)}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = {
  logout,
  changePassword,
  hideSnackbar: hideSnackbarCallback,
  push,
  closeDialog,
};

const mapStateToProps = (state) => ({
  ...state.Global,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(pageLayoutStyles)(
    withRouter(
      injectIntl(PageLayout),
    ),
  ),
);

// export default withStyles(pageLayoutStyles)(injectIntl(PageLayout));
