import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ArchiveIcon from '@material-ui/icons/Archive';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';

import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import { classesShape } from 'utils/shapes/classesShape';
import surveyResultsStyles from './SurveyResults.styles';

import messages from './SurveyResults.messages';

class SurveyResults extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    classes: classesShape,
    initialValues: PropTypes.shape({
      company: PropTypes.object,
    }),
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      company: PropTypes.string,
      gradeData: PropTypes.string,
      surveysCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    sortingDirection: PropTypes.oneOf(['asc', 'desc']),
    sortingField: PropTypes.string,
    onChangeSort: PropTypes.func,
    onDownload: PropTypes.func,
    onEditData: PropTypes.func,
  };

  static defaultProps = {
    classes: {},
    items: [],
    initialValues: {},
    sortingField: null,
    sortingDirection: 'asc',
    onChangeSort: () => {},
    onEditData: () => {},
    onDownload: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      expandedId: null,
    };
  }

  onView = (id) => {
    this.setState({
      expandedId: id,
    });
  }

  render() {
    const {
      classes, intl, items, totalItemsCount, page, rowsPerPageOptions, rowsPerPage,
      onChangePage, onChangeRowsPerPage, sortingDirection, sortingField, onChangeSort,
      onEditData,
    } = this.props;

    const head = [{
      id: 'number',
      label: '#',
      class: classes.numberCell,
      sortable: false,
    }, {
      id: 'gender',
      label: intl.formatMessage(messages.gender),
      class: classes.genderCell,
      sortable: true,
    }, {
      id: 'birthDate',
      label: intl.formatMessage(messages.birthDate),
      class: classes.birthDateCell,
      sortable: true,
    }, {
      id: 'uniqueId',
      label: intl.formatMessage(messages.uniqueId),
      class: classes.uniqueIdCell,
      sortable: true,
    }, {
      id: 'actions',
      label: intl.formatMessage(messages.actions),
      class: classes.actionsCell,
      sortable: false,
    }];

    const genders = {
      false: intl.formatMessage(messages.female),
      true: intl.formatMessage(messages.male),
    };

    return (
      <React.Fragment>
        <div className={classes.title}>
          <Typography variant="h6">
            {intl.formatMessage(messages.surveyResults)}
          </Typography>
          <Tooltip title={intl.formatMessage(messages.download)}>
            <IconButton onClick={() => this.props.onDownload()}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className={classes.contentWrapper}>
          {
            items.length > 0 ? (
              <React.Fragment>
                <Table
                  stickyHeader
                  className={classes.root}
                >
                  <TableHead
                    rows={head}
                    sortingDirection={sortingDirection}
                    sortingField={sortingField}
                    onChangeSort={onChangeSort}
                  />
                  <TableBody>
                    {
                      items.map((row) => (
                        <React.Fragment key={row.id}>
                          <TableRow>
                            <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                              {row.id}
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.genderCell}>
                              {genders[row.gender]}
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.birthDateCell}>
                              {row.birthDate}
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.uniqueIdCell}>
                              {row.uniqueId}
                            </TableCell>
                            <TableCell component="th" scope="row" className={classes.actionsCell}>
                              <div className={classes.actionsWrapper}>
                                <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                                  <IconButton onClick={() => onEditData(row.apiId)}>
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                {
                                  this.state.expandedId !== row.apiId ? (
                                    <Tooltip title={intl.formatMessage(messages.add)} placement="top">
                                      <IconButton onClick={() => this.onView(row.apiId)}>
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                                      <IconButton onClick={() => this.onView(null)}>
                                        <RemoveIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )
                                }
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow
                            className={classNames({
                              [classes.hidden]: this.state.expandedId !== row.apiId,
                            })}
                          >
                            <TableCell
                              colSpan={6}
                              className={classes.editTableCell}
                            >
                              <Collapse in={this.state.expandedId === row.apiId} timeout="auto" unmountOnExit>
                                {
                                  this.state.expandedId === row.apiId ? (
                                    <React.Fragment>
                                      {
                                        row.results.map((el1) => (
                                          <div key={el1.id} className={classes.answerWrapper}>
                                            <Typography>
                                              {el1.question}
                                            </Typography>
                                            <Typography>
                                              {el1.answer}
                                            </Typography>
                                          </div>
                                        ))
                                      }
                                    </React.Fragment>
                                  ) : null
                                }
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))
                    }
                  </TableBody>
                </Table>
                <TablePagination
                  ActionsComponent={TablePaginationActions}
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={totalItemsCount}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                  className={classNames({
                    [classes.caption]: true,
                    [classes.paginationRoot]: true,
                  })}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                  labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
                  classes={{
                    select: classes.selectWrapper,
                  }}
                />
              </React.Fragment>
            ) : null
          }
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(surveyResultsStyles)(injectIntl(SurveyResults));
