export default (theme) => ({
  wrapper: {
    width: 750,
  },
  title: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  fieldWrapper: {
    width: '48%',
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelWrapper: {
    marginRight: 12,
    color: theme.palette.error.main,
  },
});
