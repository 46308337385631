import { defineMessages } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  fieldValueTooLong: 'Wartość pola zbyt długa',
});

export default (value, passedMaxLength) => {
  const result = {};
  let maxLength = passedMaxLength;
  let finalValue = value;

  if (Number.isNaN(passedMaxLength)) {
    maxLength = 1;
  }

  if (isEmpty(value)) {
    finalValue = '';
  }

  const stringifiedValue = finalValue.toString();

  result.isValid = stringifiedValue.length <= maxLength;
  result.message = messages.fieldValueTooLong;

  return result;
};
