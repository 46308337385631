import { defineMessages } from 'react-intl';

export default defineMessages({
  name: 'Nazwa zestawu',
  range: 'Zakres',
  type: 'Typ',
  basicData: 'Dane podstawowe zestawu',
  params: 'Parametry skali ocen',
  syntax: 'Składnia',
  answer: 'Odpowiedź {number}',
  weight: 'Waga',
  cancel: 'Anuluj',
  save: 'Zapisz zestaw',
  mustBeInteger: 'Wartość musi być liczbą całkowitą',
  greaterThan: 'Wartość musi być większa od 1',
});
