import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { intlShape, injectIntl } from 'react-intl';
import SurveyCreatorPage from 'components/pages/SurveyCreator';
import snackbarMessages from 'utils/snackbarMessages';

import pageTitles from 'utils/pageTitles';
import getRouteState from 'utils/getRouteState';
import { historyShape } from 'utils/shapes/router.shapes';
import { setTitle, setActiveMenuItem, showSnackbar } from 'containers/store';

import {
  loadPageData, onAddQuestionSubmit, onRepositorySubmit, onChangePage, onChangeRowsPerPage,
  onSubmit, onEditQuestionSubmit, addNewSet, onFetch, onClear, clearStore, onChangeSort,
} from './store';

class SurveyCreator extends React.Component {
  static propTypes = {
    clearStore: PropTypes.func.isRequired,
    history: historyShape.isRequired,
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    repositoryPagingData: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    setActiveMenuItem: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    onAddQuestionSubmit: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    isForCopy: PropTypes.bool,
    showSnackbar: PropTypes.func,
    surveyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }

  static defaultProps = {
    surveyId: null,
    isForCopy: false,
    showSnackbar: () => {},
  }

  componentDidMount() {
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.surveyCreator));
    this.props.setActiveMenuItem('surveys');
    const routeState = getRouteState(this.props.history);
    this.props.loadPageData(routeState);
  }

  componentWillUnmount() {
    this.props.clearStore();
  }

  render() {
    return this.props.isLoadedPage ? (
      <SurveyCreatorPage
        {...this.props}
        isEdit={!!this.props.surveyId && !this.props.isForCopy}
        onAddSubmit={this.props.onAddQuestionSubmit}
        showDuplicateError={() => this.props.showSnackbar(snackbarMessages.duplicateQuestions)}
        showParamsRemoved={() => this.props.showSnackbar(snackbarMessages.questionParamsWasRemoved)}
        repositoryPagingData={{
          ...this.props.repositoryPagingData,
          onChangePage: this.props.onChangePage,
          onChangeRowsPerPage: this.props.onChangeRowsPerPage,
        }}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  loadPageData,
  onAddQuestionSubmit,
  onRepositorySubmit,
  onChangePage,
  onChangeRowsPerPage,
  onSubmit,
  onEditQuestionSubmit,
  setTitle,
  addNewSet,
  setActiveMenuItem,
  showSnackbar,
  onFetch,
  onClear,
  clearStore,
  onChangeSort,
};

const mapStateToProps = (state) => ({
  ...state.SurveyCreator,
  ...state.Global.pagingData,
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(SurveyCreator)));
