import ApiManager from 'utils/ApiManager';
import { push } from 'connected-react-router';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader,
  showSnackbar,
  showLoader,
  getMeta,
  showTransparentLoader,
  openDialog,
  setPage,
  setRowsPerPage,
  setLastFilterValues,
  setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    search: '',
    consent: '',
    status: '',
    type: '',
  },
  items: [],
  consents: [],
  organizationTypes: [],
  organizationStatuses: [],
};

const sortFieldsMapping = {
  name: 'name',
  city: 'city',
  street: 'street',
  buildingNumber: 'number',
  postalCode: 'postal_code',
  consent: 'consent',
  type: 'organization_type_id',
  status: 'organization_status_id',
};

const mapOrganizations = (organizations, startingPosition) => organizations.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  city: el.city,
  street: el.street,
  number: el.number,
  postalCode: el.postal_code,
  consent: el.consent,
  organizationType: el.organization_type_id,
  organizationStatus: el.organization_status_id,
}));

const mapOrganizationData = (organizations) => organizations.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ORGANIZATIONS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_ORGANIZATIONS: 'ORGANIZATIONS/SET_FILTERED_ORGANIZATIONS',
  SET_META: 'ORGANIZATIONS/SET_META',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapOrganizations(action.responses.organizations.data.items, 0),
        organizationTypes: mapOrganizationData(action.responses.organizationTypes.data),
        organizationStatuses: mapOrganizationData(action.responses.organizationStatuses.data),
        totalItemsCount: action.responses.organizations.data.total,
      };
    }

    case actionTypes.SET_META: {
      return {
        ...state,
        consents: action.response.data.boolList,
      };
    }

    case actionTypes.SET_FILTERED_ORGANIZATIONS: {
      return {
        ...state,
        items: mapOrganizations(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const setFilteredOrganizations = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_ORGANIZATIONS,
  response,
  startCountFrom,
});

const setMeta = (response) => ({
  type: actionTypes.SET_META,
  response,
});

const loadOrganizations = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
  };

  if (params.lastFilterValues.search) {
    data.find = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.type) {
    data.findByType = params.lastFilterValues.type;
  }

  if (params.lastFilterValues.status) {
    data.findByStatus = params.lastFilterValues.status;
  }

  data.findByConsent = params.lastFilterValues.consent;

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('organizations', data);

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizationTypes = () => (dispatch) => {
  const url = 'organization_types';

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizationStatuses = () => (dispatch) => {
  const url = 'organization_statuses';

  return ApiManager.request('get', dispatch, url);
};

const getOrganizations = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadOrganizations(data)).then((response) => {
    dispatch(setFilteredOrganizations(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getOrganizations());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getOrganizations());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getOrganizations());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getOrganizations());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `organizations/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadOrganizations(data)).then((response) => {
      dispatch(setFilteredOrganizations(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.organizationDeleted));
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteOrganizations,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/organizationAddEdit'));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/organizationAddEdit', { id }));
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(getMeta()).then((response) => {
    dispatch(setMeta(response));
    PromiseAll({
      organizations: dispatch(loadOrganizations(data)),
      organizationTypes: dispatch(loadOrganizationTypes()),
      organizationStatuses: dispatch(loadOrganizationStatuses()),
    }).then((responses) => {
      dispatch(loadPageSuccess(responses));
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
