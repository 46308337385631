import { defineMessages } from 'react-intl';

export default defineMessages({
  globalError: 'Wystąpił problem podczas wczytywania. Spróbuj ponownie później.',
  wrongData: 'Niepoprawne dane',
  logoutSuccess: 'Zostałeś pomyślnie wylogowany',
  passwordChanged: 'Hasło zostało zmienione',
  succesfullyResetedPassword: 'Link został wysłany',
  userAddedSuccessfully: 'Użytkownik dodany pomyślnie',
  userEditedSuccessfully: 'Użytkownik zmieniony pomyślnie',
  answerAddedSuccessfully: 'Zestaw dodany pomyślnie',
  answerEditedSuccessfully: 'Zestaw zmieniony pomyślnie',
  userDeleted: 'Użytkownik usunięty pomyślnie',
  organizationAddedSuccessfully: 'Placówka dodana pomyślnie',
  organizationEditedSuccessfully: 'Placówka zmieniona pomyślnie',
  organizationDeleted: 'Placówka usunięta pomyślnie',
  questionDeleted: 'Pytanie usunięte pomyślnie',
  questionAddedSuccessfully: 'Pytanie dodane pomyślnie',
  questionEditedSuccessfully: 'Pytanie zmodyfikowane pomyślnie',
  participantEditedSuccessfully: 'Dane zmodyfikowane pomyślnie',
  setAdded: 'Pomyślnie dodany nowy zestaw odpowiedzi',
  answerDeleted: 'Zestaw usunięty pomyślnie',
  surveyEdited: 'Ankieta zmodyfikowana pomyślnie',
  surveyDeleted: 'Ankieta usunięta pomyślnie',
  surveyAdded: 'Ankieta dodana pomyślnie',
  duplicateQuestions: 'Pytania nie mogą się powtarzać',
  unsaveData: 'Uwaga! niezapisane dane formularza. Czy na pewno chcesz opuścić stronę?',
  networkError: 'Błąd połączenia z internetem',
  questionParamsWasRemoved: 'Niektóre powiązania pytań musiały zostać usunięte',
});
