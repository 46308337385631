export default (theme) => ({
  wrapper: {},
  title: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  separator: {
    width: '100%',
    height: 2,
    backgroundColor: theme.palette.background.main,
  },
  smallFieldWrapper: {
    width: 220,
  },
  fieldSpacer: {
    marginLeft: 24,
    marginRight: 24,
  },
  rightSpacer: {
    marginRight: 24,
  },
  bigFieldWrapper: {
    display: 'flex',
    flexGrow: 1,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  addQuestionWrapper: {
    marginTop: 12,
  },
  currentItemsWrapper: {
    marginTop: 12,
  },
  invisible: {
    display: 'none',
  },
  newSetVisible: {
    display: 'block',
  },
  inputColor: {
    color: theme.palette.text.primary,
  },
});
