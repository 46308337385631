import forEach from 'lodash/forEach';

const createURLWithQuery = (url, data) => {
  const ret = [];
  forEach(data, (value, key) => {
    if (data[key] !== undefined) {
      ret.push(`${key}=${encodeURIComponent(value)}`);
    }
  });
  return `${url}?${ret.join('&')}`;
};

export default createURLWithQuery;
