import { defineMessages } from 'react-intl';

export default defineMessages({
  basicData: 'Dane podstawowe ankiety',
  name: 'Nazwa ankiety',
  group: 'Grupa',
  surveyRespondent: 'Respondent',
  surveyType: 'Rodzaj',
  timeLimit: 'Limit czasu ankiety',
  minutes: 'minut',
  questionsRepository: 'Repozytorium pytań',
  repoFind: 'Wpisz treść',
  questionType: 'Typ pytania',
  addQuestion: 'Dodaj pytanie',
  questionGroup: 'Grupa',
  questionTitle: 'Treść pytania',
  answerSet: 'Zestaw odpowiedzi',
  questionQuestionType: 'Typ pytania',
  questionQuestionGroup: 'Grupa',
  save: 'Zapisz ankietę',
  surveyStatus: 'Status ankiety',
  onlyNumbers: 'To pole powinno zawierać tylko liczby dodatnie',
  dialogTitle: 'Zmień pozycje pytania',
  dialogLabel: 'Wprowadź nową pozycję',
});
