import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldValueTooLow: 'Wartość pola zbyt niska',
});

export default (value, minValue) => {
  const result = {};

  result.isValid = value >= minValue;
  result.message = messages.fieldValueTooLow;

  return result;
};
