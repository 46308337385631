import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const CustomTableHead = ({
  rows, sortingField, sortingDirection, onChangeSort,
}) => (
  <TableHead>
    <TableRow>
      {
        rows.map((el) => (
          <TableCell
            variant="head"
            className={el.class}
            key={el.id}
          >
            {
              el.sortable ? (
                <TableSortLabel
                  active={el.id === sortingField}
                  direction={sortingDirection}
                  onClick={() => { onChangeSort(el.id); }}
                >
                  {el.label}
                </TableSortLabel>
              ) : el.label
            }
          </TableCell>
        ))
      }
    </TableRow>
  </TableHead>
);

CustomTableHead.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    class: PropTypes.string,
    sortable: PropTypes.bool,
  })).isRequired,
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  onChangeSort: PropTypes.func,
};

CustomTableHead.defaultProps = {
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
};

export default CustomTableHead;
