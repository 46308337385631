import _forEach from 'lodash/forEach';
import isFunction from 'lodash/isFunction';
import some from 'lodash/some';
import mappings from './mappings';

export default (values, validators, formatMessage) => {
  const errors = {};

  _forEach(validators, (el) => {
    const shouldRunValidation = !isFunction(el.validateIf) || el.validateIf(values);

    if (shouldRunValidation) {
      const fieldValue = values[el.fieldName];

      let result = {
        isValid: true,
        errorMessageFromDictionary: false,
      };

      some(el.validators, (el1) => {
        const validator = mappings[el1.validatorName];
        const parameters = el1.parameters || [];
        const additionalFields = el1.additionalFields || [];
        const additionalFieldsValues = additionalFields.map((el2) => values[el2]);

        result = validator(fieldValue, ...parameters, ...additionalFieldsValues);
        if (el1.customErrorMessage) {
          result.message = el1.customErrorMessage;
          result.errorMessageFromDictionary = !!el1.errorMessageFromDictionary;
        } else if (el1.errorMessageFromDictionary) {
          result.errorMessageFromDictionary = true;
        }

        return !result.isValid;
      });

      if (el.isArrayValidation && !result.isValid && !result.errorMessageFromDictionary) {
        _forEach(result.fields, (el2, key2) => {
          errors[key2] = formatMessage(el2);
        });
      } else if (el.isArrayValidation && !result.isValid) {
        _forEach(result.fields, (el2, key2) => {
          errors[key2] = el2;
        });
      } else if (!result.isValid && !result.errorMessageFromDictionary) {
        errors[el.fieldName] = formatMessage(result.message);
      } else if (!result.isValid) {
        errors[el.fieldName] = result.message;
      }
    }
  });

  return errors;
};
