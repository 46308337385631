import { defineMessages } from 'react-intl';

export default defineMessages({
  personalData: 'Dane',
  name: 'Imię',
  surname: 'Nazwisko',
  city: 'Miasto',
  organization: 'Placówka',
  userSex: 'Płeć',
  female: 'Kobieta',
  male: 'Mężczyzna',
  birthDay: 'Dzień urodzenia',
  birthYear: 'Rok urodzenia',
  birthMonth: 'Miesiąc urodzenia',
  cancel: 'Anuluj',
  save: 'Zapisz',
});
