import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { actionTypes as globalActionTypes, reducer as Global } from 'containers/store';

// page reducers
import { reducer as Login } from 'containers/Login/store';
import { reducer as ForgotPassword } from 'containers/ForgotPassword/store';
import { reducer as ResetPassword } from 'containers/ResetPassword/store';
import { reducer as Users } from 'containers/Users/store';
import { reducer as Organizations } from 'containers/Organizations/store';
import { reducer as Questions } from 'containers/Questions/store';
import { reducer as UserAddEdit } from 'containers/UserAddEdit/store';
import { reducer as ParticipantEdit } from 'containers/ParticipantEdit/store';
import { reducer as QuestionAddEdit } from 'containers/QuestionAddEdit/store';
import { reducer as OrganizationAddEdit } from 'containers/OrganizationAddEdit/store';
import { reducer as SurveyCreator } from 'containers/SurveyCreator/store';
import { reducer as Surveys } from 'containers/Surveys/store';
import { reducer as Answers } from 'containers/Answers/store';
import { reducer as AnswersAddEdit } from 'containers/AnswersAddEdit/store';
import { reducer as SurveysResults } from 'containers/SurveysResults/store';
import { reducer as SurveyResults } from 'containers/SurveyResults/store';
import { reducer as PreviewSurvey } from 'containers/PreviewSurvey/store';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  Global,
  Login,
  ForgotPassword,
  ResetPassword,
  Users,
  Organizations,
  Questions,
  UserAddEdit,
  ParticipantEdit,
  OrganizationAddEdit,
  QuestionAddEdit,
  SurveyCreator,
  Surveys,
  Answers,
  AnswersAddEdit,
  SurveysResults,
  SurveyResults,
  PreviewSurvey,
});

export const rootReducer = (history) => (state, action) => {
  let newState = { ...state };

  if (action.type === globalActionTypes.CLEAR_DATA) {
    // clear a bit of store
    newState = {};
  }

  return appReducer(history)(newState, action);
};
