export default (theme) => ({
  wrapper: {
    width: 750,
  },
  title: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  organizationsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  fieldWrapper: {
    width: '48%',
  },
  addWrapper: {
    position: 'relative',
    marginBottom: 24,
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '100%',
    justifyContent: 'space-between',
  },
  lastInRow: {
    marginRight: 0,
  },
  addRemoveButton: {
    minHeight: 56,
    minWidth: 56,
    padding: 0,
  },
  buttonWrapper: {
    position: 'absolute',
    left: 370,
    display: 'flex',
    alignItems: 'center',
  },
  buttonAddWrapper: {
    marginBottom: 24,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelWrapper: {
    marginRight: 12,
    color: theme.palette.error.main,
  },
  formControl: {
    width: '48%',
  },
});
