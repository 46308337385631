export default (theme) => ({
  wrapper: {
    width: 602,
    minHeight: 841,
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.background.main}`,
  },
  header: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    height: 48,
    borderBottom: `2px solid ${theme.palette.background.main}`,
    padding: '0 12px',
  },
  logoWrapper: {
    display: 'flex',
    width: 42,
  },
  logo: {
    margin: 'auto',
  },
  headerContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 24px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  userData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  pageContent: {
    height: 'calc(100% - 72px)',
    padding: 12,
  },
  loadingPageContent: {
    padding: 0,
  },
  idWrapper: {
    whiteSpace: 'nowrap',
  },
});
