import greaterThan from './validators/greaterThan';
import isNumber from './validators/isNumber';
import matchRegex from './validators/matchRegex';
import maxLength from './validators/maxLength';
import minLength from './validators/minLength';
import notEmpty from './validators/notEmpty';
import matchOtherField from './validators/matchOtherField';
import maxDecimalPlaces from './validators/maxDecimalPlaces';
import answersValidator from './validators/answersValidator';
import weightsValidator from './validators/weightsValidator';
import notEmptyAutocomplete from './validators/notEmptyAutocomplete';
import oneOf from './validators/oneOf';
import lowerOrEqualTo from './validators/lowerOrEqualTo';
import higherOrEqualTo from './validators/higherOrEqualTo';

export default {
  greaterThan,
  isNumber,
  matchRegex,
  maxLength,
  minLength,
  notEmpty,
  matchOtherField,
  maxDecimalPlaces,
  answersValidator,
  weightsValidator,
  notEmptyAutocomplete,
  oneOf,
  lowerOrEqualTo,
  higherOrEqualTo,
};
