import { defineMessages } from 'react-intl';

export default defineMessages({
  surveysList: 'Lista ankiet',
  findSurvey: 'Znajdź ankietę',
  surveyGroup: 'Grupa ankiety',
  surveyStatus: 'Status ankiety',
  surveyType: 'Rodzaj',
  surveyRespondent: 'Respondent',
  questionsCount: 'Ilość pytań',
  name: 'Nazwa',
  id: 'Id',
  group: 'Grupa',
  status: 'Status',
  maxTime: 'Czas ankiety (minut)',
  actions: 'Akcje',
  rowsPerPage: 'Wierszy:',
  filter: 'Filtruj',
  reset: 'Wyczyść',
  csv: 'CSV',
  sps: 'SPS',
  deleteTooltip: 'Usuń',
  previewTooltip: 'Zobacz',
  createTooltip: 'Utwórz',
  resultsTooltip: 'Pobierz wyniki',
  cSVTooltip: 'Pobierz CSV',
  sPSTooltip: 'Pobierz SPS',
  editTooltip: 'Edytuj',
});
