import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import regexes from 'utils/validator/regexesValidation';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Autocomplete from 'components/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import disabledSaveButton from 'utils/disableSaveButton';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';
import addEditUserStyles from './UserAddEdit.styles';

import messages from './UserAddEdit.messages';

const getValidators = (isEdit) => ([{
  fieldName: 'name',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'surname',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'email',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    }, {
      validatorName: 'matchRegex',
      parameters: [regexes.isEmail],
      customErrorMessage: messages.emailError,
    },
  ],
}, {
  fieldName: 'role',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'status',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'password',
  validateIf: () => !isEdit,
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'confirmPassword',
  validateIf: (values) => values.password,
  validators: [
    {
      validatorName: 'matchOtherField',
      additionalFields: ['password'],
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}]);

const onAddOrganization = (setFieldValue, values) => {
  const organizations = [...values.organizations];

  organizations.push({
    organization: {},
  });

  setFieldValue('organizations', organizations);
};

const onRemoveOrganization = (setFieldValue, values, index) => {
  const organizations = [...values.organizations];

  organizations.splice(index, 1);

  setFieldValue('organizations', organizations);
};

const onOrganizationChange = (value, values, index, setFieldValue) => {
  const organizations = [...values.organizations];

  organizations[index].organization = value;

  setFieldValue('organizations', organizations);
};

const UserAddEdit = ({
  classes, intl, initialValues, onSubmit, statuses, onCancel, onFetch,
  onClear, suggestions, roles, isViewProfile, isEdit,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.title}>
      <Typography variant="h6">
        {intl.formatMessage(messages.personalData)}
      </Typography>
    </div>
    <Formik
      initialValues={
        {
          ...initialValues,
          organizations: initialValues.organizations.map((el) => ({
            organization: { ...el.organization },
          })),
        }
      }
      validate={(values) => validator(values, getValidators(isEdit), intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.name}
                    labelWidth={30}
                    margin="none"
                    variant="outlined"
                    disabled={isViewProfile}
                  />
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="surname"
                  label={intl.formatMessage(messages.surname)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.surname}
                    labelWidth={70}
                    margin="none"
                    variant="outlined"
                    disabled={isViewProfile}
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.contactData)}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="email"
                  label={intl.formatMessage(messages.email)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.email}
                    labelWidth={40}
                    margin="none"
                    variant="outlined"
                    disabled={isViewProfile}
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.ogranizationsData)}
              </Typography>
            </div>
            <div className={classes.organizationsWrapper}>
              {
              values.organizations.map((el, key) => (
                <div
                  className={classNames(
                    classes.addWrapper,
                    { [classes.lastInRow]: (key + 1) % 3 === 0 },
                  )}
                  key={`organization-${key}`} // eslint-disable-line react/no-array-index-key
                >
                  <FormField
                    formControlClassName={classes.formControl}
                    onBlur={setFieldTouched}
                    onChange={
                        (name, value) => {
                          onOrganizationChange(value, values, key, setFieldValue);
                        }
                      }
                    errors={errors}
                    touched={touched}
                    name="organization"
                    label={intl.formatMessage(messages.organization)}
                    variant="outlined"
                  >
                    <Autocomplete
                      fullWidth
                      onFetch={onFetch}
                      onClear={onClear}
                      labelWidth={75}
                      suggestions={suggestions}
                      disabled={isViewProfile}
                      value={el.organization}
                    />
                  </FormField>
                  {
                    isViewProfile || key === 0 ? null : (
                      <div className={classes.buttonWrapper}>
                        {
                          values.organizations.length > 1 && (
                          <Button
                            variant="outlined"
                            className={classes.addRemoveButton}
                            onClick={() => { onRemoveOrganization(setFieldValue, values, key); }}
                          >
                            <RemoveIcon />
                          </Button>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              ))
              }
              {
                !isViewProfile && (
                  <div className={classes.buttonAddWrapper}>
                    <Button
                      variant="outlined"
                      className={classes.addRemoveButton}
                      onClick={() => { onAddOrganization(setFieldValue, values); }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                )
              }
            </div>
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.systemParams)}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="status"
                  label={intl.formatMessage(messages.status)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.status}
                    input={(
                      <OutlinedInput
                        labelWidth={45}
                        name="status"
                        id="status-picker"
                        disabled={isViewProfile}
                      />
                    )}
                  >
                    {
                      statuses.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="role"
                  label={intl.formatMessage(messages.role)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.role}
                    input={(
                      <OutlinedInput
                        labelWidth={30}
                        name="role"
                        id="role-picker"
                        disabled={isViewProfile}
                      />
                    )}
                  >
                    {
                      roles.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
            </div>
            {
            isViewProfile ? null : (
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="password"
                    label={intl.formatMessage(messages.password)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      type="password"
                      value={values.password}
                      labelWidth={40}
                      margin="none"
                      variant="outlined"
                      disabled={isViewProfile}
                    />
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="confirmPassword"
                    label={intl.formatMessage(messages.confirmPassword)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      type="password"
                      value={values.confirmPassword}
                      labelWidth={100}
                      margin="none"
                      variant="outlined"
                      disabled={isViewProfile}
                    />
                  </FormField>
                </div>
              </div>
            )
          }
            {
            isViewProfile ? null : (
              <div className={classes.buttonsWrapper}>
                <div className={classes.cancelWrapper}>
                  <Button
                    color="inherit"
                    onClick={onCancel}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disabledSaveButton(errors, touched)}
                  onClick={handleSubmit}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              </div>
            )
          }
          </form>
          <Prompt
            when={!_isEqual(values, initialValues) && !isSubmitting}
            message={intl.formatMessage(snackbarMessages.unsaveData)}
          />
        </React.Fragment>
      )}
    />
  </div>
);

UserAddEdit.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    status: PropTypes.string,
    role: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    organizations: PropTypes.array,
  }),
  isEdit: PropTypes.bool,
  isViewProfile: PropTypes.bool,
  roles: PropTypes.arrayOf(itemShape),
  statuses: PropTypes.arrayOf(itemShape),
  suggestions: PropTypes.arrayOf(itemShape),
  onClear: PropTypes.func,
  onFetch: PropTypes.func,
};

UserAddEdit.defaultProps = {
  classes: {},
  isEdit: false,
  initialValues: {},
  isViewProfile: false,
  roles: [],
  statuses: [],
  suggestions: [],
  onClear: () => {},
  onFetch: () => {},
};

export default withStyles(addEditUserStyles)(injectIntl(UserAddEdit));
