import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import disabledSaveButton from 'utils/disableSaveButton';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';
import participantEditStyles from './ParticipantEdit.styles';

import messages from './ParticipantEdit.messages';

const validators = [{
  fieldName: 'name',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'surname',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'city',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'organization',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'userSex',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'birthDay',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
    }, {
      validatorName: 'maxLength',
      parameters: [2],
    }, {
      validatorName: 'greaterThan',
      parameters: [1, true],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [31],
    },
  ],
}, {
  fieldName: 'birthYear',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
    }, {
      validatorName: 'maxLength',
      parameters: [4],
    }, {
      validatorName: 'greaterThan',
      parameters: [1990, true],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [2019],
    },
  ],
}, {
  fieldName: 'birthMonth',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
    }, {
      validatorName: 'maxLength',
      parameters: [2],
    }, {
      validatorName: 'greaterThan',
      parameters: [1, true],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [12],
    },
  ],
}];

const ParticipantEdit = ({
  classes, intl, initialValues, onSubmit, onCancel, organizations,
}) => {
  const genders = [{
    id: true,
    name: intl.formatMessage(messages.male),
  }, {
    id: false,
    name: intl.formatMessage(messages.female),
  }];

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.personalData)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="name"
                    label={intl.formatMessage(messages.name)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.name}
                      labelWidth={35}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="surname"
                    label={intl.formatMessage(messages.surname)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.surname}
                      labelWidth={75}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="city"
                    label={intl.formatMessage(messages.city)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.city}
                      labelWidth={50}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="organization"
                    label={intl.formatMessage(messages.organization)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.organization}
                      input={(
                        <OutlinedInput
                          labelWidth={75}
                          name="organization"
                          id="organization-picker"
                        />
                      )}
                    >
                      {
                        organizations.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="birthDay"
                    label={intl.formatMessage(messages.birthDay)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.birthDay}
                      labelWidth={110}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="birthMonth"
                    label={intl.formatMessage(messages.birthMonth)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.birthMonth}
                      labelWidth={130}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="birthYear"
                    label={intl.formatMessage(messages.birthYear)}
                    variant="outlined"
                    fullWidth
                  >
                    <OutlinedInput
                      value={values.birthYear}
                      labelWidth={110}
                      margin="none"
                      variant="outlined"
                    />
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="userSex"
                    label={intl.formatMessage(messages.userSex)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.userSex}
                      input={(
                        <OutlinedInput
                          labelWidth={30}
                          name="userSex"
                          id="userSex-picker"
                        />
                      )}
                    >
                      {
                        genders.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
              </div>
              <div className={classes.buttonsWrapper}>
                <div className={classes.cancelWrapper}>
                  <Button
                    color="inherit"
                    onClick={onCancel}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={disabledSaveButton(errors, touched)}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              </div>
            </form>
            <Prompt
              when={!_isEqual(values, initialValues) && !isSubmitting}
              message={intl.formatMessage(snackbarMessages.unsaveData)}
            />
          </React.Fragment>
        )}
      />
    </div>
  );
};

ParticipantEdit.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    city: PropTypes.string,
    userSex: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
    organization: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  organizations: PropTypes.arrayOf(itemShape),
};

ParticipantEdit.defaultProps = {
  classes: {},
  initialValues: {},
  organizations: [],
};

export default withStyles(participantEditStyles)(injectIntl(ParticipantEdit));
