import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import surveysStyles from './Answers.styles';

import messages from './Answers.messages';

const validators = [{
  fieldName: 'search',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const getFromMeta = (value, meta) => {
  const item = _find(meta, (el) => el.id === value);

  return item.name || value;
};

const Surveys = ({
  onAddClick, classes, intl, initialValues, onSearchSubmit, answerSets, items,
  totalItemsCount, page, rowsPerPageOptions, rowsPerPage,
  onChangePage, onChangeRowsPerPage, onDelete, onEdit, sortingDirection,
  sortingField, onChangeSort,
}) => {
  const head = [{
    id: 'number',
    label: '#',
    class: classes.numberCell,
    sortable: false,
  }, {
    id: 'name',
    label: intl.formatMessage(messages.name),
    class: classes.nameCell,
    sortable: true,
  }, {
    id: 'answerSet',
    label: intl.formatMessage(messages.answerSet),
    class: classes.answerSetCell,
    sortable: true,
  }, {
    id: 'answerCount',
    label: intl.formatMessage(messages.answerCount),
    class: classes.answerCountCell,
    sortable: true,
  }, {
    id: 'actions',
    label: intl.formatMessage(messages.actions),
    class: classes.actionsCell,
    sortable: false,
  }];

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.answersList)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSearchSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          dirty,
          handleReset,
          submitCount,
          isSubmitting,
          setSubmitting,
        }) => (
          <form onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}>
            <div className={classes.filtersWrapper}>
              <div className={classes.searchWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="search"
                  label={intl.formatMessage(messages.findAnswer)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.search}
                    labelWidth={130}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.answerSetWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="answerSet"
                  label={intl.formatMessage(messages.answerSet)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.answerSet}
                    input={(
                      <OutlinedInput
                        labelWidth={140}
                        name="answerSet"
                        id="answerSet-picker"
                      />
                    )}
                  >
                    {
                      answerSets.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                >
                  {intl.formatMessage(messages.filter)}
                </Button>
                <div className={classes.resetWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      handleReset(event);
                      onSearchSubmit({});
                    }}
                    disabled={!(submitCount || dirty)}
                  >
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className={classes.contentWrapper}>
        {
          items.length > 0 ? (
            <React.Fragment>
              <Table
                stickyHeader
                className={classes.root}
              >
                <TableHead
                  rows={head}
                  sortingDirection={sortingDirection}
                  sortingField={sortingField}
                  onChangeSort={onChangeSort}
                />
                <TableBody>
                  {items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                        {row.id}
                      </TableCell>
                      <TableCell className={classes.nameCell}>{row.name}</TableCell>
                      <TableCell
                        className={classNames(classes.answerSetCellWrapper, classes.answerSetCell)}
                      >
                        {getFromMeta(row.answerSetType, answerSets)}
                      </TableCell>
                      <TableCell className={classes.answerCountCell}>{row.answersCount}</TableCell>
                      <TableCell className={classNames(classes.buttonCell, classes.actionsCell)}>
                        <div className={classes.actionsWrapper}>
                          <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                            <IconButton onClick={() => onDelete(row.apiId)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                            <IconButton onClick={() => onEdit(row.apiId)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalItemsCount}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                className={classNames({
                  [classes.caption]: true,
                  [classes.paginationRoot]: true,
                })}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
                classes={{
                  select: classes.selectWrapper,
                }}
              />
            </React.Fragment>
          ) : null
        }
      </div>
      <div className={classes.addWrapper}>
        <Fab color="primary" onClick={onAddClick}>
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

Surveys.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  answerSets: PropTypes.arrayOf(itemShape),
  classes: classesShape,
  initialValues: PropTypes.shape({
    search: PropTypes.string,
    answerSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    answerSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  onChangeSort: PropTypes.func,
};

Surveys.defaultProps = {
  classes: {},
  items: [],
  initialValues: {},
  answerSets: [],
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
};

export default withStyles(surveysStyles)(injectIntl(Surveys));
