import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import FormHelperText from '@material-ui/core/FormHelperText';

import { withStyles } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

import PreviewPopup from 'components/PreviewPopup';
import { classesShape } from 'utils/shapes/classesShape';

import UploadButtonStyles from './UploadButton.styles';
import messages from './UploadButton.messages';

const UploadButton = ({
  intl,
  classes,
  uploaded,
  imageUrl,
  onAddFile,
  onRemoveFile,
  withoutPreview,
  uploadButtonId,
  isErrorImage,
  isErrorImageWeight,
}) => {
  const errorMessage = isErrorImage ? messages.errorTypeFile : messages.errorFileSize;

  return (
    <div className={classes.mainWrapper}>
      {
      !uploaded ? (
        <div className={classes.inputType}>
          <label htmlFor={uploadButtonId}>
            <input
              accept="image/x-png,image/jpeg,image/jpeg"
              className={classes.input}
              id={uploadButtonId}
              type="file"
              onChange={onAddFile}
            />
            <Button variant="contained" component="span" color="primary" className={classes.button}>
              {intl.formatMessage(messages.upload)}
              <CloudUploadIcon className={classes.rightIcon} />
            </Button>
          </label>
          {(isErrorImage || isErrorImageWeight) && (
            <FormHelperText error className={classes.formHelper}>
              {intl.formatMessage(errorMessage)}
            </FormHelperText>
          )}
        </div>
      ) : (
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={onRemoveFile}
          >
            {intl.formatMessage(messages.remove)}
            <DeleteIcon className={classes.rightIcon} />
          </Button>
          {
            !withoutPreview ? (
              <div className={classes.rightIcon}>
                <PreviewPopup imageUrl={imageUrl} />
              </div>
            ) : null
          }
        </div>
      )
    }
    </div>
  );
};

UploadButton.propTypes = {
  intl: intlShape.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func.isRequired,
  classes: classesShape,
  imageUrl: PropTypes.string,
  isErrorImage: PropTypes.bool,
  isErrorImageWeight: PropTypes.bool,
  uploadButtonId: PropTypes.string,
  uploaded: PropTypes.bool,
  withoutPreview: PropTypes.bool,
};

UploadButton.defaultProps = {
  classes: {},
  imageUrl: '',
  withoutPreview: false,
  isErrorImageWeight: false,
  uploaded: false,
  isErrorImage: false,
  uploadButtonId: 'file-upload',
};

export default withStyles(UploadButtonStyles)(injectIntl(UploadButton));
