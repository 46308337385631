import { defineMessages } from 'react-intl';

const messages = defineMessages({
  invalidValues: 'Niepoprawna wartość',
});

export default (value, allowedValues = []) => {
  const result = {};

  result.isValid = allowedValues.indexOf(value) > -1;
  result.message = messages.invalidValues;

  return result;
};
