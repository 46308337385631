export default () => ({
  wrapper: {
    position: 'absolute',
    width: '100%',
    zIndex: 1000,
  },
  elipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
