import theme from '../../../../../theme/theme';

export default () => ({
  title: {
    marginBottom: 12,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  smallFieldWrapper: {
    width: 220,
  },
  fieldSpacer: {
    marginLeft: 24,
    marginRight: 24,
  },
  bigFieldWrapper: {
    width: 'calc(50% - 12px)',
    maxWidth: 852,
  },
  uploadButtonWrapper: {
    paddingTop: '3px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '200px',
  },
  warningWrapper: {
    marginTop: 8,
    color: theme.palette.error.main,
  },
  topSpacer: {
    marginTop: 12,
  },
  disableRightMargin: {
    marginRight: 0,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  editButtonsWrapper: {
    justifyContent: 'flex-end',
  },
  newSetWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 150,
  },
  saveWrapper: {
    width: 170,
  },
  outlinedInputRoot: {
    background: theme.palette.cardBackground,
  },
  cancelWrapper: {
    marginRight: 24,
    color: theme.palette.error.main,
  },
  femaleContentWrapper: {
    marginLeft: 24,
  },
  alignLeft: {
    justifyContent: 'flex-start',
  },
});
