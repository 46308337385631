import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import FormField from 'components/FormField';
import logo from 'assets/img/logo.png';
import { classesShape } from 'utils/shapes/classesShape';
import validator from 'utils/validator/core';
import forgotPasswordStyles from './ForgotPassword.styles';

import messages from './ForgotPassword.messages';

const validators = [
  {
    fieldName: 'login',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
];

const ForgotPassword = ({
  onSendClick, classes, intl, initialValues,
}) => (
  <React.Fragment>
    <div className={classes.logoWrapper}>
      <img src={logo} alt="diagmatic" />
    </div>
    <div className={classes.separator} />
    <div className={classes.forgot}>
      <Typography variant="h5">
        {intl.formatMessage(messages.remindPassword)}
      </Typography>
    </div>
    <div className={classes.forgotDescription}>
      <Typography variant="caption">
        {intl.formatMessage(messages.insertEmail)}
      </Typography>
    </div>
    <Formik
      initialValues={
        { ...initialValues }
      }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSendClick}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.loginWrapper}>
            <FormField
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              errors={errors}
              touched={touched}
              name="login"
              label={intl.formatMessage(messages.emailAddress)}
              variant="outlined"
              fullWidth
            >
              <OutlinedInput
                value={values.login}
                labelWidth={95}
                margin="none"
                variant="outlined"
              />
            </FormField>
          </div>
          <div className={classes.sendButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {intl.formatMessage(messages.send)}
            </Button>
          </div>
        </form>
      )}
    />
  </React.Fragment>
);

ForgotPassword.propTypes = {
  intl: intlShape.isRequired,
  onSendClick: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    login: PropTypes.string,
  }),
};

ForgotPassword.defaultProps = {
  classes: {},
  initialValues: {},
};

export default withStyles(forgotPasswordStyles)(injectIntl(ForgotPassword));
