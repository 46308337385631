import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import _find from 'lodash/find';
import _isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import usersStyles from './Users.styles';

import messages from './Users.messages';

const validators = [{
  fieldName: 'search',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const getFromMeta = (value, meta) => {
  const item = _find(meta, (el) => el.id === value);

  return item.name || value;
};

const Users = ({
  onAddClick, classes, intl, initialValues, onSearchSubmit, types, statuses, items,
  totalItemsCount, page, rowsPerPageOptions, rowsPerPage,
  onChangePage, onChangeRowsPerPage, onDelete, onEdit, onView, organizations, sortingDirection,
  sortingField, onChangeSort, loggedUserId,
}) => {
  const head = [{
    id: 'number',
    label: '#',
    class: classes.numberCell,
    sortable: false,
  }, {
    id: 'name',
    label: intl.formatMessage(messages.name),
    class: classes.nameCell,
    sortable: true,
  }, {
    id: 'surname',
    label: intl.formatMessage(messages.surname),
    class: classes.surnameCell,
    sortable: true,
  }, {
    id: 'email',
    label: intl.formatMessage(messages.email),
    class: classes.emailCell,
    sortable: true,
  }, {
    id: 'role',
    label: intl.formatMessage(messages.role),
    class: classes.roleCell,
    sortable: true,
  }, {
    id: 'status',
    label: intl.formatMessage(messages.status),
    class: classes.statusCell,
    sortable: true,
  }, {
    id: 'actions',
    label: intl.formatMessage(messages.actions),
    class: classes.actionsCell,
    sortable: false,
  }];

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.usersList)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSearchSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          dirty,
          handleReset,
          submitCount,
          isSubmitting,
          setSubmitting,
        }) => (
          <form onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}>
            <div className={classes.filtersWrapper}>
              <div className={classes.searchWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="search"
                  label={intl.formatMessage(messages.findUser)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.search}
                    labelWidth={140}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.organizationWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="organization"
                  label={intl.formatMessage(messages.organization)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.organization}
                    input={(
                      <OutlinedInput
                        labelWidth={70}
                        name="organization"
                        id="organization-picker"
                      />
                    )}
                  >
                    {
                      organizations.map((el) => (
                        <MenuItem
                          value={el.id}
                          key={el.id}
                        >
                          <div className={classes.elipsis}>
                            <span title={el.name}>
                              {el.name}
                            </span>
                          </div>
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.typeWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="type"
                  label={intl.formatMessage(messages.userType)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.type}
                    input={(
                      <OutlinedInput
                        labelWidth={120}
                        name="type"
                        id="type-picker"
                      />
                    )}
                  >
                    {
                      types.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.statusWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="status"
                  label={intl.formatMessage(messages.userStatus)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.status}
                    input={(
                      <OutlinedInput
                        labelWidth={140}
                        name="status"
                        id="status-picker"
                      />
                    )}
                  >
                    {
                      statuses.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                >
                  {intl.formatMessage(messages.filter)}
                </Button>
                <div className={classes.resetWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      handleReset(event);
                      onSearchSubmit({});
                    }}
                    disabled={!(submitCount || dirty)}
                  >
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className={classes.contentWrapper}>
        {
          items.length > 0 ? (
            <React.Fragment>
              <Table
                stickyHeader
                className={classes.root}
              >
                <TableHead
                  rows={head}
                  sortingDirection={sortingDirection}
                  sortingField={sortingField}
                  onChangeSort={onChangeSort}
                />
                <TableBody>
                  {items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                        {row.id}
                      </TableCell>
                      <TableCell className={classes.nameCell}>{row.name}</TableCell>
                      <TableCell className={classes.surnameCell}>{row.surname}</TableCell>
                      <TableCell className={classes.emailCell}>{row.email}</TableCell>
                      <TableCell
                        className={classNames(classes.roleWrapper, classes.roleCell)}
                      >
                        {getFromMeta(row.role, types)}
                      </TableCell>
                      <TableCell className={classes.statusCell}>
                        {getFromMeta(row.status, statuses)}
                      </TableCell>
                      <TableCell className={classNames(classes.buttonCell, classes.actionsCell)}>
                        <div className={classes.actionsWrapper}>
                          {
                            row.apiId === loggedUserId ? null : (
                              <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                                <IconButton onClick={() => onDelete(row.apiId)}>
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            )
                          }
                          <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                            <IconButton onClick={() => onEdit(row.apiId)}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={intl.formatMessage(messages.see)} placement="top">
                            <IconButton onClick={() => onView(row.apiId)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalItemsCount}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                className={classNames({
                  [classes.caption]: true,
                  [classes.paginationRoot]: true,
                })}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
                classes={{
                  select: classes.selectWrapper,
                }}
              />
            </React.Fragment>
          ) : null
        }
      </div>
      <div className={classes.addWrapper}>
        <Fab color="primary" onClick={onAddClick}>
          <AddIcon />
        </Fab>
      </div>
    </React.Fragment>
  );
};

Users.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    organization: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    search: PropTypes.string,
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
  })),
  loggedUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  organizations: PropTypes.arrayOf(itemShape),
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  statuses: PropTypes.arrayOf(itemShape),
  types: PropTypes.arrayOf(itemShape),
  onChangeSort: PropTypes.func,
};

Users.defaultProps = {
  classes: {},
  items: [],
  initialValues: {},
  organizations: [],
  statuses: [],
  types: [],
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
  loggedUserId: null,
};

export default withStyles(usersStyles)(injectIntl(Users));
