import { defineMessages } from 'react-intl';

export default defineMessages({
  surveysResults: 'Lista badań',
  company: 'Placówka',
  name: 'Nazwa',
  gradeData: 'Nazwa klasy',
  surveysCount: 'Ilość ankiet',
  rowsPerPage: 'Wierszy:',
  actions: 'Akcje',
  filter: 'Filtruj',
  reset: 'Wyczyść',
  download: 'Pobierz',
  see: 'Zobacz',
});
