import { defineMessages } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  fieldCannotBeEmpty: 'To pole nie może być puste',
});

export default (value) => {
  const result = {};

  result.isValid = value !== null && value !== undefined
    && value.toString().length > 0 && !_isEmpty(value);
  result.message = messages.fieldCannotBeEmpty;

  return result;
};
