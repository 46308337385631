import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import { classesShape } from 'utils/shapes/classesShape';
import { hideSnackbar as hideSnackbarCallback } from 'containers/store';
import unauthorizedLayoutStyles from './UnauthorizedLayout.styles';

const UnauthorizedLayout = ({
  children, classes, loaderVisible, transparentLoaderVisible,
  hideSnackbar, snackbarVisible, snackbarMessage, intl,
}) => (
  <React.Fragment>
    {
      loaderVisible || transparentLoaderVisible ? (
        <div className={classNames({
          [classes.loaderWrapper]: true,
          [classes.transparentLoaderWrapper]: transparentLoaderVisible,
        })}
        >
          {
            transparentLoaderVisible ? (
              <div className={classes.transparentLoaderBg} />
            ) : null
          }
          <div className={classes.progressWrapper}>
            <CircularProgress color="primary" />
          </div>
        </div>
      ) : null
    }
    <div className={classNames({
      [classes.wrapper]: true,
      [classes.contentWrapperWithLoader]: loaderVisible,
    })}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          {children}
        </CardContent>
      </Card>
    </div>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={snackbarVisible}
      onClose={hideSnackbar}
      message={snackbarMessage ? intl.formatMessage(snackbarMessage) : null}
    />
  </React.Fragment>
);

UnauthorizedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hideSnackbar: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  loaderVisible: PropTypes.bool.isRequired,
  snackbarVisible: PropTypes.bool.isRequired,
  transparentLoaderVisible: PropTypes.bool.isRequired,
  classes: classesShape,
  snackbarMessage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

UnauthorizedLayout.defaultProps = {
  classes: {},
  snackbarMessage: null,
};

const mapDispatchToProps = {
  hideSnackbar: hideSnackbarCallback,
};

const mapStateToProps = (state) => ({
  ...state.Global,
});

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(unauthorizedLayoutStyles)(injectIntl(UnauthorizedLayout)),
);
