import { defineMessages } from 'react-intl';

export default defineMessages({
  users: 'Użytkownicy',
  surveyCreator: 'Kreator ankiet',
  surveys: 'Ankiety',
  organizations: 'Placówki',
  questions: 'Pytania',
  answers: 'Odpowiedzi',
  surveysResults: 'Wyniki badań',
  surveyResults: 'Wyniki badania',
  previewSurvey: 'Podgląd ankiety',
});
