import { defineMessages } from 'react-intl';

export default defineMessages({
  answersList: 'Zestawy odpowiedzi',
  findAnswer: 'Znajdź zestaw',
  answerSet: 'Typ zestawu',
  answerCount: 'Liczba odpowiedzi',
  name: 'Nazwa',
  actions: 'Akcje',
  rowsPerPage: 'Wierszy:',
  filter: 'Filtruj',
  reset: 'Wyczyść',
  delete: 'Usuń',
  edit: 'Edytuj',
});
