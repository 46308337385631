import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  container: {
    display: 'flex',
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
});
