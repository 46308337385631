import isString from 'lodash/isString';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tooManyDecimalPlaces: 'Za dużo miejsc dziesiętnych',
});

export default (value, maxDecimalPlaces) => {
  const result = {};

  const finalValue = isString(value) ? value : String(value);
  const regexp = new RegExp(`\\.\\d{0,${maxDecimalPlaces}}$`, 'g');

  result.isValid = finalValue.indexOf('.') === -1 || regexp.test(finalValue);
  result.message = messages.maxDecimalPlaces;

  return result;
};
