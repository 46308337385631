import { push } from 'connected-react-router';
import saveAs from 'file-saver';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';
import parseContentDisposition from 'utils/parseContentDisposition';

import {
  hideLoader, showSnackbar, showLoader, getMeta, showTransparentLoader,
  openDialog, mapMeta, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    search: '',
    status: '',
    group: '',
    surveyType: '',
    surveyRespondent: '',
  },
  items: [],
  statuses: [],
  groups: [],
};

const sortFieldsMapping = {
  name: 'name',
  group: 'survey_group_id',
  maxTime: 'max_time',
  status: 'status',
  surveyType: 'survey_type_id',
  surveyRespondent: 'survey_respondent_id',
  questionsCount: 'questions_count',
};

const mapSurveys = (surveys, startingPosition) => surveys.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  surveyGroup: el.survey_group_id,
  surveyType: el.survey_type_id,
  questionsCount: el.questions_count,
  surveyRespondent: el.survey_respondent_id,
  maxTime: el.max_time,
  status: el.status,
}));

const mapData = (organizations) => organizations.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'SURVEYS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_SURVEYS: 'SURVEYS/SET_FILTERED_SURVEYS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        statuses: mapMeta(action.responses.meta.data.surveyStatus),
        items: mapSurveys(action.responses.surveys.data.items, 0),
        surveyTypes: mapData(action.responses.surveyTypes.data),
        surveyRespondents: mapData(action.responses.surveyRespondents.data),
        groups: action.responses.groups.data,
        totalItemsCount: action.responses.surveys.data.total,
      };
    }

    case actionTypes.SET_FILTERED_SURVEYS: {
      return {
        ...state,
        items: mapSurveys(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const setFilteredSurveys = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_SURVEYS,
  response,
  startCountFrom,
});

const loadSurveys = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
  };

  if (params.lastFilterValues.search) {
    data.findByName = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.status) {
    data.findByStatus = params.lastFilterValues.status;
  }

  if (params.lastFilterValues.group) {
    data.findBySurveyGroup = params.lastFilterValues.group;
  }

  if (params.lastFilterValues.surveyType) {
    data.findBySurveyType = params.lastFilterValues.surveyType;
  }

  if (params.lastFilterValues.surveyRespondent) {
    data.findBySurveyRespondent = params.lastFilterValues.surveyRespondent;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('surveys', data);

  return ApiManager.request('get', dispatch, url);
};

const loadGroups = () => (dispatch) => {
  const url = 'survey_groups';

  return ApiManager.request('get', dispatch, url);
};

const getSurveyRespondents = () => (dispatch) => ApiManager.request('get', dispatch, 'survey_respondents');

const getSurveyTypes = () => (dispatch) => ApiManager.request('get', dispatch, 'survey_types');

export const onAddClick = () => (dispatch) => {
  dispatch(push('/surveyCreator'));
};

const downloadSPSFile = (id) => (dispatch) => {
  ApiManager.request('get', dispatch, `survey/${id}/schema`, {}, true)
    .then((response) => {
      const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
      saveAs(new Blob([response.data], { type: response.headers['content-type'] }), filename);
    });
};

const downloadCSVFile = (id) => (dispatch) => {
  ApiManager.request('get', dispatch, `survey/${id}/export`, {}, true)
    .then((response) => {
      const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
      saveAs(new Blob([response.data], { type: response.headers['content-type'] }), filename);
    });
};

const downloadResults = (id) => (dispatch) => {
  ApiManager.request('get', dispatch, `survey/${id}/report`, {}, true)
    .then((response) => {
      const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
      saveAs(new Blob([response.data], { type: response.headers['content-type'] }), filename);
    });
};

const getSurveys = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadSurveys(data)).then((response) => {
    dispatch(setFilteredSurveys(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getSurveys());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getSurveys());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getSurveys());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getSurveys());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `surveys/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadSurveys(data)).then((response) => {
      dispatch(setFilteredSurveys(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.surveyDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteSurvey,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/surveyCreator', { id }));
};

export const onPreview = (id) => (dispatch) => {
  dispatch(push('/previewSurvey', { id }));
};

export const onCopy = (id) => (dispatch) => {
  dispatch(push('/surveyCreator', { id, forCopy: true }));
};

export const onDownloadSchema = (id) => (dispatch) => {
  dispatch(downloadSPSFile(id));
};

export const onDownloadCsv = (id) => (dispatch) => {
  dispatch(downloadCSVFile(id));
};

export const onDownloadResults = (id) => (dispatch) => {
  dispatch(downloadResults(id));
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    surveys: dispatch(loadSurveys(data)),
    groups: dispatch(loadGroups()),
    surveyTypes: dispatch(getSurveyTypes()),
    surveyRespondents: dispatch(getSurveyRespondents()),
    meta: dispatch(getMeta()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
