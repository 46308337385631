import _forEach from 'lodash/forEach';

export default (errors, setFieldError, fieldsList = {}) => {
  _forEach(errors, (value, key) => {
    const fieldName = fieldsList[key] || key;

    if (fieldName) {
      setFieldError(fieldName, value[0]);
    }
  });
};
