import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import RootRef from '@material-ui/core/RootRef';
import _isEqual from 'lodash/isEqual';
import TableHead from 'components/TableHead';
import { classesShape } from 'utils/shapes/classesShape';

import draggableRowsStyles from './DraggableRows.styles';

class DraggableTabs extends React.Component {
  static propTypes = {
    head: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
    rows: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      draggableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      component: PropTypes.node,
    })).isRequired,
    onDrop: PropTypes.func.isRequired,
    classes: classesShape,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    classes: {},
    disabled: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!_isEqual(prevState.items, nextProps.rows)) {
      return {
        items: nextProps.rows,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      items: props.rows,
      dragging: false,
    };

    this.domRef = React.createRef();
  }

  onDragEnd = (result) => {
    if (!result.destination || this.props.disabled) {
      this.setState({
        dragging: false,
      });

      return;
    }

    const items = this.reorder(
      this.state.items, // eslint-disable-line react/no-access-state-in-setstate
      result.source.index,
      result.destination.index,
    );

    this.props.onDrop(items);

    this.setState({
      items,
      dragging: false,
    });
  }

  onDragStart = () => {
    this.setState({
      dragging: true,
    });
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getRowStyles = (styles) => ({
    left: styles.left,
    top: styles.top,
    position: styles.position,
    transform: styles.transform,
    zIndex: styles.zIndex,
    width: styles.width,
    cursor: 'default',
  })

  render() {
    return (
      <Table
        stickyHeader
        className={classNames(
          this.props.classes.root,
          { [this.props.classes.draggingWrapper]: this.state.dragging },
        )}
      >
        <TableHead
          rows={this.props.head}
        />
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <Droppable
            droppableId="droppable"
            direction="vertical"
          >
            {
              (providedDroppable, snapDroppable) => (
                <RootRef
                  rootRef={providedDroppable.innerRef}
                >
                  <TableBody
                    {...providedDroppable.droppableProps}
                  >
                    <React.Fragment>
                      {
                        this.state.items.map((item, index) => (
                          <Draggable
                            key={item.draggableId}
                            draggableId={item.draggableId}
                            index={index}
                            isDragDisabled={this.props.disabled}
                          >
                            {
                              (providedDraggable) => (
                                <RootRef
                                  rootRef={providedDraggable.innerRef}
                                >
                                  <TableRow
                                    {...providedDraggable.draggableProps}
                                    {...{
                                      'data-react-beautiful-dnd-drag-handle': '0',
                                      ...providedDraggable.dragHandleProps,
                                    }}

                                    style={
                                      this.getRowStyles(providedDraggable.draggableProps.style)
                                    }
                                  >
                                    {item.component}
                                  </TableRow>
                                  {
                                    item.additionalContent && !snapDroppable.draggingFromThisWith
                                      ? item.additionalContent : null
                                  }
                                </RootRef>
                              )
                            }
                          </Draggable>
                        ))
                      }
                    </React.Fragment>
                  </TableBody>
                </RootRef>
              )
            }
          </Droppable>
        </DragDropContext>
      </Table>
    );
  }
}

export default withStyles(draggableRowsStyles)(DraggableTabs);
