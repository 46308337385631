import { defineMessages } from 'react-intl';

export default defineMessages({
  addQuestion: 'Dodaj pytanie',
  content: 'Treść pytania',
  contentFemale: 'Treść pytania (kobiety)',
  answerSet: 'Zestaw odpowiedzi',
  questionType: 'Typ pytania',
  group: 'Grupa',
  newSet: 'Nowy zestaw',
  save: 'Zapisz pytanie',
  cancel: 'Anuluj',
  warning: 'Uwaga! Modyfikacja pytania zmieni jego treść również w ankietach, które je zawierają.',
});
