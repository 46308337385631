import React from 'react';
import PropTypes from 'prop-types';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormField from 'components/FormField';

import RichTextInput from './components/RichTextInput';

const RitchInput = (props) => (
  <RichTextInput
    name={props.name}
    value={props.value}
    placeholder={props.placeholder}
    onBlur={props.onBlur}
    disabled={props.disabled}
    onChange={props.onChange}
    idSuffix={props.idSuffix}
  />
);

RitchInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  idSuffix: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const OutlinedTextarea = ({
  disabled,
  errors,
  touched,
  name,
  label,
  labelWidth,
  value,
  placeholder,
  isRTE,
  onBlur,
  onChange,
  idSuffix,
}) => {
  const inputComponent = isRTE ? RitchInput : 'input';

  return (
    <FormField
      variant="outlined"
      name={name}
      label={label}
      errors={errors}
      touched={touched}
      isRTE={isRTE}
      fullWidth
      onBlur={isRTE ? () => {} : onBlur}
      onChange={isRTE ? () => {} : onChange}
    >
      <OutlinedInput
        variant="outlined"
        margin="none"
        labelWidth={labelWidth}
        multiline={!isRTE}
        rows={isRTE ? null : 5}
        notched={isRTE || undefined}
        placeholder={placeholder}
        inputComponent={inputComponent}
        inputProps={{
          name,
          value,
          placeholder,
          onBlur,
          onChange,
          idSuffix,
        }}
        value={value}
        disabled={disabled}
      />
    </FormField>
  );
};

OutlinedTextarea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  labelWidth: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  idSuffix: PropTypes.string,
  isRTE: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  touched: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.string,
};

OutlinedTextarea.defaultProps = {
  disabled: false,
  errors: {},
  idSuffix: '',
  isRTE: false,
  placeholder: '',
  touched: {},
  value: '',
};

export default OutlinedTextarea;
