import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import QuestionsPage from 'components/pages/Questions';

import pageTitles from 'utils/pageTitles';
import { setTitle, setActiveMenuItem } from 'containers/store';

import {
  onAddClick, onDelete, onEdit, onSearchSubmit, loadPageData,
  onChangeSort, onChangePage, onChangeRowsPerPage,
} from './store';

class Questions extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.questions));
    this.props.setActiveMenuItem('questions');
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <QuestionsPage
        {...this.props}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  setTitle,
  setActiveMenuItem,
  onAddClick,
  onSearchSubmit,
  onDelete,
  onEdit,
  loadPageData,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
};

const mapStateToProps = (state) => ({
  ...state.Questions,
  ...state.Global.pagingData,
  totalItemsCount: state.Questions.totalItemsCount,
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Questions));
