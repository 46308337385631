import grey from '@material-ui/core/colors/grey';

const drawerWidth = 240;
const closedDrawerWidth = 58;

export default (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    width: `calc(100% - ${closedDrawerWidth}px)`,
    marginLeft: closedDrawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    border: 0,
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: closedDrawerWidth,
    border: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    position: 'relative',
    minHeight: '100vh',
    width: `calc(100% - ${closedDrawerWidth}px)`,
    minWidth: `calc(1600px - ${closedDrawerWidth}px)`,
  },
  menuOpened: {
    width: `calc(100% - ${drawerWidth}px)`,
    minWidth: `calc(1600px - ${drawerWidth}px)`,
  },
  loadingPageContent: {
    padding: 0,
  },
  grow: {
    flexGrow: 1,
  },
  userDataWrapper: {
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
  },
  disablePadding: {
    paddingRight: 0,
  },
  listRoot: {
    backgroundColor: theme.palette.drawerBg,
  },
  listItemIconRoot: {
    color: grey[600],
  },
  listItemTextRoot: {
    color: grey[600],
  },
  listItemTextRootSelected: {
    color: theme.palette.cardBackground,
  },
  userMenuWrapper: {
    marginLeft: 12,
    marginRight: 12,
  },
  selectedListItem: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  activeIcon: {
    color: theme.palette.cardBackground,
  },
  loaderWrapper: {
    height: 'calc(100% - 48px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transparentLoaderWrapper: {
    position: 'absolute',
    marginTop: 48,
    top: 0,
    left: 0,
    zIndex: 100,
  },
  transparentLoaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.cardBackground,
    opacity: 0.6,
    zIndex: 100,
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 101,
  },
  avatarWrapper: {
    cursor: 'pointer',
  },
  dialogRoot: {
    minWidth: 400,
  },
  card: {
    overflow: 'visible',
    marginBottom: 60,
  },
  version: {
    fontSize: 12,
    lineHeight: 1.4,
    position: 'absolute',
    bottom: 15,
    left: 15,
  },
});
