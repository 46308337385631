import { replace } from 'connected-react-router';
import {
  showTransparentLoader, hideLoader, showSnackbar, setUserData,
} from 'containers/store';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

export const initialState = {
  initialValues: {
    login: '',
    password: '',
  },
};

export const actionTypes = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export const login = (values) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      email: values.login,
      password: values.password,
    },
  };

  ApiManager.request('post', dispatch, 'login', requestBody).then((response) => {
    dispatch(setUserData(response.data));
    dispatch(hideLoader());
    dispatch(replace('/users'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
