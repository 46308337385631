import { goBack } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';

import _get from 'lodash/get';

import {
  showLoader, hideLoader, showSnackbar, showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    content: '',
    contentFemale: '',
    set: '',
    type: '',
    group: '',
    questionPicture: null,
    questionImageObject: {},
  },
  questionId: null,
  types: [],
  groups: [],
  sets: [],
};

const mapDataToValues = (data) => {
  const questionPicture = data.image || null;

  return {
    content: data.label_male,
    contentFemale: data.label_female,
    type: data.question_type_id,
    group: data.question_group_id,
    set: data.answer_set_id,
    questionPicture,
    questionImageObject: {},
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'QUESTION_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'QUESTION_ADD_EDIT/CLEAR_STORE',
  TOGGLE_ALERT_DIALOG: 'QUESTION_ADD_EDIT/TOGGLE_ALERT_DIALOG',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        questionId: action.questionId,
        initialValues: action.responses.questionData
          ? mapDataToValues(action.responses.questionData.data) : initialState.initialValues,
        types: action.responses.questionsTypes.data.items,
        groups: action.responses.questionsGroups.data.items,
        sets: action.responses.answerSets.data.items,
      };
    }

    case actionTypes.TOGGLE_ALERT_DIALOG: {
      return {
        ...state,
        openedAlertDialog: action.status,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (questionId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  questionId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const toggleAlertDialog = (status) => ({
  type: actionTypes.TOGGLE_ALERT_DIALOG,
  status,
});

export const closeAlertDialog = () => (dispatch) => {
  dispatch(toggleAlertDialog(false));
  dispatch(hideLoader());
};

const loadQuestionsTypes = () => (dispatch) => {
  const url = 'question_types';

  return ApiManager.request('get', dispatch, url);
};

const loadQuestionsGroups = () => (dispatch) => {
  const url = 'question_groups';

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerSets = () => (dispatch) => {
  const url = 'answer_sets';

  return ApiManager.request('get', dispatch, url);
};

const loadQuestionData = (id) => (dispatch) => ApiManager.request('get', dispatch, `questions/${id}`);

const questionRequest = (method, url, data, message, setFieldError) => (dispatch) => (
  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      setFormErrors(error.error.errors, setFieldError, apiFieldMappings.questionAddEdit);
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  })
);

export const onSubmit = (
  values,
  { setFieldError },
  forceSubmit = false,
) => (dispatch, getStore) => {
  const store = getStore().QuestionAddEdit;
  const questionId = store.questionId;
  let url = 'questions';
  let method = 'post';
  let message = snackbarMessages.questionAddedSuccessfully;

  const formData = new FormData();

  if (questionId) {
    url = `questions/${questionId}`;
    method = 'post';
    message = snackbarMessages.questionEditedSuccessfully;

    // Laravel workaround for put requests when formData contains file
    // https://stackoverflow.com/questions/50691938/patch-and-put-request-does-not-working-with-form-data
    formData.append('_method', 'PUT');
  }

  formData.append('name', values.content);
  formData.append('label_male', values.content);
  formData.append('label_female', values.contentFemale);
  formData.append('answer_set_id', values.set);
  formData.append('answer_set_id', values.set);
  formData.append('question_type_id', values.type);
  formData.append('question_group_id', values.group);

  const isQuestionImageObject = _get(values.questionImageObject, 'files', false);

  if (isQuestionImageObject && !questionId) {
    formData.append('image', values.questionImageObject.files[0]);
  } else if (isQuestionImageObject && questionId) {
    formData.append('image', values.questionImageObject.files[0]);
  } else if (!isQuestionImageObject && !values.questionPicture && questionId) {
    formData.append('remove_image', true);
  }

  dispatch(showTransparentLoader());

  if (!forceSubmit) {
    return ApiManager.request('post', dispatch, 'questions/name', { name: values.content }).then((response) => {
      if (!response.data) {
        dispatch(questionRequest(method, url, formData, message, setFieldError));
      } else if (response.data) {
        dispatch(toggleAlertDialog(true));
      }
    });
  }

  dispatch(closeAlertDialog());
  return dispatch(questionRequest(method, url, formData, message, setFieldError));
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

export const loadPageData = (routeState) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  const promises = {};
  promises.questionsTypes = dispatch(loadQuestionsTypes());
  promises.questionsGroups = dispatch(loadQuestionsGroups());
  promises.answerSets = dispatch(loadAnswerSets());

  if (routeState.id) {
    promises.questionData = dispatch(loadQuestionData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
