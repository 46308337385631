import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import NewSet from 'components/pages/SurveyCreator/components/NewSet';
import { itemShape } from 'utils/shapes/select.shapes';

const AnswersAddEdit = ({
  onSubmit,
  onCancel,
  answersSets,
  initialValues,
}) => (
  <NewSet
    onCancel={onCancel}
    onSubmit={onSubmit}
    initialValues={initialValues}
    types={answersSets}
  />
);

AnswersAddEdit.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  answersSets: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    range: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    answers: PropTypes.array,
  }),
};

AnswersAddEdit.defaultProps = {
  initialValues: {},
  answersSets: [],
};

export default (injectIntl(AnswersAddEdit));
