import React from 'react';
import { Redirect } from 'react-router-dom';
import { Switch } from 'react-router';

// pages
import LoginPage from 'containers/Login';
import ForgotPasswordPage from 'containers/ForgotPassword';
import ResetPassword from 'containers/ResetPassword';
import UsersPage from 'containers/Users';
import OrganizationsPage from 'containers/Organizations';
import QuestionsPage from 'containers/Questions';
import UserAddEditPage from 'containers/UserAddEdit';
import ParticipantEditPage from 'containers/ParticipantEdit';
import QuestionAddEditPage from 'containers/QuestionAddEdit';
import OrganizationAddEditPage from 'containers/OrganizationAddEdit';
import SurveyCreatorPage from 'containers/SurveyCreator';
import SurveysPage from 'containers/Surveys';
import AnswersPage from 'containers/Answers';
import AnswersAddEditPage from 'containers/AnswersAddEdit';
import SurveysResultsPage from 'containers/SurveysResults';
import SurveyResultsPage from 'containers/SurveyResults';
import PreviewSurveyPage from 'containers/PreviewSurvey';

import { historyShape } from 'utils/shapes/router.shapes';

import UnauthorizedRoute from './UnauthorizedRoute';
import AuthorizedRoute from './AuthorizedRoute';
import Global from '../global';

const Routes = ({ history }) => (
  <React.Fragment>
    <Global>
      <Switch>
        <UnauthorizedRoute component={LoginPage} path="/login" />
        <UnauthorizedRoute component={ForgotPasswordPage} path="/forgotPassword" />
        <UnauthorizedRoute component={ResetPassword} path="/resetPassword/:token" />
        <AuthorizedRoute component={UsersPage} path="/users" />
        <AuthorizedRoute component={OrganizationsPage} path="/organizations" />
        <AuthorizedRoute component={QuestionsPage} path="/questions" />
        <AuthorizedRoute component={UserAddEditPage} path="/userAddEdit" />
        <AuthorizedRoute component={ParticipantEditPage} path="/participantEdit" />
        <AuthorizedRoute component={QuestionAddEditPage} path="/questionAddEdit" />
        <AuthorizedRoute component={OrganizationAddEditPage} path="/organizationAddEdit" />
        <AuthorizedRoute component={SurveyCreatorPage} path="/surveyCreator" />
        <AuthorizedRoute component={SurveysPage} path="/surveys" />
        <AuthorizedRoute component={AnswersPage} path="/answers" />
        <AuthorizedRoute component={AnswersAddEditPage} path="/answersAddEdit" />
        <AuthorizedRoute component={SurveysResultsPage} path="/surveysResults" />
        <AuthorizedRoute component={SurveyResultsPage} path="/surveyResults" />
        <AuthorizedRoute component={PreviewSurveyPage} path="/previewSurvey" />
        <Redirect
          to={{
            pathname: '/login',
            search: history.location.search,
          }}
        />
      </Switch>
    </Global>
  </React.Fragment>
);

Routes.propTypes = {
  history: historyShape.isRequired,
};

export default Routes;
