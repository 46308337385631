import { defineMessages } from 'react-intl';

export default defineMessages({
  logIn: 'Zaloguj się',
  login: 'Login',
  password: 'Hasło',
  forgetPassword: 'Nie pamiętasz hasła?',
  log: 'Zaloguj',
  appVersion: 'Wersja aplikacji:',
  apiVersion: 'Wersja API:',
});
