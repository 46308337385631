import isString from 'lodash/isString';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldMatchRegex: 'To pole zawiera niedozwolone znaki',
});

export default (value, regex) => {
  const result = {};
  const finalValue = isString(value) ? value : String(value);

  result.isValid = regex.test(finalValue);
  result.message = messages.fieldMatchRegex;

  return result;
};
