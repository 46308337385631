import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import { classesShape } from 'utils/shapes/classesShape';

import answersPopupStyles from './AnswersPopup.styles';
import messages from './AnswersPopup.messages';

class AnswersPopup extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      intl,
      classes,
      answers,
      noPopupMessage,
    } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Tooltip title={intl.formatMessage(messages.see)} placement="top">
          <IconButton
            onClick={this.handleClick}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.wrapper}>
            {
              noPopupMessage ? null : (
                <Typography>
                  {intl.formatMessage(messages.answers)}
                  :
                </Typography>
              )
            }
            <div className={classes.answersWrapper}>
              {
                answers.map((el) => (
                  <Typography key={`${el.answer_set_id}-${el.id}`}>
                    <div className={classes.answerWrapper}>{el.label}</div>
                  </Typography>
                ))
              }
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

AnswersPopup.propTypes = {
  intl: intlShape.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
  })),
  classes: classesShape,
  noPopupMessage: PropTypes.bool,
};

AnswersPopup.defaultProps = {
  answers: [],
  classes: {},
  noPopupMessage: false,
};

export default withStyles(answersPopupStyles)(injectIntl(AnswersPopup));
