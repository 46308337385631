import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import FormField from 'components/FormField';
import logo from 'assets/img/logo.png';
import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import config from 'config';
import loginStyles from './Login.styles';

import messages from './Login.messages';

const validators = [
  {
    fieldName: 'login',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'password',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
];

const Login = ({
  onLoginClick, classes, intl, initialValues, apiVersion,
}) => (
  <React.Fragment>
    <div className={classes.logoWrapper}>
      <img src={logo} alt="diagmatic" />
    </div>
    <div className={classes.separator} />
    <div className={classes.login}>
      <Typography variant="h5">
        {intl.formatMessage(messages.logIn)}
      </Typography>
    </div>
    <Formik
      initialValues={
        { ...initialValues }
      }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onLoginClick}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            errors={errors}
            touched={touched}
            name="login"
            label={intl.formatMessage(messages.login)}
            variant="outlined"
            fullWidth
          >
            <OutlinedInput
              value={values.login}
              labelWidth={45}
              margin="none"
              variant="outlined"
            />
          </FormField>
          <div className={classes.passwordWrapper}>
            <FormField
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              errors={errors}
              touched={touched}
              name="password"
              label={intl.formatMessage(messages.password)}
              variant="outlined"
              fullWidth
            >
              <OutlinedInput
                type="password"
                value={values.password}
                labelWidth={45}
                margin="none"
                variant="outlined"
              />
            </FormField>
          </div>
          <div className={classes.forgottenPasswordWrapper}>
            <Link
              component="button"
              variant="body2"
            >
              <RouterLink to="/forgotPassword">
                {intl.formatMessage(messages.forgetPassword)}
              </RouterLink>
            </Link>
          </div>
          <div className={classes.loginButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {intl.formatMessage(messages.log)}
            </Button>
          </div>
        </form>
      )}
    />
    <Typography className={classes.version}>
      {`${intl.formatMessage(messages.appVersion)} ${config.appVersion}`}
      <br />
      {`${intl.formatMessage(messages.apiVersion)} ${apiVersion}`}
    </Typography>
  </React.Fragment>
);

Login.propTypes = {
  intl: intlShape.isRequired,
  onLoginClick: PropTypes.func.isRequired,
  apiVersion: PropTypes.string,
  classes: classesShape,
  initialValues: PropTypes.shape({
    login: PropTypes.string,
    password: PropTypes.string,
  }),
};

Login.defaultProps = {
  classes: {},
  initialValues: {},
  apiVersion: '',
};

export default withStyles(loginStyles)(injectIntl(Login));
