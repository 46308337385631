import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SurveysResultsPage from 'components/pages/SurveysResults';

import pageTitles from 'utils/pageTitles';
import { setTitle, setActiveMenuItem } from 'containers/store';

import {
  onSearchSubmit, loadPageData, onChangeSort, onChangePage, onChangeRowsPerPage,
  onFetch, onClear, onView, onDownload,
} from './store';

class SurveysResults extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    onSearchSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.surveysResults));
    this.props.setActiveMenuItem('surveysResults');
    this.props.loadPageData();
  }

  render() {
    return this.props.isLoadedPage ? (
      <SurveysResultsPage
        {...this.props}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  setTitle,
  setActiveMenuItem,
  onSearchSubmit,
  loadPageData,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onFetch,
  onClear,
  onView,
  onDownload,
};

const mapStateToProps = (state) => ({
  ...state.SurveysResults,
  ...state.Global.pagingData,
  totalItemsCount: state.SurveysResults.totalItemsCount,
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SurveysResults));
