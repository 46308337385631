import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateIcon from '@material-ui/icons/Create';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';

import FormField from 'components/FormField';
import PreviewPopup from 'components/PreviewPopup';
import TablePaginationActions from 'components/TablePaginationActions';
import AddQuestion from 'components/pages/SurveyCreator/components/AddQuestion';
import TableHead from 'components/TableHead';

import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import AnswersPopup from 'components/AnswersPopup';
import questionRepositoryStyles from './QuestionRepository.styles';

import messages from './QuestionRepository.messages';

const validators = [{
  fieldName: 'repoFind',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

class QuestionRepository extends React.Component {
  static propTypes = {
    intl: intlShape.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
    totalItemsCount: PropTypes.number.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    classes: classesShape,
    disabled: PropTypes.bool,
    editInitialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    groups: PropTypes.arrayOf(itemShape),
    initialValues: PropTypes.shape({
      find: PropTypes.string,
      type: PropTypes.string,
      group: PropTypes.string,
    }),
    isEdit: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      content: PropTypes.string,
      answerSet: PropTypes.object,
      type: PropTypes.string,
      group: PropTypes.string,
    })),
    sortingDirection: PropTypes.oneOf(['asc', 'desc']),
    sortingField: PropTypes.string,
    types: PropTypes.arrayOf(itemShape),
    onCancel: PropTypes.func,
    onChangeSort: PropTypes.func,
    onEditQuestionSubmit: PropTypes.func,
  };

  static defaultProps = {
    classes: {},
    disabled: false,
    editInitialValues: {},
    initialValues: {},
    groups: [],
    types: [],
    isEdit: false,
    items: [],
    onCancel: () => {},
    onEditQuestionSubmit: () => {},
    sortingField: null,
    sortingDirection: 'asc',
    onChangeSort: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: true,
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  }

  render() {
    const {
      classes, intl, initialValues, onSubmit, groups, types,
      items, rowsPerPage, rowsPerPageOptions, totalItemsCount,
      page, onChangePage, onChangeRowsPerPage, onAddClick, onEditClick,
      isEdit, editInitialValues, onCancel, onEditQuestionSubmit,
      sortingDirection, sortingField, onChangeSort, disabled,
    } = this.props;

    const head = [{
      id: 'number',
      label: '#',
      class: classes.numberCell,
      sortable: false,
    }, {
      id: 'picture',
      label: intl.formatMessage(messages.picture),
      sortable: false,
    }, {
      id: 'questionContent',
      label: intl.formatMessage(messages.questionContent),
      class: classes.questionContentCell,
      sortable: true,
    }, {
      id: 'answerSet',
      label: intl.formatMessage(messages.answerSet),
      class: classes.answerSetCell,
      sortable: false,
    }, {
      id: 'questionType',
      label: intl.formatMessage(messages.questionType),
      class: classes.questionTypeCell,
      sortable: false,
    }, {
      id: 'group',
      label: intl.formatMessage(messages.group),
      class: classes.groupCell,
      sortable: false,
    }, {
      id: 'actions',
      label: intl.formatMessage(messages.actions),
      class: classes.actionsCell,
      sortable: false,
    }];

    return (
      <div>
        <div className={classNames(
          classes.title,
          classes.topSpacer,
          classes.titleWithExpand,
        )}
        >
          <Typography variant="h6">
            {intl.formatMessage(messages.questionsRepository)}
          </Typography>
          <IconButton
            className={classNames(classes.expand, {
              [classes.expandOpen]: this.state.expanded,
            })}
            onClick={this.handleExpandClick}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <Formik
            initialValues={
              { ...initialValues }
            }
            validate={(values) => validator(values, validators, intl.formatMessage)}
            onSubmit={(values) => { onSubmit(values, false); }}
            render={({
              values,
              errors,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              dirty,
              isSubmitting,
              handleReset,
              submitCount,
              setSubmitting,
            }) => (
              <form
                onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}
              >
                <div className={classes.row}>
                  <div className={classes.bigFieldWrapper}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      setSubmitting={setSubmitting}
                      name="repoFind"
                      label={intl.formatMessage(messages.repoFind)}
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        value={values.repoFind}
                        startAdornment={<SearchIcon />}
                        labelWidth={85}
                        margin="none"
                        variant="outlined"
                      />
                    </FormField>
                  </div>
                  <div className={classNames(classes.smallFieldWrapper, classes.fieldSpacer)}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      setSubmitting={setSubmitting}
                      name="type"
                      label={intl.formatMessage(messages.questionType)}
                      variant="outlined"
                      fullWidth
                    >
                      <Select
                        value={values.type}
                        input={(
                          <OutlinedInput
                            labelWidth={80}
                            name="type"
                            id="type-picker"
                          />
                          )}
                      >
                        {
                            types.map((el) => (
                              <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                            ))
                          }
                      </Select>
                    </FormField>
                  </div>
                  <div className={classes.smallFieldWrapper}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      setSubmitting={setSubmitting}
                      name="group"
                      label={intl.formatMessage(messages.group)}
                      variant="outlined"
                      fullWidth
                    >
                      <Select
                        value={values.group}
                        input={(
                          <OutlinedInput
                            labelWidth={40}
                            name="group"
                            id="group-picker"
                          />
                          )}
                      >
                        {
                            groups.map((el) => (
                              <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                            ))
                          }
                      </Select>
                    </FormField>
                  </div>
                  <div className={classes.buttonsWrapper}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                    >
                      {intl.formatMessage(messages.filter)}
                    </Button>
                    <div className={classes.resetWrapper}>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={(event) => {
                          handleReset(event);
                          onSubmit({}, true);
                        }}
                        disabled={!(submitCount || dirty)}
                      >
                        {intl.formatMessage(messages.reset)}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          />
          <div>
            {
              items.length > 0 ? (
                <React.Fragment>
                  <Table
                    stickyHeader
                    className={classes.root}
                  >
                    <TableHead
                      rows={head}
                      sortingDirection={sortingDirection}
                      sortingField={sortingField}
                      onChangeSort={onChangeSort}
                    />
                    <TableBody>
                      {items.map((row) => (
                        <React.Fragment key={row.id}>
                          <TableRow>
                            <TableCell component="th" scope="row" className={classes.numberCell}>
                              {row.id}
                            </TableCell>
                            <TableCell>
                              { row.imageUrl ? (
                                <PreviewPopup imageUrl={row.imageUrl} />
                              ) : null }
                            </TableCell>
                            <TableCell className={classes.questionContentCell}>
                              { /* eslint-disable-next-line react/no-danger */ }
                              <div dangerouslySetInnerHTML={{ __html: row.content }} />
                            </TableCell>
                            <TableCell className={classes.answerSetCell}>
                              <div className={classes.answerSetWrapper}>
                                {row.answerSet.name}
                                {
                                  row.answerSet.answers.length > 0 ? (
                                    <AnswersPopup
                                      answers={row.answerSet.answers}
                                    />
                                  ) : null
                                }
                              </div>
                            </TableCell>
                            <TableCell className={classNames(
                              classes.questionTypeCell,
                              classes.questionTypeContent,
                            )}
                            >
                              {row.type}
                            </TableCell>
                            <TableCell className={classes.groupCell}>
                              {row.group}
                            </TableCell>
                            <TableCell className={classes.actionsCell}>
                              <Tooltip title={intl.formatMessage(messages.add)} placement="top">
                                <IconButton
                                  onClick={() => { onAddClick(row); }}
                                  disabled={disabled}
                                >
                                  <AddBoxIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                                <IconButton
                                  onClick={() => { onEditClick(row); }}
                                >
                                  <CreateIcon />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          {
                            isEdit && row.apiId === editInitialValues.apiId ? (
                              <TableRow>
                                <TableCell colSpan={6} className={classes.editTableCell}>
                                  <div>
                                    <AddQuestion
                                      uploadButtonId="questionRepo"
                                      onSubmit={onEditQuestionSubmit}
                                      onCancel={onCancel}
                                      initialValues={editInitialValues}
                                      groups={groups}
                                      types={types}
                                      isEdit
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ) : null
                          }
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    ActionsComponent={TablePaginationActions}
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    count={totalItemsCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    className={classNames({
                      [classes.caption]: true,
                      [classes.paginationRoot]: true,
                    })}
                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                    labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
                    classes={{
                      select: classes.selectWrapper,
                    }}
                  />
                </React.Fragment>
              ) : null
            }
          </div>
        </Collapse>
      </div>
    );
  }
}

export default withStyles(questionRepositoryStyles)(injectIntl(QuestionRepository));
