import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { matchShape } from 'utils/shapes/router.shapes';
import ResetPasswordPage from 'components/pages/ResetPassword';

import { onSubmit } from './store';

const ResetPassword = (props) => (
  <ResetPasswordPage
    {...props}
    onSubmit={(values) => props.onSubmit(values, props.match.params.token)}
  />
);

ResetPassword.propTypes = {
  match: matchShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSubmit,
};

const mapStateToProps = (state) => ({
  ...state.ResetPassword,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResetPassword));
