import _has from 'lodash/has';
import _keys from 'lodash/keys';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';

export default (errors = {}, touched = {}) => {
  const touchedKeys = _keys(touched);
  let isDisabled = false;

  if (!_isEmpty(touchedKeys)) {
    isDisabled = _some(touchedKeys, (item) => _has(errors, item));
  }

  return isDisabled;
};
