import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import _isEmpty from 'lodash/isEmpty';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { withStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import config from 'config';
import createDebouncer from 'utils/createDebouncer';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import Suggestions from './components/Suggestions';

import autocompleteStyles from './Autocomplete.styles';

function renderInputComponent(inputProps) {
  const {
    inputRef = () => {},
    ref,
    labelWidth,
    onBlur,
    disabled,
    fullWidth,
    classes,
    ...other
  } = inputProps;

  return (
    <OutlinedInput
      inputProps={{
        ref: (node) => {
          ref(node);
          inputRef(node);
        },
        maxLength: 255,
      }}
      classes={{ input: classes.elipsis }}
      labelWidth={labelWidth}
      onBlur={onBlur}
      disabled={disabled}
      margin="none"
      variant="outlined"
      fullWidth={fullWidth}
      {...other}
    />
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.name;
}

function renderSuggestions(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.name, query);
  const parts = parse(suggestion.name, matches);

  return (
    <Suggestions
      isHighlighted={isHighlighted}
      parts={parts}
    />
  );
}

class Autocomplete extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value !== prevState.prevValue) {
      return {
        prevValue: nextProps.value,
        value: !_isEmpty(nextProps.value) ? nextProps.value.name : '',
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      value: !_isEmpty(props.value) ? props.value.name : '',
      prevValue: props.value,
    };

    this.debouncer = createDebouncer(props.onFetch, config.debounceTime);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  onBlur = () => {}

  onSuggestionSelected = (event, selection) => {
    const eventPrepared = {
      target: {
        value: selection.suggestion,
      },
    };

    this.props.onChange(eventPrepared);
  }

  onSuggestionsFetchRequested = (event) => {
    if (event.reason === 'input-changed') {
      this.debouncer(event.value);
    }
  }

  render() {
    const {
      value,
    } = this.state;

    const {
      labelWidth,
      onClear,
      suggestions,
      disabled,
      classes,
      fullWidth,
    } = this.props;

    const autosuggestProps = {
      renderInputComponent,
    };

    const inputProps = {
      value,
      labelWidth,
      onChange: this.onChange,
      onBlur: this.onBlur,
      disabled,
      fullWidth,
      classes,
    };

    return (
      <Autosuggest
        {...autosuggestProps}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onClear}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestions}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square classes={{ root: classes.wrapper }}>
            {options.children}
          </Paper>
        )}
      />
    );
  }
}

Autocomplete.propTypes = {
  suggestions: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  onFetch: PropTypes.func.isRequired,
  classes: classesShape,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  labelWidth: PropTypes.number,
  value: itemShape,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

Autocomplete.defaultProps = {
  classes: {},
  labelWidth: 0,
  onChange: () => {},
  onClear: () => {},
  disabled: false,
  value: {},
  fullWidth: false,
};

export default withStyles(autocompleteStyles)(Autocomplete);
