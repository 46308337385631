import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserAddEditPage from 'components/pages/UserAddEdit';
import pageTitles from 'utils/pageTitles';
import getRouteState from 'utils/getRouteState';
import { historyShape } from 'utils/shapes/router.shapes';

import { setTitle, setActiveMenuItem } from 'containers/store';

import {
  onSubmit,
  onCancel,
  loadPageData,
  onFetch,
  onClear,
} from './store';

class UserAddEdit extends React.Component {
  static propTypes = {
    history: historyShape.isRequired,
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    userId: PropTypes.number,
  };

  static defaultProps = {
    userId: null,
  };

  componentDidMount() {
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.users));
    this.props.setActiveMenuItem('users');
    const routeState = getRouteState(this.props.history);
    this.props.loadPageData(routeState);
  }

  render() {
    return this.props.isLoadedPage ? (
      <UserAddEditPage
        isEdit={!!this.props.userId}
        {...this.props}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  setTitle,
  onSubmit,
  onCancel,
  loadPageData,
  onFetch,
  onClear,
  setActiveMenuItem,
};

const mapStateToProps = (state) => ({
  ...state.UserAddEdit,
});


export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(UserAddEdit)));
