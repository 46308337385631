import { push } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader, showSnackbar, showLoader, getMeta, showTransparentLoader,
  openDialog, mapMeta, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    search: '',
    type: '',
    status: '',
    organization: '',
  },
  items: [],
  types: [],
  statuses: [],
  organizations: [],
};

const sortFieldsMapping = {
  name: 'first_name',
  surname: 'last_name',
  email: 'email',
  role: 'role_id',
  status: 'status',
};

const mapUsers = (users, startingPosition) => users.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.first_name,
  surname: el.last_name,
  email: el.email,
  role: el.role_id,
  status: el.status,
}));

const mapOrganizations = (organizations) => organizations.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USERS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_USERS: 'USERS/SET_FILTERED_USERS',
  SET_META: 'USERS/SET_META',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapUsers(action.responses.users.data.items, 0),
        organizations: mapOrganizations(action.responses.organizations.data.items),
        totalItemsCount: action.responses.users.data.total,
      };
    }

    case actionTypes.SET_META: {
      return {
        ...state,
        types: mapMeta(action.response.data.userRole),
        statuses: mapMeta(action.response.data.userStatus),
      };
    }

    case actionTypes.SET_FILTERED_USERS: {
      return {
        ...state,
        items: mapUsers(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const setFilteredUsers = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_USERS,
  response,
  startCountFrom,
});

const setMeta = (response) => ({
  type: actionTypes.SET_META,
  response,
});

const loadUsers = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
  };

  if (params.lastFilterValues.search) {
    data.findUser = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.type) {
    data.findUserByRole = params.lastFilterValues.type;
  }

  if (params.lastFilterValues.status) {
    data.findUserByStatus = params.lastFilterValues.status;
  }

  if (params.lastFilterValues.organization) {
    data.findUserByOrganization = params.lastFilterValues.organization;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('users', data);

  return ApiManager.request('get', dispatch, url);
};

const loadOrganizations = () => (dispatch) => {
  const url = 'organizations';

  return ApiManager.request('get', dispatch, url);
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/userAddEdit'));
};

const getUsers = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadUsers(data)).then((response) => {
    dispatch(setFilteredUsers(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getUsers());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getUsers());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getUsers());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getUsers());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `users/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadUsers(data)).then((response) => {
      dispatch(setFilteredUsers(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.userDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteUser,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/userAddEdit', { id }));
};

export const onView = (id) => (dispatch) => {
  dispatch(push('/userAddEdit', { id, isViewProfile: true }));
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  dispatch(getMeta()).then((response) => {
    dispatch(setMeta(response));
    PromiseAll({
      users: dispatch(loadUsers(data)),
      organizations: dispatch(loadOrganizations()),
    }).then((responses) => {
      dispatch(loadPageSuccess(responses));
      dispatch(hideLoader());
    }).catch((error) => {
      if (isBadRequest(error)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
