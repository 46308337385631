import { defineMessages } from 'react-intl';

export default defineMessages({
  logout: 'Wyloguj',
  users: 'Użytkownicy',
  organizations: 'Placówki',
  questions: 'Pytania',
  surveys: 'Ankiety',
  answers: 'Odpowiedzi',
  surveysResults: 'Badania',
  resetPassword: 'Zmień hasło',
  dialogYes: 'Tak',
  dialogNo: 'Nie',
  appVersion: 'Wersja aplikacji:',
  apiVersion: 'Wersja API:',
});
