import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: 'Anuluj',
  save: 'Zatwierdź',
  title: 'Zmień parametry pytania',
  isLast: 'Pytanie kończy ankietę',
  questionsMapping: 'Skok do innego pytania:',
  chooseQuestion: 'Wybierz pytanie',
  none: 'Brak',
});
