import { defineMessages } from 'react-intl';

export default defineMessages({
  actions: 'Akcje',
  city: 'Miasto',
  consent: 'Zgoda',
  type: 'Typ placówki',
  status: 'Status placówki',
  filter: 'Filtruj',
  findOrganization: 'Znajdź placówkę',
  name: 'Nazwa',
  number: 'Numer',
  postalCode: 'Kod pocztowy',
  reset: 'Wyczyść',
  rowsPerPage: 'Wierszy:',
  street: 'Ulica',
  usersList: 'Lista placówek',
  delete: 'Usuń',
  edit: 'Edytuj',
});
