export default (theme) => ({
  dataWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  basicDataWrapper: {
    width: '59%',
  },
  paramsWrapper: {
    width: '39%',
  },
  dataContentWrapper: {
    marginTop: 12,
    display: 'flex',
  },
  rangeWrapper: {
    width: 200,
    marginRight: 24,
  },
  syntaxWrapper: {
    marginTop: 24,
  },
  answerWrapper: {
    marginTop: 24,
    display: 'flex',
  },
  buttonsWrapper: {
    marginTop: 24,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelWrapper: {
    marginRight: 12,
    color: theme.palette.error.main,
  },
  weightWrapper: {
    width: 200,
    marginLeft: 24,
    marginTop: -16,
  },
});
