import { defineMessages } from 'react-intl';

export default defineMessages({
  actions: 'Akcje',
  set: 'Zestaw odpowiedzi',
  contents: 'Treść pytania',
  picture: 'Obrazek',
  filter: 'Filtruj',
  findQuestion: 'Znajdź pytanie',
  questionGroup: 'Grupa',
  questionsList: 'Lista pytań',
  surveys: 'Przypisane ankiety',
  type: 'Typ pytania',
  reset: 'Wyczyść',
  rowsPerPage: 'Wierszy:',
  delete: 'Usuń',
  edit: 'Edytuj',
});
