import { defineMessages } from 'react-intl';

export default defineMessages({
  surveyResults: 'Lista uczestników',
  gender: 'Płeć',
  birthDate: 'Rok urodzenia',
  rowsPerPage: 'Wierszy:',
  actions: 'Akcje',
  female: 'Kobieta',
  male: 'Mężczyzna',
  uniqueId: 'Unikalne ID',
  download: 'Pobierz',
  edit: 'Edytuj',
  add: 'Dodaj',
  delete: 'Usuń',
});
