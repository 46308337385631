import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

import { classesShape } from 'utils/shapes/classesShape';
import PageLayout from './components/PageLayout';
import questionPageStyles from './QuestionPage.styles';

import messages from './QuestionPage.messages';

const QuestionPage = ({
  classes, answers, question, intl, surveyName, image,
}) => (
  <PageLayout title={surveyName}>
    <div className={classes.wrapper}>
      <Typography variant="h6">
        {intl.formatMessage(messages.question)}
      </Typography>
      <div className={classes.questionWrapper}>
        <Typography variant="h6">
          { /* eslint-disable-next-line react/no-danger */ }
          <span dangerouslySetInnerHTML={{ __html: question }} />
        </Typography>
      </div>
      {
        image ? (
          <Card className={classes.cardImage}>
            <CardMedia
              className={classes.media}
              image={image}
            />
          </Card>
        ) : null
      }
      <div className={classes.answersWrapper}>
        <Typography variant="h6">
          {intl.formatMessage(messages.chooseAnswer)}
        </Typography>
        {
          answers.map((el) => (
            <div
              className={classNames({
                [classes.answerWrapper]: true,
              })}
              key={el.id}
            >
              <Typography variant="h6">
                {el.answer}
              </Typography>
            </div>
          ))
        }
      </div>
      <div className={classes.submitWrapper}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {}}
          fullWidth
        >
          {intl.formatMessage(messages.next)}
        </Button>
      </div>
    </div>
  </PageLayout>
);

QuestionPage.propTypes = {
  intl: intlShape.isRequired,
  question: PropTypes.string.isRequired,
  surveyName: PropTypes.string.isRequired,
  answers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    answer: PropTypes.string,
  })),
  classes: classesShape,
  image: PropTypes.string,
};

QuestionPage.defaultProps = {
  answers: [],
  image: '',
  classes: {},
};

export default withStyles(questionPageStyles)(injectIntl(QuestionPage));
