import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { rootReducer } from 'containers/reducers';

export default function initStore(history) {
  const middleware = [
    thunkMiddleware,
    routerMiddleware(history),
  ];

  const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

  const store = createStore(
    rootReducer(history),
    composedEnhancers,
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../containers/reducers', () => {
      const nextReducers = require('../containers/reducers'); // eslint-disable-line global-require
      store.replaceReducer(nextReducers);
    });
  }

  return store;
}
