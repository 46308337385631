import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import OutlinedTextarea from 'components/OutlinedTextarea';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormField from 'components/FormField';
import AlertDialog from 'components/AlertDialog';

import UploadButtonController from 'components/UploadButtonController';

import validator from 'utils/validator/core';
import disabledSaveButton from 'utils/disableSaveButton';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';

import questionsAddEditStyles from './QuestionAddEdit.styles';
import messages from './QuestionAddEdit.messages';

const validators = [{
  fieldName: 'content',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [65535],
    },
  ],
}, {
  fieldName: 'contentFemale',
  validators: [{
    validatorName: 'maxLength',
    parameters: [65535],
  }],
}, {
  fieldName: 'set',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'type',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'group',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}];

const QuestionsAddEdit = ({
  classes,
  intl,
  initialValues,
  onSubmit,
  onCancel,
  groups,
  types,
  sets,
  openedAlertDialog,
  closeAlertDialog,
}) => {
  let forceSubmit = false;

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.questions)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={(values, formikParams) => {
          onSubmit(values, formikParams, forceSubmit);
          forceSubmit = false;
        }}
        render={({
          values,
          errors,
          touched,
          isSubmitting,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
        }) => (
          <React.Fragment>
            <form onSubmit={handleSubmit}>
              <div className={classes.row}>
                <div className={classes.fullFieldWrapper}>
                  <OutlinedTextarea
                    isRTE
                    errors={errors}
                    touched={touched}
                    name="content"
                    label={intl.formatMessage(messages.content)}
                    labelWidth={95}
                    value={values.content}
                    placeholder={intl.formatMessage(messages.content)}
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    idSuffix="male"
                  />
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.fullFieldWrapper}>
                  <OutlinedTextarea
                    isRTE
                    errors={errors}
                    touched={touched}
                    name="contentFemale"
                    label={intl.formatMessage(messages.contentFemale)}
                    labelWidth={165}
                    value={values.contentFemale}
                    placeholder={intl.formatMessage(messages.contentFemale)}
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    idSuffix="female"
                  />
                </div>
              </div>
              <div className={classes.title}>
                <Typography variant="h6">
                  {intl.formatMessage(messages.systemParams)}
                </Typography>
              </div>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="set"
                    label={intl.formatMessage(messages.set)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.set}
                      input={(
                        <OutlinedInput
                          labelWidth={140}
                          name="set"
                          id="set-picker"
                        />
                      )}
                    >
                      {
                        sets.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="type"
                    label={intl.formatMessage(messages.type)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.type}
                      input={(
                        <OutlinedInput
                          labelWidth={80}
                          name="type"
                          id="type-picker"
                        />
                      )}
                    >
                      {
                        types.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.fieldWrapper}>
                  <FormField
                    onBlur={setFieldTouched}
                    onChange={setFieldValue}
                    errors={errors}
                    touched={touched}
                    name="group"
                    label={intl.formatMessage(messages.questionGroup)}
                    variant="outlined"
                    fullWidth
                  >
                    <Select
                      value={values.group}
                      input={(
                        <OutlinedInput
                          labelWidth={45}
                          name="group"
                          id="group-picker"
                        />
                      )}
                    >
                      {
                        groups.map((el) => (
                          <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormField>
                </div>
              </div>
              <div className={classes.title}>
                <Typography variant="h6">
                  {intl.formatMessage(messages.picture)}
                </Typography>
              </div>
              <div className={classes.row}>
                <UploadButtonController
                  uploadButtonId="question-add-edit"
                  imageUrl={values.questionPicture}
                  onChange={(event, imageLocalUrl) => {
                    setFieldValue('questionPicture', imageLocalUrl);
                    setFieldValue('questionImageObject', event);
                  }}
                />
              </div>
              <div className={classes.buttonsWrapper}>
                <div className={classes.cancelWrapper}>
                  <Button
                    color="inherit"
                    onClick={onCancel}
                  >
                    {intl.formatMessage(messages.cancel)}
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disabledSaveButton(errors, touched)}
                  onClick={handleSubmit}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              </div>
            </form>
            <AlertDialog
              open={openedAlertDialog}
              title={intl.formatMessage(messages.title)}
              contentText={intl.formatMessage(messages.contentText)}
              cancelButtonText={intl.formatMessage(messages.cancelButtonText)}
              saveButtonText={intl.formatMessage(messages.saveButtonText)}
              onCancel={closeAlertDialog}
              onSubmit={() => {
                forceSubmit = true;
                handleSubmit();
              }}
              onDialogClose={closeAlertDialog}
            />
            <Prompt
              when={!_isEqual(values, initialValues) && !isSubmitting}
              message={intl.formatMessage(snackbarMessages.unsaveData)}
            />
          </React.Fragment>
        )}
      />
    </div>
  );
};

QuestionsAddEdit.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  closeAlertDialog: PropTypes.func,
  groups: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    content: PropTypes.string,
    contentFemale: PropTypes.string,
    set: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    group: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  }),
  openedAlertDialog: PropTypes.bool,
  sets: PropTypes.arrayOf(itemShape),
  types: PropTypes.arrayOf(itemShape),
};

QuestionsAddEdit.defaultProps = {
  classes: {},
  initialValues: {},
  types: [],
  sets: [],
  groups: [],
  openedAlertDialog: false,
  closeAlertDialog: () => {},
};

export default withStyles(questionsAddEditStyles)(injectIntl(QuestionsAddEdit));
