import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginPage from 'components/pages/Login';

import { login } from './store';

const Login = (props) => (
  <LoginPage
    {...props}
    onLoginClick={props.login}
  />
);

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  login,
};

const mapStateToProps = (state) => ({
  ...state.Login,
  apiVersion: state.Global.apiVersion,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
