import { defineMessages } from 'react-intl';

export default defineMessages({
  usersList: 'Lista użytkowników',
  findUser: 'Znajdź użytkownika',
  userType: 'Typ użytkownika',
  userStatus: 'Status użytkownika',
  name: 'Imię',
  surname: 'Nazwisko',
  email: 'Adres e-mail',
  role: 'Rola',
  status: 'Status',
  actions: 'Akcje',
  rowsPerPage: 'Wierszy:',
  filter: 'Filtruj',
  reset: 'Wyczyść',
  organization: 'Placówka',
  delete: 'Usuń',
  edit: 'Edytuj',
  see: 'Zobacz',
});
