import { defineMessages } from 'react-intl';

export default defineMessages({
  currentSet: 'Aktualny zestaw pytań',
  content: 'Treść pytania',
  set: 'Zestaw odpowiedzi',
  picture: 'Obrazek',
  type: 'Typ pytania',
  group: 'Grupa',
  actions: 'Akcje',
  label: 'Etykieta odpowiedzi',
  progressStatus: 'Status zaawansowania',
  questionParams: 'Właściwości pytania',
  delete: 'Usuń',
  edit: 'Edytuj',
  swap: 'Przenieś',
});
