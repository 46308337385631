import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';

const FormField = ({
  onChange, name, label, touched, errors, children, onBlur, submitCount,
  variant, fullWidth, passErrorStatus, setSubmitting, isRTE, runBlurWithChange,
  formControlClassName,
}) => {
  const isError = (touched[name] || submitCount > 0) && errors[name];

  const newChildrenProps = {
    name,
    id: name,
    onBlur: () => onBlur(name, true),
    onChange: (event) => {
      onChange(name, event.target.value);

      if (setSubmitting) {
        setSubmitting(false);
      }

      if (runBlurWithChange) {
        onBlur(name, true);
      }
    },
  };

  if (passErrorStatus) {
    newChildrenProps.withError = !!isError;
  }

  return (
    <FormControl
      className={formControlClassName}
      error={!!isError}
      variant={variant}
      fullWidth={fullWidth}
    >
      {
        label ? (
          <InputLabel
            htmlFor={name}
            shrink={isRTE || undefined}
          >
            {label}
          </InputLabel>
        ) : null
      }
      {
        React.cloneElement(children, newChildrenProps)
      }
      {
        isError ? (
          <FormHelperText>{errors[name]}</FormHelperText>
        ) : null
      }
    </FormControl>
  );
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  name: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  formControlClassName: PropTypes.string,
  fullWidth: PropTypes.bool,
  isRTE: PropTypes.bool,
  label: PropTypes.string,
  passErrorStatus: PropTypes.bool,
  runBlurWithChange: PropTypes.bool,
  setSubmitting: PropTypes.func,
  submitCount: PropTypes.number,
  variant: PropTypes.string,
};

FormField.defaultProps = {
  fullWidth: false,
  passErrorStatus: false,
  runBlurWithChange: false,
  setSubmitting: () => {},
  submitCount: 0,
  variant: null,
  label: null,
  isRTE: false,
  formControlClassName: '',
};

export default FormField;
