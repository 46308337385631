import { push } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import getFieldAndSortDirection from 'utils/getFieldAndSortDirection';
import getSortAndPageData from 'utils/getSortAndPageData';
import getCountStartFrom from 'utils/getCountStartFrom';
import dialogTexts from 'utils/dialogTexts';

import {
  hideLoader, showSnackbar, showLoader, showTransparentLoader,
  openDialog, setPage, setRowsPerPage, setLastFilterValues, setSortingData,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    search: '',
    answerSet: '',
  },
  items: [],
  answerSets: [],
};

const sortFieldsMapping = {
  name: 'name',
  answerSet: 'answer_set_type_id',
  answerCount: 'answers_count',
};

const mapAnswers = (surveys, startingPosition) => surveys.map((el, key) => ({
  id: key + 1 + startingPosition,
  apiId: el.id,
  name: el.name,
  answerSetType: el.answer_set_type_id,
  answersCount: el.answers_count,
}));

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'ANSWERS/LOAD_PAGE_SUCCESS',
  SET_FILTERED_ANSWERS: 'ANSWERS/SET_FILTERED_ANSWERS',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        items: mapAnswers(action.responses.answers.data.items, 0),
        answerSets: action.responses.answerSets.data,
        totalItemsCount: action.responses.answers.data.total,
      };
    }

    case actionTypes.SET_FILTERED_ANSWERS: {
      return {
        ...state,
        items: mapAnswers(action.response.data.items, action.startCountFrom),
        totalItemsCount: action.response.data.total,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  responses,
});

const setFilteredAnswers = (response, startCountFrom) => ({
  type: actionTypes.SET_FILTERED_ANSWERS,
  response,
  startCountFrom,
});

const loadAnswers = (params) => (dispatch) => {
  const data = {
    perPage: params.perPage,
    page: params.page,
  };

  if (params.lastFilterValues.search) {
    data.find = params.lastFilterValues.search;
  }

  if (params.lastFilterValues.answerSet) {
    data.findByAnswerSetType = params.lastFilterValues.answerSet;
  }

  if (params.sortedBy && params.orderBy) {
    data.sortedBy = params.sortedBy;
    data.orderBy = params.orderBy;
  }

  const url = createURLWithQuery('answer_sets', data);

  return ApiManager.request('get', dispatch, url);
};

const loadAnswerSets = () => (dispatch) => {
  const url = 'answer_set_types';

  return ApiManager.request('get', dispatch, url);
};

export const onAddClick = () => (dispatch) => {
  dispatch(push('/answersAddEdit'));
};

const getAnswers = () => (dispatch, getStore) => {
  dispatch(showTransparentLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const startCountFrom = getCountStartFrom(getStore);

  dispatch(loadAnswers(data)).then((response) => {
    dispatch(setFilteredAnswers(response, startCountFrom));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onSearchSubmit = (values) => (dispatch) => {
  dispatch(setPage(1));
  dispatch(setLastFilterValues(values));
  dispatch(getAnswers());
};

export const onChangeSort = (fieldName) => (dispatch, getStore) => {
  const data = getSortAndPageData(getStore, sortFieldsMapping);
  const mappedFieldName = sortFieldsMapping[fieldName];
  const newData = getFieldAndSortDirection(fieldName, data.sortedBy, data.orderBy, mappedFieldName);

  dispatch(setSortingData(newData));
  dispatch(getAnswers());
};

export const onChangePage = (event, page) => (dispatch) => {
  dispatch(setPage(page + 1));
  dispatch(getAnswers());
};

export const onChangeRowsPerPage = (event) => (dispatch) => {
  const value = event.target.value;

  dispatch(setPage(1));
  dispatch(setRowsPerPage(value));
  dispatch(getAnswers());
};

const onDeleteAccept = (id) => (dispatch, getStore) => () => {
  dispatch(showTransparentLoader());

  ApiManager.request('delete', dispatch, `answer_sets/${id}`).then(() => {
    const data = getSortAndPageData(getStore, sortFieldsMapping);
    const startCountFrom = getCountStartFrom(getStore);

    dispatch(loadAnswers(data)).then((response) => {
      dispatch(setFilteredAnswers(response, startCountFrom));
      dispatch(showSnackbar(snackbarMessages.answerDeleted));
      dispatch(hideLoader());
    }).catch((err) => {
      if (isBadRequest(err)) {
        dispatch(showSnackbar(snackbarMessages.wrongData));
      } else {
        dispatch(showSnackbar(snackbarMessages.globalError));
      }

      dispatch(hideLoader());
    });
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onDelete = (id) => (dispatch) => {
  dispatch(openDialog({
    title: dialogTexts.deleteAnswer,
    onAccept: dispatch(onDeleteAccept(id)),
  }));
};

export const onEdit = (id) => (dispatch) => {
  dispatch(push('/answersAddEdit', { id }));
};

export const loadPageData = () => (dispatch, getStore) => {
  dispatch(showLoader());
  const data = getSortAndPageData(getStore, sortFieldsMapping);

  PromiseAll({
    answers: dispatch(loadAnswers(data)),
    answerSets: dispatch(loadAnswerSets()),
  }).then((responses) => {
    dispatch(loadPageSuccess(responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};
