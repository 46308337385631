import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { classesShape } from 'utils/shapes/classesShape';
import logoSmall from 'assets/img/logo_small.png';
import pageLayoutStyles from './PageLayout.styles';

const PageLayout = ({
  classes, children, title,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.header}>
      <div className={classes.logoWrapper}>
        <img src={logoSmall} alt="Diagmatic" className={classes.logo} />
      </div>
      <div className={classNames({
        [classes.headerContent]: true,
      })}
      >
        <Typography
          variant="h6"
        >
          {title}
        </Typography>
      </div>
      <div className={classes.userData}>
        <div className={classes.idWrapper}>
          <Typography>
            ID
            {' '}
            {'2PK7FB'}
          </Typography>
        </div>
      </div>
    </div>
    <div className={classNames({
      [classes.pageContent]: true,
    })}
    >
      {children}
    </div>
  </div>
);

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: classesShape,
  title: PropTypes.string,
};

PageLayout.defaultProps = {
  classes: {},
  title: null,
};

export default withStyles(pageLayoutStyles)(PageLayout);
