import { goBack } from 'connected-react-router';
import _filter from 'lodash/filter';
import _forEach from 'lodash/forEach';
import ApiManager from 'utils/ApiManager';
import PromiseAll from 'utils/PromiseAll';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import createURLWithQuery from 'utils/createURLWithQuery';
import setFormErrors from 'utils/setFormErrors';
import apiFieldMappings from 'utils/apiFieldMappings';
import {
  showLoader, hideLoader, showSnackbar, getMeta, showTransparentLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  initialValues: {
    name: '',
    surname: '',
    email: '',
    status: '',
    role: '',
    password: '',
    confirmPassword: '',
    organizations: [{
      organization: {},
    }],
  },
  roles: [],
  statuses: [],
  suggestions: [],
  isViewProfile: false,
  userId: null,
};

const mapDataToValues = (data) => {
  const organizations = [];

  _forEach(data.organizations, (el) => {
    organizations.push({
      organization: {
        id: el.id,
        name: el.name,
      },
    });
  });

  if (organizations.length === 0) {
    organizations.push({
      organization: {},
    });
  }

  return {
    name: data.first_name || '',
    surname: data.last_name || '',
    email: data.email || '',
    status: data.status || '',
    role: data.role_id || '',
    organizations,
  };
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'USERS_ADD_EDIT/LOAD_PAGE_SUCCESS',
  CLEAR_STORE: 'USERS_ADD_EDIT/CLEAR_STORE',
  SET_SUGGESTIONS: 'USERS_ADD_EDIT/SET_SUGGESTIONS',
  CLEAR_SUGGESTIONS: 'USERS_ADD_EDIT/CLEAR_SUGGESTIONS',
};

const mapSuggestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        isViewProfile: action.isViewProfile,
        userId: action.userId,
        initialValues: action.responses.userData
          ? mapDataToValues(action.responses.userData.data) : initialState.initialValues,
        roles: action.responses.meta.data.userRole,
        statuses: action.responses.meta.data.userStatus,
      };
    }

    case actionTypes.CLEAR_STORE: {
      return {
        ...initialState,
      };
    }

    case actionTypes.SET_SUGGESTIONS: {
      return {
        ...state,
        suggestions: mapSuggestions(action.response.data.items),
      };
    }

    case actionTypes.CLEAR_SUGGESTIONS: {
      return {
        ...state,
        suggestions: [],
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (isViewProfile, userId, responses) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  isViewProfile,
  userId,
  responses,
});

const clearStore = () => ({
  type: actionTypes.CLEAR_STORE,
});

const setSuggestions = (response) => ({
  type: actionTypes.SET_SUGGESTIONS,
  response,
});

const loadUserData = (id) => (dispatch) => ApiManager.request('get', dispatch, `users/${id}`);

export const onSubmit = (values, { setFieldError }) => (dispatch, getStore) => {
  const store = getStore().UserAddEdit;
  const userId = store.userId;
  let url = 'users';
  let method = 'post';
  let message = snackbarMessages.userAddedSuccessfully;

  if (userId) {
    url = `users/${userId}`;
    method = 'put';
    message = snackbarMessages.userEditedSuccessfully;
  }

  const organizations = _filter(values.organizations, (el) => !!el.organization.id);

  const data = {
    first_name: values.name,
    last_name: values.surname,
    email: values.email,
    role_id: values.role,
    status: values.status,
    organizations: organizations.map((el) => ({
      organization_id: el.organization.id,
    })),
  };

  if (!userId || (userId && values.password && values.confirmPassword)) {
    data.password = values.password;
    data.password_confirmation = values.confirmPassword;
  }

  dispatch(showTransparentLoader());

  ApiManager.request(method, dispatch, url, data).then(() => {
    dispatch(showSnackbar(message));
    dispatch(goBack());
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      if (error.error.errors.organizations) {
        dispatch(showSnackbar({
          id: 'duplicatedOrganization',
          defaultMessage: error.error.errors.organizations[0],
        }));
      } else {
        setFormErrors(error.error.errors, setFieldError, apiFieldMappings.usersAddEdit);
        dispatch(showSnackbar(snackbarMessages.wrongData));
      }
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onCancel = () => (dispatch) => {
  dispatch(goBack());
};

const getOrganizations = (find) => (dispatch) => {
  const params = {
    find,
  };

  const url = createURLWithQuery('organizations', params);

  return ApiManager.request('get', dispatch, url);
};

export const onFetch = (search) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(getOrganizations(search)).then((response) => {
    dispatch(setSuggestions(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const onClear = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

export const loadPageData = (routeState) => (dispatch) => {
  dispatch(clearStore());
  dispatch(showLoader());

  const promises = {};
  promises.meta = dispatch(getMeta());

  if (routeState.id) {
    promises.userData = dispatch(loadUserData(routeState.id));
  }

  PromiseAll(promises).then((responses) => {
    dispatch(loadPageSuccess(!!routeState.isViewProfile, routeState.id, responses));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }
    dispatch(goBack());
    dispatch(hideLoader());
  });
};
