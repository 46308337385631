import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArchiveIcon from '@material-ui/icons/Archive';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import TablePaginationActions from 'components/TablePaginationActions';
import TableHead from 'components/TableHead';
import TableCell from 'components/TableCell';
import FormField from 'components/FormField';
import Autocomplete from 'components/Autocomplete';
import validator from 'utils/validator/core';
import checkIfSubmitForm from 'utils/checkIfSubmitForm';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import surveysResultsStyles from './SurveysResults.styles';

import messages from './SurveysResults.messages';

const validators = [{
  fieldName: 'company',
  validators: [{
    validatorName: 'maxLength',
    parameters: [255],
  }],
}];

const SurveysResults = ({
  classes, intl, initialValues, onSearchSubmit, items,
  totalItemsCount, page, rowsPerPageOptions, rowsPerPage,
  onChangePage, onChangeRowsPerPage, sortingDirection,
  sortingField, onChangeSort, suggestions, onFetch, onClear, onView, onDownload,
}) => {
  const head = [{
    id: 'number',
    label: '#',
    class: classes.numberCell,
    sortable: false,
  }, {
    id: 'company',
    label: intl.formatMessage(messages.company),
    class: classes.companyCell,
    sortable: true,
  }, {
    id: 'gradeData',
    label: intl.formatMessage(messages.gradeData),
    class: classes.gradeDataCell,
    sortable: true,
  }, {
    id: 'surveysCount',
    label: intl.formatMessage(messages.surveysCount),
    class: classes.surveysCountCell,
    sortable: true,
  }, {
    id: 'actions',
    label: intl.formatMessage(messages.actions),
    class: classes.actionsCell,
    sortable: false,
  }];

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.surveysResults)}
        </Typography>
      </div>
      <Formik
        initialValues={
          { ...initialValues }
        }
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSearchSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          dirty,
          handleReset,
          submitCount,
          isSubmitting,
          setSubmitting,
        }) => (
          <form onSubmit={(e) => { checkIfSubmitForm(e, handleSubmit, !dirty || isSubmitting); }}>
            <div className={classes.filtersWrapper}>
              <div className={classes.searchWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  setSubmitting={setSubmitting}
                  name="company"
                  label={intl.formatMessage(messages.company)}
                  variant="outlined"
                  fullWidth
                >
                  <Autocomplete
                    onFetch={onFetch}
                    onClear={onClear}
                    labelWidth={85}
                    suggestions={suggestions}
                    value={values.company}
                    fullWidth
                  />
                </FormField>
              </div>
              <div className={classes.buttonsWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!dirty || isSubmitting || !_isEmpty(errors)}
                >
                  {intl.formatMessage(messages.filter)}
                </Button>
                <div className={classes.resetWrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={(event) => {
                      handleReset(event);
                      onSearchSubmit({});
                    }}
                    disabled={!(submitCount || dirty)}
                  >
                    {intl.formatMessage(messages.reset)}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      />
      <div className={classes.contentWrapper}>
        {
          items.length > 0 ? (
            <React.Fragment>
              <Table
                stickyHeader
                className={classes.root}
              >
                {' '}
                <TableHead
                  rows={head}
                  sortingDirection={sortingDirection}
                  sortingField={sortingField}
                  onChangeSort={onChangeSort}
                />
                <TableBody>
                  {items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell noWrap component="th" scope="row" className={classes.numberCell}>
                        {row.id}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.companyCell}>
                        {row.company}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.gradeDataCell}>
                        {row.gradeData}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.surveysCountCell}>
                        {row.surveysCount}
                      </TableCell>
                      <TableCell component="th" scope="row" className={classes.actionsCell}>
                        <div className={classes.actionsWrapper}>
                          <Tooltip title={intl.formatMessage(messages.see)} placement="top">
                            <IconButton onClick={() => onView(row.apiId)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={intl.formatMessage(messages.download)} placement="top">
                            <IconButton onClick={() => onDownload(row.apiId)}>
                              <ArchiveIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={TablePaginationActions}
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={totalItemsCount}
                rowsPerPage={rowsPerPage}
                page={page - 1}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                className={classNames({
                  [classes.caption]: true,
                  [classes.paginationRoot]: true,
                })}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
                labelRowsPerPage={intl.formatMessage(messages.rowsPerPage)}
                classes={{
                  select: classes.selectWrapper,
                }}
              />
            </React.Fragment>
          ) : null
        }
      </div>
    </React.Fragment>
  );
};

SurveysResults.propTypes = {
  intl: intlShape.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItemsCount: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    company: PropTypes.object,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    company: PropTypes.string,
    gradeData: PropTypes.string,
    surveysCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  sortingDirection: PropTypes.oneOf(['asc', 'desc']),
  sortingField: PropTypes.string,
  suggestions: PropTypes.arrayOf(itemShape),
  onChangeSort: PropTypes.func,
  onClear: PropTypes.func,
  onDownload: PropTypes.func,
  onFetch: PropTypes.func,
  onView: PropTypes.func,
};

SurveysResults.defaultProps = {
  classes: {},
  items: [],
  initialValues: {},
  sortingField: null,
  sortingDirection: 'asc',
  onChangeSort: () => {},
  onFetch: () => {},
  onClear: () => {},
  onView: () => {},
  onDownload: () => {},
  suggestions: [],
};

export default withStyles(surveysResultsStyles)(injectIntl(SurveysResults));
