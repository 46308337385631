import { defineMessages } from 'react-intl';

export default defineMessages({
  organizationData: 'Dane placówki',
  name: 'Nazwa',
  city: 'Miasto',
  street: 'Ulica',
  number: 'Numer',
  postalCode: 'Kod pocztowy',
  systemParams: 'Parametry systemowe',
  consent: 'Zgoda',
  type: 'Typ placówki',
  survey: 'Ankieta',
  surveys: 'Ankiety',
  status: 'Status placówki',
  cancel: 'Anuluj',
  save: 'Zapisz',
  dateFrom: 'Data urodzenia od',
  dateTo: 'Data urodzenia do',
});
