import { goBack } from 'connected-react-router';
import ApiManager from 'utils/ApiManager';
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';

import {
  hideLoader, showSnackbar, showLoader,
} from 'containers/store';

export const initialState = {
  isLoadedPage: false,
  questions: [],
  surveyName: '',
};

export const actionTypes = {
  LOAD_PAGE_SUCCESS: 'PREVIEW_SURVEY/LOAD_PAGE_SUCCESS',
};

const mapQuestions = (items) => items.map((el) => ({
  id: el.id,
  name: el.name,
  answers: el.answer_set.answers.map((el1) => ({
    id: `${el.id}-${el1.id}`,
    answer: el1.label,
  })),
  image: el.image || null,
}));

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_PAGE_SUCCESS: {
      return {
        ...state,
        isLoadedPage: true,
        questions: mapQuestions(action.response.data.questions),
        surveyName: action.response.data.name,
      };
    }

    default:
      return state;
  }
};

const loadPageSuccess = (response) => ({
  type: actionTypes.LOAD_PAGE_SUCCESS,
  response,
});

const loadSurveyData = (id) => (dispatch) => ApiManager.request('get', dispatch, `surveys/${id}`);

export const loadPageData = (routeState) => (dispatch) => {
  dispatch(showLoader());

  dispatch(loadSurveyData(routeState.id)).then((response) => {
    dispatch(loadPageSuccess(response));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(goBack());
    dispatch(hideLoader());
  });
};
