export default () => ({
  title: {
    marginBottom: 12,
  },
  questionWrapper: {
    marginBottom: 12,
    wordBreak: 'break-word',
  },
  questionsWrapper: {
    width: 768,
    margin: 'auto',
  },
});
