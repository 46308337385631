import { defineMessages } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

const messages = defineMessages({
  fieldValueTooShort: 'Wartość pola zbyt krótka',
});

export default (value, passedMinLength) => {
  const result = {};
  let minLength = passedMinLength;
  let finalValue = value;

  if (Number.isNaN(passedMinLength)) {
    minLength = 1;
  }

  if (isEmpty(value)) {
    finalValue = '';
  }

  const stringifiedValue = finalValue.toString();

  result.isValid = stringifiedValue.length >= minLength;
  result.message = messages.fieldValueTooShort;

  return result;
};
