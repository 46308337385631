import React from 'react';
import PropTypes from 'prop-types';
import _includes from 'lodash/includes';
import UploadButton from './components/UploadButton';

// file size in MB
const parseFileSizeToMB = (file) => file / 1024 / 1024;
class UploadButtonController extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    imageUrl: PropTypes.string,
    uploadButtonId: PropTypes.string,
    withoutPreview: PropTypes.bool,
  };

  static defaultProps = {
    imageUrl: '',
    uploadButtonId: '',
    withoutPreview: false,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.imageUrl !== state.imageUrl) {
      return {
        uploaded: !!props.imageUrl,
        imageUrl: props.imageUrl,
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];

    this.state = {
      isErrorImageWeight: false,
      isErrorImage: false,
      uploaded: !!props.imageUrl,
      imageUrl: props.imageUrl,
    };
  }

  onAddFile = (event) => {
    const eventObject = event.target;
    const imageLocalUrl = URL.createObjectURL(eventObject.files[0]);
    const imageWeight = parseFileSizeToMB(eventObject.files[0].size);

    if (_includes(this.acceptedImageTypes, eventObject.files[0].type)) {
      if (imageWeight > 1) {
        this.setState({
          isErrorImageWeight: true,
          isErrorImage: false,
        });
      } else {
        this.setState({
          uploaded: true,
          imageUrl: imageLocalUrl,
          isErrorImage: false,
          isErrorImageWeight: false,
        }, () => { this.props.onChange(eventObject, imageLocalUrl); });
      }
    } else {
      this.setState({
        isErrorImageWeight: false,
        isErrorImage: true,
      });
    }
  }

  onRemoveFile = () => {
    this.setState({
      uploaded: false,
      imageUrl: '',
    }, () => { this.props.onChange(null); });
  }

  render() {
    return (
      <UploadButton
        uploadButtonId={this.props.uploadButtonId}
        withoutPreview={this.props.withoutPreview}
        uploaded={this.state.uploaded}
        imageUrl={this.state.imageUrl}
        onAddFile={this.onAddFile}
        isErrorImage={this.state.isErrorImage}
        onRemoveFile={this.onRemoveFile}
        isErrorImageWeight={this.state.isErrorImageWeight}
      />
    );
  }
}


export default UploadButtonController;
