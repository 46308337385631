import React from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const AlertDialog = ({
  cancelButtonText,
  contentText,
  onCancel,
  onDialogClose,
  onSubmit,
  open,
  saveButtonText,
  title,
}) => (
  <Dialog open={open} onClose={onDialogClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{contentText}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} color="primary">
        {cancelButtonText}
      </Button>
      <Button onClick={onSubmit} color="primary">
        {saveButtonText}
      </Button>
    </DialogActions>
  </Dialog>
);

AlertDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  cancelButtonText: PropTypes.string,
  contentText: PropTypes.string,
  open: PropTypes.bool,
  saveButtonText: PropTypes.string,
  title: PropTypes.string,
  onCancel: PropTypes.func,
  onDialogClose: PropTypes.func,
};

AlertDialog.defaultProps = {
  open: false,
  onCancel: () => {},
  onDialogClose: () => {},
  title: '',
  contentText: '',
  cancelButtonText: '',
  saveButtonText: '',
};

export default AlertDialog;
