import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { classesShape } from 'utils/shapes/classesShape';

import QuestionPage from './components/QuestionPage';
import messages from './PreviewSurvey.messages';
import previewSurveyStyles from './PreviewSurvey.styles';

const PreviewSurvey = ({
  questions, surveyName, intl, classes,
}) => (
  <React.Fragment>
    <div className={classes.title}>
      <Typography variant="h6">{intl.formatMessage(messages.surveyPreview)}</Typography>
    </div>
    <div className={classes.questionsWrapper}>
      {
        questions.map((el, key) => (
          <div className={classes.questionWrapper} key={el.id}>
            <Typography variant="h6">
              {intl.formatMessage(messages.surveyNumber, { number: key + 1 })}
            </Typography>
            <QuestionPage
              surveyName={surveyName}
              question={el.name}
              answers={el.answers}
              image={el.image}
            />
          </div>
        ))
      }
    </div>
  </React.Fragment>
);

PreviewSurvey.propTypes = {
  intl: intlShape.isRequired,
  surveyName: PropTypes.string.isRequired,
  classes: classesShape,
  questions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      answer: PropTypes.string,
    })),
  })),
};

PreviewSurvey.defaultProps = {
  questions: [],
  classes: {},
};

export default withStyles(previewSurveyStyles)(injectIntl(PreviewSurvey));
