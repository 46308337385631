export default (theme) => ({
  wrapper: {
    width: 468,
    margin: 'auto',
  },
  questionWrapper: {
    marginTop: 12,
    padding: 24,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderRadius: 5,
  },
  answersWrapper: {
    marginTop: 48,
  },
  answerWrapper: {
    padding: 24,
    border: `2px solid ${theme.palette.background.main}`,
    borderRadius: 5,
    marginTop: 12,
    cursor: 'pointer',
    position: 'relative',
  },
  activeAnswerWrapper: {
    borderColor: theme.palette.primary.main,
  },
  errorAnswerWrapper: {
    borderColor: theme.palette.error.main,
  },
  checkWrapper: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  submitWrapper: {
    marginTop: 24,
  },
  errorMessage: {
    marginTop: 12,
  },
  caption: {
    color: theme.palette.error.main,
  },
  cardImage: {
    marginTop: 48,
  },
  media: {
    height: 300,
    backgroundSize: 'cover',
  },
});
