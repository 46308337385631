import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ParticipantEditPage from 'components/pages/ParticipantEdit';
import pageTitles from 'utils/pageTitles';
import getRouteState from 'utils/getRouteState';
import { historyShape } from 'utils/shapes/router.shapes';

import { setTitle, setActiveMenuItem } from 'containers/store';

import {
  onSubmit, onCancel, loadPageData,
} from './store';

class ParticipantEdit extends React.Component {
  static propTypes = {
    history: historyShape.isRequired,
    intl: intlShape.isRequired,
    isLoadedPage: PropTypes.bool.isRequired,
    loadPageData: PropTypes.func.isRequired,
    setActiveMenuItem: PropTypes.func.isRequired,
    setTitle: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    participantId: PropTypes.number,
  };

  static defaultProps = {
    participantId: null,
  };

  componentDidMount() {
    this.props.setTitle(this.props.intl.formatMessage(pageTitles.surveysResults));
    this.props.setActiveMenuItem('surveysResults');
    const routeState = getRouteState(this.props.history);
    this.props.loadPageData(routeState);
  }

  render() {
    return this.props.isLoadedPage ? (
      <ParticipantEditPage
        {...this.props}
      />
    ) : null;
  }
}

const mapDispatchToProps = {
  setTitle,
  onSubmit,
  onCancel,
  loadPageData,
  setActiveMenuItem,
};

const mapStateToProps = (state) => ({
  ...state.ParticipantEdit,
});


export default (
  connect(mapStateToProps, mapDispatchToProps)(injectIntl(withRouter(ParticipantEdit)))
);
