export default () => ({
  bold: {
    fontWeight: 500,
  },
  regular: {
    fontWeight: 300,
  },
  elipsis: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
