import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SwapIcon from '@material-ui/icons/SwapVert';
import Tooltip from '@material-ui/core/Tooltip';

import PreviewPopup from 'components/PreviewPopup';
import DraggableRows from 'components/DraggableRows';
import AddQuestion from 'components/pages/SurveyCreator/components/AddQuestion';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';

import AnswersPopup from 'components/AnswersPopup';
import currentQuestionSetStyles from './CurrentQuestionSet.styles';
import messages from './CurrentQuestionSet.messages';

const generateRows = (
  items, classes, onDelete, onEdit, isEdit, onReorder, onLabelEdit, editInitialValues,
  onEditQuestionSubmit, onCancel, groups, types, disableFields, onProgressStatusEdit,
  onQuestionParamsEdit, intl,
) => {
  const rows = items.map((row, index) => ({
    id: row.id,
    apiId: row.apiId,
    draggableId: `${row.apiId}-${index}`,
    component: (
      <React.Fragment key={row.id}>
        <React.Fragment>
          <TableCell component="th" scope="row" className={classes.numberCell}>
            {index + 1}
          </TableCell>
          <TableCell>
            { row.imageUrl ? (
              <PreviewPopup imageUrl={row.imageUrl} />
            ) : null }
          </TableCell>
          <TableCell className={classes.questionContentCell}>
            { /* eslint-disable-next-line react/no-danger */ }
            <div dangerouslySetInnerHTML={{ __html: row.content }} />
          </TableCell>
          <TableCell className={classes.questionParamCell}>
            {
              disableFields ? null : (
                <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                  <IconButton
                    onClick={() => { onQuestionParamsEdit(index); }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )
            }
          </TableCell>
          <TableCell className={classes.progressStatusLabelCell}>
            <div className={classes.questionLabelContainer}>
              <div className={
                  classNames(
                    classes.questionLabelText,
                    classes.ellipsis,
                  )
                }
              >
                {row.progressStatus}
              </div>
              {
                disableFields ? null : (
                  <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                    <IconButton
                      onClick={() => { onProgressStatusEdit(index); }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )
              }
            </div>
          </TableCell>
          <TableCell className={classes.questionLabelCell}>
            <div className={classes.questionLabelContainer}>
              <div className={
                  classNames(
                    classes.questionLabelText,
                    classes.ellipsis,
                  )
                }
              >
                {row.label}
              </div>
              <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                <IconButton
                  onClick={() => { onLabelEdit(index); }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </div>
          </TableCell>
          <TableCell className={classes.answerSetCell}>
            <div className={classes.answerSetWrapper}>
              {row.answerSet.name}
              {
                row.answerSet.answers.length > 0 ? (
                  <AnswersPopup
                    answers={row.answerSet.answers}
                  />
                ) : null
              }
            </div>
          </TableCell>
          <TableCell className={classNames(
            classes.questionTypeCell,
            classes.questionTypeContent,
          )}
          >
            {row.type}
          </TableCell>
          <TableCell className={classes.groupCell}>
            {row.group}
          </TableCell>
          <TableCell className={classNames(
            classes.actionsCell,
          )}
          >
            <div className={classes.buttonCell}>
              {
                disableFields ? null : (
                  <React.Fragment>
                    {
                      items.length > 1 ? (
                        <Tooltip title={intl.formatMessage(messages.swap)} placement="top">
                          <IconButton
                            onClick={() => { onReorder(index); }}
                          >
                            <SwapIcon />
                          </IconButton>
                        </Tooltip>
                      ) : null
                    }
                    <Tooltip title={intl.formatMessage(messages.delete)} placement="top">
                      <IconButton
                        onClick={() => { onDelete(row.apiId); }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={intl.formatMessage(messages.edit)} placement="top">
                      <IconButton
                        onClick={() => { onEdit(row); }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </React.Fragment>
                )
              }
            </div>
          </TableCell>
        </React.Fragment>
      </React.Fragment>
    ),
    additionalContent: isEdit && row.apiId === editInitialValues.apiId ? (
      <TableRow>
        <TableCell colSpan={9} className={classes.editTableCell}>
          <div>
            <AddQuestion
              uploadButtonId="editPicture"
              onSubmit={onEditQuestionSubmit}
              onCancel={onCancel}
              initialValues={editInitialValues}
              groups={groups}
              types={types}
              isEdit
            />
          </div>
        </TableCell>
      </TableRow>
    ) : null,
  }));

  return rows;
};

const CurrentQuestionSet = ({
  intl, classes, items, onDelete, onEdit, onReorder, onLabelEdit, isEdit, editInitialValues,
  onEditQuestionSubmit, onCancel, groups, types, reorderItems, disableFields, onProgressStatusEdit,
  onQuestionParamsEdit,
}) => {
  const head = [{
    id: 'number',
    label: '#',
    class: classes.numberCell,
    sortable: false,
  }, {
    id: 'picture',
    label: intl.formatMessage(messages.picture),
    sortable: false,
  }, {
    id: 'questionContent',
    label: intl.formatMessage(messages.content),
    class: classes.questionContentCell,
    sortable: false,
  }, {
    id: 'questionParams',
    label: intl.formatMessage(messages.questionParams),
    class: classes.questionParamCell,
    sortable: false,
  }, {
    id: 'progressStatusLabel',
    label: intl.formatMessage(messages.progressStatus),
    class: classes.progressStatusLabelCell,
    sortable: false,
  }, {
    id: 'questionLabel',
    label: intl.formatMessage(messages.label),
    class: classes.questionLabelCell,
    sortable: false,
  }, {
    id: 'answerSet',
    label: intl.formatMessage(messages.set),
    class: classes.answerSetCell,
    sortable: false,
  }, {
    id: 'questionType',
    label: intl.formatMessage(messages.type),
    class: classes.questionTypeCell,
    sortable: false,
  }, {
    id: 'group',
    label: intl.formatMessage(messages.group),
    class: classes.groupCell,
    sortable: false,
  }, {
    id: 'actions',
    label: intl.formatMessage(messages.actions),
    class: classes.actionsCell,
    sortable: false,
  }];

  const rows = generateRows(
    items, classes, onDelete, onEdit, isEdit, onReorder, onLabelEdit, editInitialValues,
    onEditQuestionSubmit, onCancel, groups, types, disableFields, onProgressStatusEdit,
    onQuestionParamsEdit, intl,
  );

  return (
    <React.Fragment>
      <div className={classes.title}>
        <Typography variant="h6">
          {intl.formatMessage(messages.currentSet)}
        </Typography>
      </div>
      <div className={classes.tableWrapper}>
        {
          items.length > 0 ? (
            <React.Fragment>
              <DraggableRows
                head={head}
                rows={rows}
                onDrop={(newItems) => { reorderItems(newItems, items); }}
                disabled={disableFields}
              />
            </React.Fragment>
          ) : null
        }
      </div>
    </React.Fragment>
  );
};

CurrentQuestionSet.propTypes = {
  intl: intlShape.isRequired,
  reorderItems: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onReorder: PropTypes.func.isRequired,
  classes: classesShape,
  disableFields: PropTypes.bool,
  editInitialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  groups: PropTypes.arrayOf(itemShape),
  isEdit: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    content: PropTypes.string,
    answerSet: PropTypes.object,
    type: PropTypes.string,
    group: PropTypes.string,
  })),
  types: PropTypes.arrayOf(itemShape),
  onCancel: PropTypes.func,
  onEditQuestionSubmit: PropTypes.func,
  onLabelEdit: PropTypes.func,
  onProgressStatusEdit: PropTypes.func,
  onQuestionParamsEdit: PropTypes.func,
};

CurrentQuestionSet.defaultProps = {
  classes: {},
  disableFields: false,
  editInitialValues: {},
  isEdit: false,
  items: [],
  onCancel: () => {},
  onLabelEdit: () => {},
  onProgressStatusEdit: () => {},
  onEditQuestionSubmit: () => {},
  onQuestionParamsEdit: () => {},
  groups: [],
  types: [],
};

export default withStyles(currentQuestionSetStyles)(injectIntl(CurrentQuestionSet));
