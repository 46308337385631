import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _forEach from 'lodash/forEach';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import FormField from 'components/FormField';
import FormDialog from 'components/FormDialog';
import disabledSaveButton from 'utils/disableSaveButton';
import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';
import surveyCreatorStyles from './SurveyCreator.styles';

import QuestionLabelFormDialog from './components/QuestionLabelFormDialog';
import QuestionProgressStatusFormDialog from './components/QuestionProgressStatusFormDialog';
import QuestionParamsFormDialog from './components/QuestionParamsFormDialog';
import messages from './SurveyCreator.messages';
import AddQuestion from './components/AddQuestion';
import QuestionRepository from './components/QuestionRepository';
import CurrentQuestionSet from './components/CurrentQuestionSet';
import NewSet from './components/NewSet';

const getValidators = (isEdit, initialStatus) => {
  let allowedStatuses = ['PROTOTYPE', 'ACTIVE', 'NOT_ACTIVE'];

  if (isEdit) {
    allowedStatuses = initialStatus === 'PROTOTYPE'
      ? ['ACTIVE', 'PROTOTYPE'] : ['ACTIVE', 'NOT_ACTIVE'];
  }

  return [{
    fieldName: 'name',
    validators: [
      {
        validatorName: 'notEmpty',
      }, {
        validatorName: 'maxLength',
        parameters: [255],
      },
    ],
  }, {
    fieldName: 'group',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  }, {
    fieldName: 'surveyType',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  }, {
    fieldName: 'surveyRespondent',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  }, {
    fieldName: 'surveyStatus',
    validators: [
      {
        validatorName: 'notEmpty',
      },
      {
        validatorName: 'oneOf',
        parameters: [allowedStatuses],
      },
    ],
  }, {
    fieldName: 'timeLimit',
    validators: [
      {
        validatorName: 'notEmpty',
      },
      {
        validatorName: 'isNumber',
        parameters: [true],
        customErrorMessage: messages.onlyNumbers,
      },
      {
        validatorName: 'lowerOrEqualTo',
        parameters: [86400],
      },
    ],
  }];
};

const addItem = (item, setFieldValue, values, showDuplicateError) => {
  const questions = [...values.questions];

  const findDuplicate = _find(questions, { apiId: item.apiId });

  if (findDuplicate) {
    showDuplicateError();
  } else {
    questions.push(item);
  }

  setFieldValue('questions', questions);
};

class SurveyCreator extends React.Component {
    static propTypes = {
      addNewSet: PropTypes.func.isRequired,
      intl: intlShape.isRequired,
      onAddSubmit: PropTypes.func.isRequired,
      onRepositorySubmit: PropTypes.func.isRequired,
      onSubmit: PropTypes.func.isRequired,
      addInitialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      addNewSetInitialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      classes: classesShape,
      disableFields: PropTypes.bool,
      groups: PropTypes.arrayOf(itemShape),
      initialValues: PropTypes.shape({
        name: PropTypes.string,
        group: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        timeLimit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        surveyStatus: PropTypes.string,
        questions: PropTypes.array,
      }),
      isEdit: PropTypes.bool,
      questionGroups: PropTypes.arrayOf(itemShape),
      questionTypes: PropTypes.arrayOf(itemShape),
      repositoryInitialValues: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      repositoryItems: PropTypes.array, // eslint-disable-line react/forbid-prop-types
      repositoryPagingData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      setTypes: PropTypes.arrayOf(itemShape),
      showDuplicateError: PropTypes.func,
      showParamsRemoved: PropTypes.func,
      sortingDirection: PropTypes.oneOf(['asc', 'desc']),
      sortingField: PropTypes.string,
      suggestions: PropTypes.arrayOf(itemShape),
      surveyRespondents: PropTypes.arrayOf(itemShape),
      surveyStatuses: PropTypes.arrayOf(itemShape),
      surveyTypes: PropTypes.arrayOf(itemShape),
      onChangeSort: PropTypes.func,
      onClear: PropTypes.func,
      onEditQuestionSubmit: PropTypes.func,
      onFetch: PropTypes.func,
    };

    static defaultProps = {
      addInitialValues: {},
      addNewSetInitialValues: {},
      classes: {},
      disableFields: false,
      initialValues: {},
      isEdit: false,
      groups: [],
      surveyTypes: [],
      surveyRespondents: [],
      suggestions: [],
      questionTypes: [],
      questionGroups: [],
      repositoryInitialValues: {},
      repositoryItems: [],
      repositoryPagingData: {},
      surveyStatuses: [],
      onEditQuestionSubmit: () => {},
      setTypes: [],
      onClear: () => {},
      onFetch: () => {},
      showDuplicateError: () => {},
      showParamsRemoved: () => {},
      sortingField: null,
      sortingDirection: 'asc',
      onChangeSort: () => {},
    };

    constructor(props) {
      super(props);

      this.state = {
        editingFrom: null,
        editInitialValues: {},
        addingSetMode: false,
        dialogOpen: false,
        reorderingItemIndex: null,
        questionLabelDialogOpened: false,
        questionLabelEditedItemIndex: null,
        questionProgressStatusDialogOpened: false,
        questionProgressStatusEditedItemIndex: null,
        questionParamsDialogOpened: false,
        questionParamsEditedItemIndex: null,
      };
    }

    removeMappingsErrors = (questions) => {
      const withRemovedWrongMappings = [];
      let showNotification = false;

      _forEach(questions, (el, key) => {
        if (!el.answersMapping) {
          withRemovedWrongMappings.push(el);
          return true;
        }

        const newMapping = [];

        _forEach(el.answersMapping, (el1) => {
          const mappedQuestionIndex = _findIndex(questions, (el2) => el2.apiId === el1.questionId);

          if (mappedQuestionIndex > key) {
            newMapping.push(el1);
          } else {
            showNotification = true;
          }
        });

        withRemovedWrongMappings.push({
          ...el,
          answersMapping: newMapping,
        });

        return true;
      });

      if (showNotification) {
        this.props.showParamsRemoved();
      }

      return withRemovedWrongMappings;
    }

    deleteItem = (apiId, setFieldValue, values) => {
      const questions = [...values.questions];

      const index = _findIndex(questions, (el) => el.apiId === apiId);

      questions.splice(index, 1);

      const withRemovedMappings = this.removeMappingsErrors(questions);

      setFieldValue('questions', withRemovedMappings);
    };

    reorderItems = (newItems, prevItems, setFieldValue) => {
      let reorderedItems = [];

      _forEach(newItems, (el) => {
        const properItem = _find(prevItems, (el1) => el1.apiId === el.apiId);
        reorderedItems.push(properItem);
      });

      reorderedItems = this.removeMappingsErrors(reorderedItems);

      setFieldValue('questions', reorderedItems);
    }

    onEditClick = (row, from) => {
      const type = _find(this.props.questionTypes, (el) => el.name === row.type);
      const group = _find(this.props.questionGroups, (el) => el.name === row.group);
      const questionPicture = row.imageUrl ? row.imageUrl : null;

      const initialValues = {
        content: row.content,
        contentFemale: row.contentFemale,
        answerSet: {
          id: row.answerSet.id,
          name: row.answerSet.name,
        },
        apiId: row.apiId,
        type: type.id,
        group: group.id,
        imageUrl: row.imageUrl,
        questionPicture,
        questionImageObject: {},
      };

      this.setState({
        editingFrom: from,
        editInitialValues: initialValues,
      });
    }

    onCancel = () => {
      this.setState({
        editingFrom: null,
        editInitialValues: {},
      });
    }

    onEditQuestionSubmit = (editValues, formikMethods, values, fromRepo) => {
      this.props.onEditQuestionSubmit(editValues, formikMethods, values, fromRepo).then(() => {
        this.setState({
          editingFrom: null,
          editInitialValues: {},
        });
      });
    }

    onAddNewSet = () => {
      this.setState({
        addingSetMode: true,
      });
    }

    onAddSetCancel = () => {
      this.setState({
        addingSetMode: false,
      });
    }

    onAddSetSubmit = (values, methods) => {
      this.props.addNewSet(values, methods).then(() => {
        this.setState({
          addingSetMode: false,
        });
      });
    }

    reorder = (values, methods, currentQuestions, questionsSetFieldValue) => {
      const movingElementIndex = this.state.reorderingItemIndex;
      const element = currentQuestions[movingElementIndex];

      currentQuestions.splice(movingElementIndex, 1);
      currentQuestions.splice(values.value - 1, 0, element);

      const withRemovedMappingsError = this.removeMappingsErrors(currentQuestions);

      questionsSetFieldValue('questions', withRemovedMappingsError);

      methods.resetForm();

      this.onCloseClick();
    }

    setQuestionLabel = (label, questions, setFieldValue) => {
      const mutatedQuestions = [...questions];
      mutatedQuestions[this.state.questionLabelEditedItemIndex].label = label;

      setFieldValue('questions', questions);
    }

    setQuestionProgressStatus = (progress, questions, setFieldValue) => {
      const newQuestions = [...questions];
      newQuestions[this.state.questionProgressStatusEditedItemIndex].progressStatus = progress;

      setFieldValue('questions', newQuestions);
    }

    setQuestionParams = (values, questions, setFieldValue) => {
      const mutatedQuestions = [...questions];

      const questionsIndex = this.state.questionParamsEditedItemIndex;
      mutatedQuestions[questionsIndex].isLast = values.isLast;
      mutatedQuestions[questionsIndex].answersMapping = values.answersMapping;

      setFieldValue('questions', questions);
    }

    onQuestionLabelEdit = (index) => {
      this.setState({
        questionLabelDialogOpened: true,
        questionLabelEditedItemIndex: index,
      });
    }

    onQuestionLabelEditClose = () => {
      this.setState({
        questionLabelDialogOpened: false,
        questionLabelEditedItemIndex: null,
      });
    }

    onQuestionProgressStatusEdit = (index) => {
      this.setState({
        questionProgressStatusDialogOpened: true,
        questionProgressStatusEditedItemIndex: index,
      });
    }

    onQuestionProgressStatusEditClose = () => {
      this.setState({
        questionProgressStatusDialogOpened: false,
        questionProgressStatusEditedItemIndex: null,
      });
    }

    onQuestionParamsEdit = (index) => {
      this.setState({
        questionParamsDialogOpened: true,
        questionParamsEditedItemIndex: index,
      });
    }

    onQuestionParamsEditClose = () => {
      this.setState({
        questionParamsDialogOpened: false,
        questionParamsEditedItemIndex: null,
      });
    }

    onReorderClick = (index) => {
      this.setState({
        dialogOpen: true,
        reorderingItemIndex: index,
      });
    }

    onCloseClick = () => {
      this.setState({
        dialogOpen: false,
        reorderingItemIndex: null,
      });
    }

    getQuestionAnswers = (values) => {
      if (!this.state.questionParamsDialogOpened) {
        return [];
      }

      const selectedQuestion = values.questions[this.state.questionParamsEditedItemIndex];
      const answers = _get(selectedQuestion, 'answerSet.answers', []);

      return answers.map((el) => ({
        id: el.id,
        name: el.label,
      }));
    }

    getAvailableQuestions = (values) => {
      if (!this.state.questionParamsDialogOpened) {
        return [];
      }

      const startingIndex = this.state.questionParamsEditedItemIndex + 1;
      const availableQuestions = values.questions.slice(startingIndex);

      return availableQuestions.map((el) => ({
        id: el.apiId,
        name: el.content,
      }));
    };

    getQuestionMappings = (values) => {
      if (!this.state.questionParamsDialogOpened) {
        return [];
      }

      const selectedItem = values.questions[this.state.questionParamsEditedItemIndex];

      return selectedItem.answersMapping || [];
    }

    render() {
      const {
        classes, intl, initialValues, onSubmit, groups, questionGroups, addNewSetInitialValues,
        questionTypes, onAddSubmit, addInitialValues, onRepositorySubmit, setTypes,
        repositoryInitialValues, repositoryItems, repositoryPagingData, surveyStatuses,
        suggestions, onFetch, onClear, disableFields, showDuplicateError, surveyTypes,
        surveyRespondents, onChangeSort, sortingField, sortingDirection,
      } = this.props;

      return (
        <React.Fragment>
          <div className={classNames(classes.wrapper, {
            [classes.invisible]: this.state.addingSetMode,
          })}
          >
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.basicData)}
              </Typography>
            </div>
            <Formik
              initialValues={{
                ...initialValues,
                questions: [...initialValues.questions],
              }}
              validate={
                (values) => validator(
                  values,
                  getValidators(this.props.isEdit, initialValues.surveyStatus),
                  intl.formatMessage,
                )
              }
              onSubmit={onSubmit}
              render={({
                values,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <React.Fragment>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.row}>
                      <div className={classes.bigFieldWrapper}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="name"
                          label={intl.formatMessage(messages.name)}
                          variant="outlined"
                          fullWidth
                        >
                          <OutlinedInput
                            value={values.name}
                            labelWidth={100}
                            margin="none"
                            variant="outlined"
                            disabled={disableFields}
                          />
                        </FormField>
                      </div>
                      <div className={classNames(classes.smallFieldWrapper, classes.fieldSpacer)}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="group"
                          label={intl.formatMessage(messages.group)}
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            value={values.group}
                            input={(
                              <OutlinedInput
                                labelWidth={45}
                                name="group"
                                id="group-picker"
                                disabled={disableFields}
                              />
                          )}
                          >
                            {
                            groups.map((el) => (
                              <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                            ))
                          }
                          </Select>
                        </FormField>
                      </div>
                      <div className={classNames(classes.smallFieldWrapper, classes.rightSpacer)}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="surveyStatus"
                          label={intl.formatMessage(messages.surveyStatus)}
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            value={values.surveyStatus}
                            input={(
                              <OutlinedInput
                                labelWidth={100}
                                name="surveyStatus"
                                id="surveyStatus-picker"
                              />
                          )}
                          >
                            {
                              surveyStatuses.map((el) => (
                                <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormField>
                      </div>
                      <div className={classNames(classes.smallFieldWrapper, classes.rightSpacer)}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="surveyRespondent"
                          label={intl.formatMessage(messages.surveyRespondent)}
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            value={values.surveyRespondent}
                            input={(
                              <OutlinedInput
                                labelWidth={90}
                                name="surveyRespondent"
                                id="surveyRespondent-picker"
                                disabled={disableFields}
                              />
                          )}
                          >
                            {
                              surveyRespondents.map((el) => (
                                <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormField>
                      </div>
                      <div className={classNames(classes.smallFieldWrapper, classes.rightSpacer)}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="surveyType"
                          label={intl.formatMessage(messages.surveyType)}
                          variant="outlined"
                          fullWidth
                        >
                          <Select
                            value={values.surveyType}
                            input={(
                              <OutlinedInput
                                labelWidth={55}
                                name="surveyType"
                                id="surveyType-picker"
                                disabled={disableFields}
                              />
                          )}
                          >
                            {
                              surveyTypes.map((el) => (
                                <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                              ))
                            }
                          </Select>
                        </FormField>
                      </div>
                      <div className={classes.smallFieldWrapper}>
                        <FormField
                          onBlur={setFieldTouched}
                          onChange={setFieldValue}
                          errors={errors}
                          touched={touched}
                          name="timeLimit"
                          label={intl.formatMessage(messages.timeLimit)}
                          variant="outlined"
                          fullWidth
                        >
                          <OutlinedInput
                            classes={{ input: classes.inputColor }}
                            value={values.timeLimit}
                            labelWidth={135}
                            margin="none"
                            variant="outlined"
                            endAdornment={intl.formatMessage(messages.minutes)}
                            disabled={disableFields}
                          />
                        </FormField>
                      </div>
                    </div>
                  </form>
                  <Prompt
                    when={!_isEqual(values, initialValues) && !isSubmitting}
                    message={intl.formatMessage(snackbarMessages.unsaveData)}
                  />
                  <div className={classes.separator} />
                  <QuestionRepository
                    onSubmit={onRepositorySubmit}
                    disabled={disableFields}
                    initialValues={repositoryInitialValues}
                    items={repositoryItems}
                    onAddClick={(item) => {
                      addItem(
                        item,
                        setFieldValue,
                        values,
                        showDuplicateError,
                      );
                    }}
                    onEditClick={(item) => { this.onEditClick(item, 'repository'); }}
                    onChangeSort={onChangeSort}
                    sortingField={sortingField}
                    sortingDirection={sortingDirection}
                    types={questionTypes}
                    groups={questionGroups}
                    isEdit={this.state.editingFrom === 'repository'}
                    editInitialValues={this.state.editInitialValues}
                    onCancel={this.onCancel}
                    onEditQuestionSubmit={
                      (editValues, formikMethods) => {
                        this.onEditQuestionSubmit(editValues, formikMethods, values, true);
                      }
                    }
                    {...repositoryPagingData}
                  />
                  <div className={classes.separator} />
                  <div className={classes.addQuestionWrapper}>
                    {
                      <AddQuestion
                        uploadButtonId="addPicture"
                        onSubmit={(value, formikMethods) => {
                          onAddSubmit(value, formikMethods, setFieldValue, values.questions);
                        }}
                        initialValues={addInitialValues}
                        groups={questionGroups}
                        types={questionTypes}
                        onAddNewSet={this.onAddNewSet}
                        suggestions={suggestions}
                        onFetch={onFetch}
                        onClear={onClear}
                        disabled={disableFields}
                      />
                    }
                  </div>
                  <div className={classes.currentItemsWrapper}>
                    <CurrentQuestionSet
                      items={values.questions}
                      onReorder={(index) => { this.onReorderClick(index); }}
                      onLabelEdit={(index) => { this.onQuestionLabelEdit(index); }}
                      onProgressStatusEdit={(index) => {
                        this.onQuestionProgressStatusEdit(index);
                      }}
                      onQuestionParamsEdit={(index) => { this.onQuestionParamsEdit(index); }}
                      onDelete={(apiId) => { this.deleteItem(apiId, setFieldValue, values); }}
                      onEdit={(item) => { this.onEditClick(item, 'currentSet'); }}
                      isEdit={this.state.editingFrom === 'currentSet'}
                      editInitialValues={this.state.editInitialValues}
                      onCancel={this.onCancel}
                      onEditQuestionSubmit={
                        (editValues, formikMethods) => {
                          this.onEditQuestionSubmit(editValues, formikMethods, values, false);
                        }
                      }
                      types={questionTypes}
                      groups={questionGroups}
                      reorderItems={(newItems, prevItems) => {
                        this.reorderItems(newItems, prevItems, setFieldValue);
                      }}
                      disableFields={disableFields}
                    />
                    <div className={classes.buttonsWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabledSaveButton(errors, touched)}
                        onClick={handleSubmit}
                      >
                        {intl.formatMessage(messages.save)}
                      </Button>
                    </div>
                  </div>
                  <FormDialog
                    title={intl.formatMessage(messages.dialogTitle)}
                    label={intl.formatMessage(messages.dialogLabel)}
                    onClose={() => { this.onCloseClick(); }}
                    onSubmit={(formDialogValues, methods) => {
                      this.reorder(formDialogValues, methods, values.questions, setFieldValue);
                    }}
                    open={this.state.dialogOpen}
                    maxValue={values.questions.length}
                  />
                  <QuestionLabelFormDialog
                    onClose={() => { this.onQuestionLabelEditClose(); }}
                    onSubmit={(formDialogValues) => {
                      this.setQuestionLabel(
                        formDialogValues.labelValue,
                        values.questions,
                        setFieldValue,
                      );
                      this.onQuestionLabelEditClose();
                    }}
                    questionLabel={
                      _get(values, `questions[${this.state.questionLabelEditedItemIndex}].label`, '')
                    }
                    open={this.state.questionLabelDialogOpened}
                  />
                  <QuestionProgressStatusFormDialog
                    onClose={() => { this.onQuestionProgressStatusEditClose(); }}
                    onSubmit={(formDialogValues) => {
                      this.setQuestionProgressStatus(
                        formDialogValues.labelValue,
                        values.questions,
                        setFieldValue,
                      );
                      this.onQuestionProgressStatusEditClose();
                    }}
                    questionStatusProgress={
                      _get(values, `questions[${this.state.questionProgressStatusEditedItemIndex}].progressStatus`, '')
                    }
                    open={this.state.questionProgressStatusDialogOpened}
                  />
                  <QuestionParamsFormDialog
                    onClose={() => { this.onQuestionParamsEditClose(); }}
                    onSubmit={(formDialogValues) => {
                      this.setQuestionParams(
                        formDialogValues,
                        values.questions,
                        setFieldValue,
                      );
                      this.onQuestionParamsEditClose();
                    }}
                    isLast={
                      _get(values, `questions[${this.state.questionParamsEditedItemIndex}].isLast`, false)
                    }
                    open={this.state.questionParamsDialogOpened}
                    answers={this.getQuestionAnswers(values)}
                    questions={this.getAvailableQuestions(values)}
                    questionMappings={this.getQuestionMappings(values)}
                  />
                </React.Fragment>
              )}
            />
          </div>
          <div className={classNames(classes.invisible, {
            [classes.newSetVisible]: this.state.addingSetMode,
          })}
          >
            <NewSet
              onCancel={this.onAddSetCancel}
              onSubmit={this.onAddSetSubmit}
              initialValues={addNewSetInitialValues}
              types={setTypes}
            />
          </div>
        </React.Fragment>
      );
    }
}

export default withStyles(surveyCreatorStyles)(injectIntl(SurveyCreator));
