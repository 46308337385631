import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldValueTooLarge: 'Wartość pola zbyt wysoka',
});

export default (value, maxValue) => {
  const result = {};

  result.isValid = value <= maxValue;
  result.message = messages.fieldValueTooLarge;

  return result;
};
