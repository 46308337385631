import isString from 'lodash/isString';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  fieldDoNotMatchOtherField: 'Podane hasła się różnią',
});

export default (value, otherFieldValue) => {
  const finalValue = isString(value) ? value : String(value);
  const result = {
    isValid: finalValue === otherFieldValue,
    message: messages.fieldDoNotMatchOtherField,
  };

  return result;
};
