export default () => ({
  mainWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: 48,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  rightIcon: {
    marginLeft: 8,
  },
  input: {
    display: 'none',
  },
  inputType: {
    display: 'flex',
    flexDirection: 'column',
  },
  formHelper: {
    margin: '8px 12px 0',
  },
});
