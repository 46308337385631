import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import FormField from 'components/FormField';
import logo from 'assets/img/logo.png';
import validator from 'utils/validator/core';
import { classesShape } from 'utils/shapes/classesShape';
import resetPasswordStyles from './ResetPassword.styles';

import messages from './ResetPassword.messages';

const validators = [
  {
    fieldName: 'login',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'password',
    validators: [
      {
        validatorName: 'notEmpty',
      },
    ],
  },
  {
    fieldName: 'confirmPassword',
    validators: [
      {
        validatorName: 'notEmpty',
      },
      {
        validatorName: 'matchOtherField',
        additionalFields: ['password'],
      },
    ],
  },
];

const ResetPassword = ({
  onSubmit, classes, intl, initialValues,
}) => (
  <React.Fragment>
    <div className={classes.logoWrapper}>
      <img src={logo} alt="diagmatic" />
    </div>
    <div className={classes.separator} />
    <div className={classes.login}>
      <Typography variant="h5">
        {intl.formatMessage(messages.resetPassword)}
      </Typography>
    </div>
    <Formik
      initialValues={
        { ...initialValues }
      }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            errors={errors}
            touched={touched}
            name="login"
            label={intl.formatMessage(messages.login)}
            variant="outlined"
            fullWidth
          >
            <OutlinedInput
              value={values.login}
              labelWidth={45}
              margin="none"
              variant="outlined"
            />
          </FormField>
          <div className={classes.passwordWrapper}>
            <FormField
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              errors={errors}
              touched={touched}
              name="password"
              label={intl.formatMessage(messages.password)}
              variant="outlined"
              fullWidth
            >
              <OutlinedInput
                type="password"
                value={values.password}
                labelWidth={45}
                margin="none"
                variant="outlined"
              />
            </FormField>
          </div>
          <div className={classes.confirmPasswordWrapper}>
            <FormField
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              errors={errors}
              touched={touched}
              name="confirmPassword"
              label={intl.formatMessage(messages.confirmPassword)}
              variant="outlined"
              fullWidth
            >
              <OutlinedInput
                type="password"
                value={values.confirmPassword}
                labelWidth={105}
                margin="none"
                variant="outlined"
              />
            </FormField>
          </div>
          <div className={classes.loginButtonWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {intl.formatMessage(messages.log)}
            </Button>
          </div>
        </form>
      )}
    />
  </React.Fragment>
);

ResetPassword.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  initialValues: PropTypes.shape({
    login: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  classes: {},
  initialValues: {},
};

export default withStyles(resetPasswordStyles)(injectIntl(ResetPassword));
