import _forEach from 'lodash/forEach';
import _keys from 'lodash/keys';
import _values from 'lodash/values';

export default (params) => (
  new Promise((resolve, reject) => (
    Promise.all(_values(params))
      .then((response) => {
        const result = {};

        _forEach(_keys(params), (name, index) => {
          result[name] = response[index];
        });

        resolve(result);
      })
      .catch((response) => {
        reject(response);
      })
  ))
);
