import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';

import { classesShape } from 'utils/shapes/classesShape';

import previewPopupStyles from './PreviewPopup.styles';

import messages from './PreviewPopup.messages';

class PreviewPopup extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const {
      classes,
      imageUrl,
    } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <Tooltip title={this.props.intl.formatMessage(messages.see)} placement="top">
          <IconButton
            onClick={this.handleClick}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className={classes.wrapper}>
            <img src={imageUrl} width="200px" alt="" />
          </div>
        </Popover>
      </div>
    );
  }
}

PreviewPopup.propTypes = {
  intl: intlShape.isRequired,
  classes: classesShape,
  imageUrl: PropTypes.string,
};

PreviewPopup.defaultProps = {
  classes: {},
  imageUrl: '',
};

export default withStyles(previewPopupStyles)(injectIntl(PreviewPopup));
