import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import _isEqual from 'lodash/isEqual';
import { injectIntl, intlShape } from 'react-intl';
import _find from 'lodash/find';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Autocomplete from 'components/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';

import FormField from 'components/FormField';
import validator from 'utils/validator/core';
import disabledSaveButton from 'utils/disableSaveButton';
import { classesShape } from 'utils/shapes/classesShape';
import { itemShape } from 'utils/shapes/select.shapes';
import snackbarMessages from 'utils/snackbarMessages';
import organizationAddEditStyles from './OrganizationAddEdit.styles';

import messages from './OrganizationAddEdit.messages';

const validators = [{
  fieldName: 'name',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'city',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'street',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'number',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [255],
    },
  ],
}, {
  fieldName: 'postalCode',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'maxLength',
      parameters: [6],
    },
  ],
}, {
  fieldName: 'consent',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
  validateIf: (values) => values.type === '1',
}, {
  fieldName: 'type',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'status',
  validators: [
    {
      validatorName: 'notEmpty',
    },
  ],
}, {
  fieldName: 'dateFrom',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
    }, {
      validatorName: 'maxLength',
      parameters: [4],
    }, {
      validatorName: 'higherOrEqualTo',
      parameters: [1990],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [new Date().getFullYear()],
    },
  ],
}, {
  fieldName: 'dateTo',
  validators: [
    {
      validatorName: 'notEmpty',
    }, {
      validatorName: 'isNumber',
    }, {
      validatorName: 'maxLength',
      parameters: [4],
    }, {
      validatorName: 'higherOrEqualTo',
      parameters: [1990],
    }, {
      validatorName: 'lowerOrEqualTo',
      parameters: [new Date().getFullYear()],
    },
  ],
}];

const onAddSurveys = (setFieldValue, values) => {
  const surveys = [...values.surveys];

  surveys.push({
    survey: {},
  });

  setFieldValue('surveys', surveys);
};

const onRemoveSurveys = (setFieldValue, values, index) => {
  const surveys = [...values.surveys];

  surveys.splice(index, 1);

  setFieldValue('surveys', surveys);
};

const onSurveyChange = (value, values, index, setFieldValue) => {
  const surveys = [...values.surveys];

  surveys[index].survey = value;

  setFieldValue('surveys', surveys);
};

const checkConsent = (organizationTypes, value) => {
  const data = _find(organizationTypes, { id: value });

  return data ? data.show_consent : false;
};

const OrganizationAddEdit = ({
  classes,
  intl,
  initialValues,
  onSubmit,
  consents,
  organizationTypes,
  organizationStatuses,
  onCancel,
  surveySuggestions,
  onSurveyFetch,
  onSurveyClear,
}) => (
  <div className={classes.wrapper}>
    <div className={classes.title}>
      <Typography variant="h6">
        {intl.formatMessage(messages.organizationData)}
      </Typography>
    </div>
    <Formik
      initialValues={
        { ...initialValues }
      }
      validate={(values) => validator(values, validators, intl.formatMessage)}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => (
        <React.Fragment>
          <form onSubmit={handleSubmit}>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="name"
                  label={intl.formatMessage(messages.name)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.name}
                    labelWidth={50}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="city"
                  label={intl.formatMessage(messages.city)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.city}
                    labelWidth={50}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="street"
                  label={intl.formatMessage(messages.street)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.street}
                    labelWidth={40}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="number"
                  label={intl.formatMessage(messages.number)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.number}
                    labelWidth={50}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="postalCode"
                  label={intl.formatMessage(messages.postalCode)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.postalCode}
                    labelWidth={105}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="dateFrom"
                  label={intl.formatMessage(messages.dateFrom)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.dateFrom}
                    labelWidth={130}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="dateTo"
                  label={intl.formatMessage(messages.dateTo)}
                  variant="outlined"
                  fullWidth
                >
                  <OutlinedInput
                    value={values.dateTo}
                    labelWidth={130}
                    margin="none"
                    variant="outlined"
                  />
                </FormField>
              </div>
            </div>
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.surveys)}
              </Typography>
            </div>
            <div className={classes.surveysWrapper}>
              {
                values.surveys.map((el, key) => (
                  <div
                    className={classNames(
                      classes.addWrapper,
                      { [classes.lastInRow]: (key + 1) % 3 === 0 },
                    )}
                    key={`survey-${key}`} // eslint-disable-line react/no-array-index-key
                  >
                    <FormField
                      formControlClassName={classes.formControl}
                      onBlur={setFieldTouched}
                      onChange={
                        (name, value) => { onSurveyChange(value, values, key, setFieldValue); }
                      }
                      errors={errors}
                      touched={touched}
                      name="survey"
                      label={intl.formatMessage(messages.survey)}
                      variant="outlined"
                    >
                      <Autocomplete
                        fullWidth
                        onFetch={onSurveyFetch}
                        onClear={onSurveyClear}
                        labelWidth={55}
                        suggestions={surveySuggestions}
                        value={el.survey}
                      />
                    </FormField>
                    {
                      key === 0 ? null : (
                        <div className={classes.buttonWrapper}>
                          {
                            values.surveys.length > 1 && (
                            <Button
                              variant="outlined"
                              className={classes.addRemoveButton}
                              onClick={() => { onRemoveSurveys(setFieldValue, values, key); }}
                            >
                              <RemoveIcon />
                            </Button>
                            )
                          }
                        </div>
                      )
                    }
                  </div>
                ))
              }
              {
                <div className={classes.buttonAddWrapper}>
                  <Button
                    variant="outlined"
                    className={classes.addRemoveButton}
                    onClick={() => { onAddSurveys(setFieldValue, values); }}
                  >
                    <AddIcon />
                  </Button>
                </div>
            }
            </div>
            <div className={classes.title}>
              <Typography variant="h6">
                {intl.formatMessage(messages.systemParams)}
              </Typography>
            </div>
            <div className={classes.row}>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                  errors={errors}
                  touched={touched}
                  name="status"
                  label={intl.formatMessage(messages.status)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.status}
                    input={(
                      <OutlinedInput
                        labelWidth={115}
                        name="status"
                        id="status-picker"
                      />
                    )}
                  >
                    {
                      organizationStatuses.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
              <div className={classes.fieldWrapper}>
                <FormField
                  onBlur={setFieldTouched}
                  onChange={(field, value) => {
                    setFieldValue(field, value);
                    checkConsent(organizationTypes, value);
                  }}
                  errors={errors}
                  touched={touched}
                  name="type"
                  label={intl.formatMessage(messages.type)}
                  variant="outlined"
                  fullWidth
                >
                  <Select
                    value={values.type}
                    input={(
                      <OutlinedInput
                        labelWidth={95}
                        name="type"
                        id="type-picker"
                      />
                    )}
                  >
                    {
                      organizationTypes.map((el) => (
                        <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                      ))
                    }
                  </Select>
                </FormField>
              </div>
            </div>
            {
              checkConsent(organizationTypes, values.type) ? (
                <div className={classes.row}>
                  <div className={classes.fieldWrapper}>
                    <FormField
                      onBlur={setFieldTouched}
                      onChange={setFieldValue}
                      errors={errors}
                      touched={touched}
                      name="consent"
                      label={intl.formatMessage(messages.consent)}
                      variant="outlined"
                      fullWidth
                    >
                      <Select
                        value={values.consent}
                        input={(
                          <OutlinedInput
                            labelWidth={50}
                            name="consent"
                            id="consent-picker"
                          />
                        )}
                      >
                        {
                          consents.map((el) => (
                            <MenuItem value={el.id} key={el.id}>{el.name}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormField>
                  </div>
                </div>
              ) : null
            }
            <div className={classes.buttonsWrapper}>
              <div className={classes.cancelWrapper}>
                <Button
                  color="inherit"
                  onClick={onCancel}
                >
                  {intl.formatMessage(messages.cancel)}
                </Button>
              </div>
              <Button
                variant="contained"
                color="primary"
                disabled={disabledSaveButton(errors, touched)}
                onClick={handleSubmit}
              >
                {intl.formatMessage(messages.save)}
              </Button>
            </div>
          </form>
          <Prompt
            when={!_isEqual(values, initialValues) && !isSubmitting}
            message={intl.formatMessage(snackbarMessages.unsaveData)}
          />
        </React.Fragment>
      )}
    />
  </div>
);

OrganizationAddEdit.propTypes = {
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  classes: classesShape,
  consents: PropTypes.arrayOf(itemShape),
  initialValues: PropTypes.shape({
    name: PropTypes.string,
    city: PropTypes.string,
    street: PropTypes.string,
    number: PropTypes.string,
    postalCode: PropTypes.string,
    consent: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
    ]),
  }),
  organizationStatuses: PropTypes.arrayOf(itemShape),
  organizationTypes: PropTypes.arrayOf(itemShape),
  surveySuggestions: PropTypes.arrayOf(itemShape),
  onSurveyClear: PropTypes.func,
  onSurveyFetch: PropTypes.func,
};

OrganizationAddEdit.defaultProps = {
  classes: {},
  initialValues: {},
  consents: [],
  organizationTypes: [],
  organizationStatuses: [],
  surveySuggestions: [],
  onSurveyFetch: () => {},
  onSurveyClear: () => {},
};

export default withStyles(organizationAddEditStyles)(injectIntl(OrganizationAddEdit));
