import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { classesShape } from 'utils/shapes/classesShape';
import TableCell from '@material-ui/core/TableCell';
import cellStyles from './TableCell.styles';

const CustomTableCell = ({
  children,
  className,
  classes,
  noWrap,
  ...props
}) => (
  <TableCell {...props} className={classNames(className, { [classes.customCell]: !noWrap })}>
    {children}
  </TableCell>
);

CustomTableCell.propTypes = {
  children: PropTypes.node,
  classes: classesShape,
  className: PropTypes.string,
  noWrap: PropTypes.bool,
};

CustomTableCell.defaultProps = {
  className: '',
  children: null,
  noWrap: false,
  classes: {},
};

export default withStyles(cellStyles)(CustomTableCell);
