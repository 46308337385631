import { defineMessages } from 'react-intl';

export default defineMessages({
  personalData: 'Dane osobowe',
  name: 'Imię',
  surname: 'Nazwisko',
  contactData: 'Dane kontaktowe',
  ogranizationsData: 'Placówki',
  organization: 'Placówka',
  email: 'Email',
  systemParams: 'Parametry systemowe',
  status: 'Status',
  role: 'Rola',
  cancel: 'Anuluj',
  save: 'Zapisz',
  password: 'Hasło',
  survey: 'Ankieta',
  confirmPassword: 'Powtórz hasło',
  emailError: 'Niepoprawny adres e-mail',
});
