export default (theme) => ({
  title: {
    marginBottom: 12,
  },
  filtersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  organizationWrapper: {
    width: 240,
    marginLeft: 24,
  },
  typeWrapper: {
    width: 240,
    marginLeft: 24,
    marginRight: 24,
  },
  contentWrapper: {
    marginTop: 12,
  },
  root: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
  },
  paginationRoot: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderTop: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingBottom: 1,
  },
  addWrapper: {
    position: 'fixed',
    right: 24,
    bottom: 24,
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  selectWrapper: {
    paddingRight: 24,
  },
  buttonsWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 24,
    height: 56,
  },
  resetWrapper: {
    marginLeft: 24,
  },
  searchWrapper: {
    flexGrow: 1,
  },
  consentCell: {
    padding: '4px 14px 4px 4px',
  },
  typeCell: {
    width: '8%',
    padding: '4px 14px 4px 4px',
  },
  postalCodeCell: {
    width: '7%',
    padding: '4px 14px 4px 4px',
  },
  nameCell: {
    padding: '4px 20px 4px 0',
  },
  streetCell: {
    width: '7%',
    padding: '4px 14px 4px 4px',
  },
});
