import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormField from 'components/FormField';
import validator from 'utils/validator/core';

import messages from './FormDialog.messages';

const FormDialog = ({
  intl,
  open,
  onClose,
  onDialogClose,
  label,
  id,
  title,
  maxValue,
  onSubmit,
}) => {
  const validators = [{
    fieldName: 'value',
    validators: [
      {
        validatorName: 'notEmpty',
      },
      {
        validatorName: 'maxLength',
        parameters: [255],
      },
      {
        validatorName: 'isNumber',
      },
      {
        validatorName: 'lowerOrEqualTo',
        parameters: [maxValue],
      },
      {
        validatorName: 'higherOrEqualTo',
        parameters: [1],
      },
    ],
  }];

  return (
    <Dialog open={open} onClose={onDialogClose}>
      <DialogTitle>{title}</DialogTitle>
      <Formik
        initialValues={{
          value: '',
        }}
        validate={(values) => validator(values, validators, intl.formatMessage)}
        onSubmit={onSubmit}
        render={({
          values,
          errors,
          touched,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          resetForm,
        }) => (
          <React.Fragment>
            <DialogContent>
              <FormField
                onBlur={setFieldTouched}
                onChange={setFieldValue}
                errors={errors}
                touched={touched}
                name="value"
                variant="outlined"
                fullWidth
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id={id}
                  label={label}
                  value={values.value}
                />
              </FormField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { onClose(); resetForm(); }} color="primary">
                {intl.formatMessage(messages.cancel)}
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {intl.formatMessage(messages.save)}
              </Button>
            </DialogActions>
          </React.Fragment>
        )}
      />
    </Dialog>
  );
};

FormDialog.propTypes = {
  intl: intlShape.isRequired,
  maxValue: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onDialogClose: PropTypes.func,
};

FormDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onDialogClose: () => {},
  title: '',
  id: '',
  label: '',
};

export default (injectIntl(FormDialog));
