import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import deepOrange from '@material-ui/core/colors/deepOrange';
import grey from '@material-ui/core/colors/grey';

const white = '#fff';

const theme = createMuiTheme({
  palette: {
    primary: { main: orange[400] },
    secondary: { main: deepOrange[400] },
    text: {
      primary: grey[800],
      secondary: white,
    },
    background: { main: grey[100] },
    cardBackground: white,
    borderColor: grey[500],
    tableBorderColor: grey[300],
    drawerBg: grey[900],
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: white,
      },
    },
    MuiInputLabel: {
      formControl: {
        color: grey[400],
      },
      shrink: {
        color: grey[400],
      },
      outlined: {
        color: grey[400],
      },
    },
    MuiTableCell: {
      head: {
        color: grey[400],
      },
      stickyHeader: {
        top: 64,
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: grey[400],
      },
    },
    MuiList: {
      root: {
        backgroundColor: white,
      },
    },
    MuiTypography: {
      subtitle2: {
        color: white,
      },
    },
    MuiToolbar: {
      root: {
        background: white,
      },
    },
    MuiDrawer: {
      paper: {
        background: grey[900],
      },
    },
    MuiFab: {
      primary: {
        color: white,
      },
    },
    MuiDialogContentText: {
      root: {
        color: grey[800],
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        color: grey[400],
      },
    },
    MuiCheckbox: {
      root: {
        color: grey[500],
      },
    },
  },
});

export default theme;
