import { replace } from 'connected-react-router';
import saveAs from 'file-saver';
import ApiManager from 'utils/ApiManager'; // eslint-disable-line import/no-cycle
import snackbarMessages from 'utils/snackbarMessages';
import isBadRequest from 'utils/isBadRequest';
import parseContentDisposition from 'utils/parseContentDisposition';

export const initialState = {
  apiVersion: null,
  loaderVisible: false,
  transparentLoaderVisible: false,
  snackbarVisible: false,
  snackbarMessage: null,
  title: null,
  activeMenuItem: null,
  userData: {},
  dialogData: {},
  dialogOpened: false,
  pagingData: {
    sortingField: null,
    sortingDirection: 'asc',
    lastFilterValues: {},
    page: 1,
    rowsPerPageOptions: [],
    rowsPerPage: 0,
  },
};

export const actionTypes = {
  SHOW_LOADER: 'GLOBAL/SHOW_LOADER',
  SHOW_TRANSPARENT_LOADER: 'GLOBAL/SHOW_TRANSPARENT_LOADER',
  HIDE_LOADER: 'GLOBAL/HIDE_LOADER',
  SHOW_SNACKBAR: 'GLOBAL/SHOW_SNACKBAR',
  HIDE_SNACKBAR: 'GLOBAL/HIDE_SNACKBAR',
  SET_TITLE: 'GLOBAL/SET_TITLE',
  SET_ACTIVE_MENU_ITEM: 'GLOBAL/SET_ACTIVE_MENU_ITEM',
  SET_USER_DATA: 'GLOBAL/SET_USER_DATA',
  OPEN_DIALOG: 'GLOBAL/OPEN_DIALOG',
  CLOSE_DIALOG: 'GLOBAL/CLOSE_DIALOG',
  SET_ROWS_PER_PAGE: 'GLOBAL/SET_ROWS_PER_PAGE',
  SET_PAGE: 'GLOBAL/SET_PAGE',
  SET_ROWS_PER_PAGE_OPTIONS: 'GLOBAL/SET_ROWS_PER_PAGE_OPTIONS',
  SET_LAST_FILTER_VALUES: 'GLOBAL/SET_LAST_FILTER_VALUES',
  SET_SORTING_DATA: 'GLOBAL/SET_SORTING_DATA',
  SET_API_VERSION: 'GLOBAL/SET_API_VERSION',
  CLEAR_PAGING_DATA: 'GLOBAL/CLEAR_PAGING_DATA',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_LOADER: {
      return {
        ...state,
        loaderVisible: true,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_TRANSPARENT_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: true,
      };
    }

    case actionTypes.HIDE_LOADER: {
      return {
        ...state,
        loaderVisible: false,
        transparentLoaderVisible: false,
      };
    }

    case actionTypes.SHOW_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: true,
        snackbarMessage: action.message,
      };
    }

    case actionTypes.HIDE_SNACKBAR: {
      return {
        ...state,
        snackbarVisible: false,
        snackbarMessage: null,
      };
    }

    case actionTypes.SET_TITLE: {
      return {
        ...state,
        title: action.title,
      };
    }

    case actionTypes.SET_ACTIVE_MENU_ITEM: {
      return {
        ...state,
        activeMenuItem: action.activeItem,
      };
    }

    case actionTypes.SET_USER_DATA: {
      return {
        ...state,
        userData: action.data,
      };
    }

    case actionTypes.OPEN_DIALOG: {
      return {
        ...state,
        dialogOpened: true,
        dialogData: action.dialogData,
      };
    }

    case actionTypes.CLOSE_DIALOG: {
      return {
        ...state,
        dialogOpened: false,
        dialogData: {},
      };
    }

    case actionTypes.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          rowsPerPage: action.value,
        },
      };
    }

    case actionTypes.SET_PAGE: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          page: action.value,
        },
      };
    }

    case actionTypes.SET_ROWS_PER_PAGE_OPTIONS: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          rowsPerPage: action.response.data.perPageNumber[0],
          rowsPerPageOptions: action.response.data.perPageNumber,
        },
      };
    }

    case actionTypes.SET_LAST_FILTER_VALUES: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          lastFilterValues: action.values,
        },
      };
    }

    case actionTypes.SET_SORTING_DATA: {
      return {
        ...state,
        pagingData: {
          ...state.pagingData,
          page: 1,
          sortingField: action.data.fieldName,
          sortingDirection: action.data.sortDirection,
        },
      };
    }

    case actionTypes.CLEAR_PAGING_DATA: {
      return {
        ...state,
        pagingData: {
          ...initialState.pagingData,
          rowsPerPageOptions: state.pagingData.rowsPerPageOptions,
          rowsPerPage: state.pagingData.rowsPerPageOptions[0],
        },
      };
    }

    case actionTypes.SET_API_VERSION: {
      return {
        ...state,
        apiVersion: action.apiVersion,
      };
    }

    default:
      return state;
  }
};

const setRowsPerPageOptions = (response) => ({
  type: actionTypes.SET_ROWS_PER_PAGE_OPTIONS,
  response,
});

export const showLoader = () => ({
  type: actionTypes.SHOW_LOADER,
});

export const showTransparentLoader = () => ({
  type: actionTypes.SHOW_TRANSPARENT_LOADER,
});

export const hideLoader = () => ({
  type: actionTypes.HIDE_LOADER,
});

export const showSnackbar = (message) => ({
  type: actionTypes.SHOW_SNACKBAR,
  message,
});

export const hideSnackbar = () => ({
  type: actionTypes.HIDE_SNACKBAR,
});

export const setApiVersion = (apiVersion) => (dispatch, getStore) => {
  if (getStore().Global.apiVersion !== apiVersion) {
    dispatch({
      type: actionTypes.SET_API_VERSION,
      apiVersion,
    });
  }
};

export const logout = () => (dispatch) => {
  dispatch(showTransparentLoader());

  ApiManager.request('post', dispatch, 'logout', {}).then(() => {
    dispatch(hideLoader());
    dispatch(showSnackbar(snackbarMessages.logoutSuccess));
    dispatch(replace('/login'));
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const setTitle = (title) => ({
  type: actionTypes.SET_TITLE,
  title,
});

export const setActiveMenuItem = (activeItem) => ({
  type: actionTypes.SET_ACTIVE_MENU_ITEM,
  activeItem,
});

export const setUserData = (data) => ({
  type: actionTypes.SET_USER_DATA,
  data,
});

export const openDialog = (dialogData) => ({
  type: actionTypes.OPEN_DIALOG,
  dialogData,
});

export const closeDialog = () => ({
  type: actionTypes.CLOSE_DIALOG,
});

export const resetPassword = (email) => (dispatch) => {
  dispatch(showTransparentLoader());

  const requestBody = {
    data: {
      email,
    },
  };

  return ApiManager.request('post', dispatch, 'forgot_password', requestBody);
};

export const changePassword = (email) => (dispatch) => {
  dispatch(showTransparentLoader());

  dispatch(resetPassword(email)).then(() => {
    dispatch(showSnackbar(snackbarMessages.succesfullyResetedPassword));
    dispatch(hideLoader());
  }).catch((error) => {
    if (isBadRequest(error)) {
      dispatch(showSnackbar(snackbarMessages.wrongData));
    } else {
      dispatch(showSnackbar(snackbarMessages.globalError));
    }

    dispatch(hideLoader());
  });
};

export const loadUserData = () => (dispatch) => new Promise((resolve, reject) => {
  ApiManager.request('get', dispatch, 'current_user').then((response) => {
    dispatch(setUserData(response.data));
    resolve();
  }).catch((error) => {
    reject(error);
  });
});

export const getMeta = () => (dispatch) => ApiManager.request('get', dispatch, 'meta');

export const mapMeta = (meta) => meta.map((el) => ({
  id: el.id,
  name: el.name,
}));

export const setRowsPerPage = (value) => ({
  type: actionTypes.SET_ROWS_PER_PAGE,
  value,
});

export const setPage = (value) => ({
  type: actionTypes.SET_PAGE,
  value,
});

export const getRowsPerPageOptions = () => (dispatch) => new Promise((resolve, reject) => {
  ApiManager.request('get', dispatch, 'meta').then((response) => {
    dispatch(setRowsPerPageOptions(response));
    resolve();
  }).catch(() => {
    reject();
  });
});

export const setLastFilterValues = (values) => ({
  type: actionTypes.SET_LAST_FILTER_VALUES,
  values,
});

export const setSortingData = (data) => ({
  type: actionTypes.SET_SORTING_DATA,
  data,
});

export const clearPagingData = () => ({
  type: actionTypes.CLEAR_PAGING_DATA,
});

export const downloadSurveyFile = (id) => (dispatch) => {
  ApiManager.request('get', dispatch, `sessions/${id}/export`, {}, true).then((response) => {
    const contentType = 'text/csv;charset=UTF-8';
    const { filename } = parseContentDisposition({ header: response.headers['content-disposition'] });
    const file = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), response.data],
      { type: contentType });
    saveAs(file, filename);
  });
};
