import { defineMessages } from 'react-intl';

export default defineMessages({
  group: 'Grupa',
  questionsRepository: 'Repozytorium pytań',
  picture: 'Obrazek',
  repoFind: 'Wpisz treść',
  questionType: 'Typ pytania',
  filter: 'Filtruj',
  reset: 'Wyczyść',
  questionContent: 'Treść pytania',
  answerSet: 'Zestaw odpowiedzi',
  actions: 'Akcje',
  rowsPerPage: 'Wierszy:',
  add: 'Dodaj',
  edit: 'Edytuj',
});
