export default (theme) => ({
  wrapper: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.background.main}`,
  },
  card: {
    width: 397,
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40,
    paddingRight: 40,
  },
  loaderWrapper: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  transparentLoaderWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 100,
  },
  transparentLoaderBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: theme.palette.cardBackground,
    opacity: 0.6,
    zIndex: 100,
  },
  progressWrapper: {
    position: 'relative',
    zIndex: 101,
  },
  contentWrapperWithLoader: {
    display: 'none',
  },
});
