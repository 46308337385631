export default (theme) => ({
  tableWrapper: {
    marginTop: 12,
  },
  root: {
    border: `1px solid ${theme.palette.tableBorderColor}`,
    borderRadius: 5,
    borderCollapse: 'unset',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: 0,
  },
  numberCell: {
    width: 20,
  },
  questionContentCell: {
    width: 600,
    wordBreak: 'break-word',
  },
  progressStatusLabelCell: {
    width: 300,
  },
  questionParamCell: {
    width: 50,
  },
  questionLabelCell: {
    width: 200,
  },
  questionLabelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  questionLabelText: {
    maxWidth: 170,
  },
  ellipsis: {
    whiteSpaces: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  answerSetCell: {
    width: 250,
  },
  questionTypeCell: {
    width: 200,
  },
  questionTypeContent: {
    color: theme.palette.primary.main,
  },
  groupCell: {
    width: 200,
  },
  actionsCell: {
    width: 100,
  },
  buttonCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editTableCell: {
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.main,
    paddingTop: 24,
    paddingBottom: 24,
  },
  answerSetWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
});
