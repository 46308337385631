export default (theme) => ({
  container: {
    '&.ql-toolbar.ql-snow': {
      border: 'none',
      borderBottom: `1px solid ${theme.palette.borderColor}`,
    },
  },
  disabled: {
    opacity: 0.7,
    pointerEvents: 'none',
  },
});
